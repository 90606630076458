import React, { useState, useEffect } from 'react';
import { SideBar, EditClient } from '../organisms';
import { SubSideItem, Loader, NoData, DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader } from '../organisms';
import { CreateClienteProfile, NutritionalInfo, AnswersInfo, ObservationModal, TrueIntityModal } from '../molecules';
import { useAccessToken } from '../../hooks';
import { Api } from '../../services';

function EditUserPage({ match }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [client, setClient] = useState({});
  const [loading, setloading] = useState(true);
  const [selectedTab, setSelectedTab] = useState()
  const [stages, setStages] = useState([]);

  const tabs = [
    "cadastro",
    "meta",
    "estrategia_alimentar",
    "exames_bioquimicos",
    "avaliacao_fisica",
    "avaliação_emocional",
    "diagnostico_nutricional",
    "arquivos_pdf",
    "jornada_do_paciente",
    "aviso_agua",
  ]

  const subsides = [
    (<SubSideItem name="Cadastro" icon={"/img/diet.svg"} />),
    (<SubSideItem name="Meta" icon={"/img/goal.svg"} />),
    (<SubSideItem name="Estratégia Alimentar" icon={"/img/diet.svg"} />),
    (<SubSideItem name="Exames Bioquímicos" icon={"/img/chemistry.svg"} />),
    (<SubSideItem name="Avaliação Física" icon={"/img/measuring-tape.svg"} />),
    (<SubSideItem name="Avaliação Emocional" icon={"/img/heart.svg"} />),
    (<SubSideItem name="Diagnóstico Nutricional" icon={"/img/diet.svg"} />),
    (<SubSideItem name="Arquivos PDF" icon={"/img/diet.svg"} />),
    (<SubSideItem name="Jornada do Paciente" icon={"/img/diet.svg"} />),
    (<SubSideItem name="Aviso de água" icon={"/img/diet.svg"} />),
  ];

  useEffect(() => {
    setloading(true);
    if (isAuthenticated) {
      const { userid } = match.params;
      Api.get(`/api/clients/${userid}`, `Bearer ${access_token}`)
        .then(response => {
          setClient(response.data);
          const { answers } = response.data;
          if (answers && answers.retorno) {
            setStages(Object.keys(answers.retorno));
          }
        })
        .catch(erro => {
          if (erro.message == 'Unauthenticated.') {
            setIsAuthenticated(false);
          }
        })
        .finally(() => setloading(false));
    }
  }, [isAuthenticated, match]);

  return (
    <div className="dashboard-container create-client-container">
      <SideBar />
      <DashboardContent subsides={subsides} onClickItemMenu={tabIndex => setTab(tabIndex)}>
        <InternPageHeader link={"/pacientes"} title={"Editar Paciente"} />
        {
          loading ?
            <div className="d-flex justify-content-center"> <Loader /> </div> :
            (
              client.user ?
                (<>
                  <CreateClienteProfile client={client} token={access_token} updateClient={setClient} />
                  <div className="nutritional-info d-flex flex-wrap">
                    <NutritionalInfo client={client} updateClient={setClient} />
                    <ObservationModal client={client} updateClient={setClient} tab_name={tabs[tab]} />
                    <AnswersInfo answers={client.answers} type="retorno" enableSelect selectLabel="Etapa" selectValues={stages} />
                    <AnswersInfo answers={client.answers} type="protocolo" buttonLabel="Protocolo de Avaliação Respondido" />
                    <TrueIntityModal client={client} updateClient={setClient} />
                  </div>
                  <EditClient tab={tab} token={access_token} client={client} updateClient={setClient} />
                </>) :
                <NoData message={"Paciente não encontrado"} />
            )
        }
      </DashboardContent>
    </div>
  )
}

export { EditUserPage };
