import React, { useState, useEffect } from 'react';
import { Input, Button, Alert, TextArea, DeleteButton } from '../../atoms';
import { ListOfMediasWithPlayer } from '../../organisms';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';

const AvaliacaoEmocionalForm = ({ evaluation, client, updateClient, setCurrentEvaluation }) => {

  useEffect(() => {
    setForm({
      title: evaluation ? evaluation.title : "",
      description: evaluation ? evaluation.description : "",
      media_id: evaluation ? evaluation.media_id : "",
      media: evaluation ? evaluation.media : null,
    });
  }, [evaluation])

  const [form, setForm] = useState({});

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [isBusy, setIsBusy] = useState(false);

  const changeVideo = (media) => {
    setForm({ ...form, media: media, media_id: media ? media.id : null });
  }

  const create = () => {
    setAlert(null);
    setIsBusy(true);
    Api.post(`/api/clients/${client.id}/emotionalEvaluation`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setForm({
          title: "",
          description: "",
          media_id: "",
          media: null,
        });
        updateClient({ ...client, emotional_evaluations: [...(client.emotional_evaluations || []), res.data] });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const update = () => {
    setAlert(null);
    setIsBusy(true);
    Api.put(`/api/clients/${client.id}/emotionalEvaluation/${evaluation.id}`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setCurrentEvaluation(null);
        updateClient({
          ...client, emotional_evaluations: (client.emotional_evaluations || []).map(evaluation => {
            if (evaluation.id === res.data.id) {
              return res.data;
            }
            return evaluation;
          })
        });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const deleteExame = () => {
    setAlert(null);
    setIsBusy(true);
    Api.delete(`/api/clients/${client.id}/emotionalEvaluation/${evaluation.id}`, {}, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setCurrentEvaluation(null);
        updateClient({
          ...client,
          emotional_evaluations: (client.emotional_evaluations || []).filter(evaluation => evaluation.id !== res.data.id)
        });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const { title, description, media } = form;

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
        <div className="row">
          <div className="col-12">
            <Input label="Titulo do Treinamento" value={title}
              onChangeInputValue={(title, value) => setForm({ ...form, title: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12">
            <TextArea label="Descrição do Treinamento" value={description}
              onChangeInputValue={(description, value) => setForm({ ...form, description: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row mt-2">
          <div className="col-12">
            <ListOfMediasWithPlayer select={true} currentMedia={media} type={"select"} onSelect={changeVideo} />
          </div>
        </div>
        <div className="mt-5 actions mb-5">
          {evaluation && <Button text="Excluir avaliação" className="btn-danger" onSubmit={() => deleteExame()} />}
          <Button disabled={isBusy} text={evaluation ? "Atualizar" : "Criar"} onSubmit={() => evaluation ? update() : create()} className="btn-submit" />
        </div>
      </div>
    </>
  );
}

export { AvaliacaoEmocionalForm };