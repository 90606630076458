import React from 'react';
import { VideoUpload } from '../VideoUpload';
import { VideoUpdate } from '../VideoUpdate';

const MediaForm = ({ currentMedia, setRefresh }) => {
  return (
    currentMedia ?
      <VideoUpdate currentMedia={currentMedia} setRefresh={setRefresh} />
      :
      <VideoUpload onErro={(error) => console.log(error)} onSuccess={(res) =>{ setRefresh(true)}}  />
  );
}

export { MediaForm };
