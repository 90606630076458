import React from 'react';
import './style.scss';

const HeaderAccordion = ({ closed=true, title, onClick }) => {
  return (
    <div className='header-accordion justify-content-between' onClick={onClick}>
      <span>{title}</span>
      <img src={closed ? '/img/arrow_down_color.svg' : '/img/arrow_up_color.svg'} />
    </div>
  );
}

export { HeaderAccordion };
