import React, { useState, useEffect } from 'react';
import { Input, Button, Alert, TextArea, SelectInput } from '../../../atoms';
import { Modal } from '../../../molecules';
import { useAccessToken } from '../../../../hooks';
import '../Style.scss'
import { Api } from '../../../../services';

const Meta = ({ token, client, updateClient }) => {
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [estrategias, setEstrategias] = useState([]);//Número de estratégias necessárias
    const [image, setImage] = useState(null);
    const status = useState([
        { value: 'created', label: 'Criado' },
        { value: 'waiting', label: 'Esperando data' },
        { value: 'ongoing', label: 'Andamento' },
        { value: 'pending_quiz', label: 'Questionário pendente' },
        { value: 'finished', label: 'Concluído' }
    ])[0];//Número de estratégias necessárias
    const [form, setForm] = useState({ nEstrategias: 1, meta: "", plano: "", file: null, estrategias: [{ kcal: '', status: '' }] });
    const [fileError, setFileError] = useState("");
    const [openAddDaysModal, setOpenAddDaysModal] = useState(false);
    const [addDays, setAddDays] = useState(0);
    const [addDaysLoading, setAddDaysLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await Api.get(`/api/clients/${client.id}/goal`, `Bearer ${access_token}`);
                //goas, action_plan, 
                if (!response.data) {
                    setForm({ ...form, nEstrategias: 1, estrategias: [{ kcal: '', status: 'created', duration: 1 }] })
                } else {
                    const { goals, action_plan, path } = (response.data.goal || { goals: "", action_plan: "" });
                    setImage(path);
                    const strats = (response.data.foodStrategies || [{ kcal: '', status: 'created', duration: 2 }]).map(strat => {
                        return { kcal: strat.cal, status: strat.status, duration: strat.duration }
                    });
                    setForm({
                        ...form,
                        plano: action_plan,
                        meta: goals,
                        nEstrategias: response.data.foodStrategies.length,
                        estrategias: strats.length ? strats : [{ kcal: '', status: 'created', duration: 3 }]
                    });
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        })();
    }, [])

    useEffect(() => {
        let arr = [];
        for (let i = 1; i < 31; i++) {
            arr.push({ label: i });
        }
        setEstrategias(arr);
    }, []);

    function getFormData(formParams) {
        let formData = new FormData();
        Object.keys(formParams).forEach((key) => {
            if (formParams[key]) {
                formData.append(key, formParams[key]);
            }
        });
        return formData;
    }

    function atualizar() {
        const { plano, estrategias, file, meta } = form;
        let postObj = {
            "goals": meta, "action_plan": plano, "file": file,
            "strategies": JSON.stringify(estrategias.map((strat, index) => {
                return {
                    "cal": strat.kcal,
                    "duration": strat.duration,
                    "status": strat.status,
                    "strategy_number": index + 1
                }
            }))
        }
        setLoading(true);
        Api.post(`/api/clients/${client.id}/goal`, getFormData(postObj), `Bearer ${access_token}`).then(() => {
            setAlert({ message: 'Cadastrado com sucesso', type: 'alert-success' });
            setTimeout(() => setAlert(null), 5000);
        }).catch(error => {
            setAlert({ message: 'Houve um erro', type: 'alert-warning' });
            console.error('error', error);
        }).finally(() => setLoading(false));
    }

    function onFile(file) {
        const formats = ["image/png", "image/jpeg"];
        if (formats.find(type => type == file.type)) {
            setForm({ ...form, file });
            setFileError("");
        } else {
            setFileError("Formato não aceito");
            setForm({ ...form, file: null });
        }
    }

    function onChangeEstrategia(index, estrategia) {
        const strats = form.estrategias.map((strat, i) => {
            if (index == i) {
                return { ...estrategia };
            }
            else if (estrategia.status == 'waiting' || estrategia.status == 'ongoing') {
                return { ...strat, status: i > index ? 'created' : 'finished' }
            }
            else {
                return { ...strat };
            }
        })
        setForm({ ...form, estrategias: strats });
    }

    function onChangeSelectEstrategias(e) {
        const value = e.target.value;
        let arr = [...form.estrategias];//SEMPRE QUE MUDAR, RESETAR INPUTS
        while (arr.length != Number(value)) {
            if (arr.length < Number(value)) {
                arr.push({ kcal: '', status: 'created' });//ADICIONAR 
            } else {
                arr.splice(arr.length - 1, 1);//REMOVER O ULTIMO
            }
        }
        setForm({ ...form, nEstrategias: value, estrategias: arr });
    }

    function addDaysServer() {
        setAddDaysLoading(true);
        Api.post(`/api/clients/${client.id}/strategy/addDays`, {days: addDays }, `Bearer ${access_token}`)
        .then(_ => {
            console.log(_);
            const strats = form.estrategias.map((strat, i) => {
                if (strat.status == 'ongoing') {
                    return { ...strat, duration: strat.duration + addDays }
                }
                return strat;
            })
            setForm({ ...form, estrategias: strats });
            setOpenAddDaysModal(false);
        })
        .finally(() => setAddDaysLoading(false));
    }

    return (
        <>
            <div>
                {
                    alert && <Alert text={alert.message} className={alert.type} />
                }
            </div>
            <div className="row mt-3">
                <div className="col-md-4 meta-form">
                    <TextArea label="Meta" value={form.meta} onChangeInputValue={(c, meta) => setForm({ ...form, meta })} />
                    <SelectInput options={estrategias} value="label" label="label" name="Estratégias Necessárias"
                        selected={form.nEstrategias}
                        onSelect={e => onChangeSelectEstrategias(e)} />
                </div>
                <div className="col-md-4">
                    <TextArea label="Plano de Ação" value={form.plano}
                        onChangeInputValue={(c, plano) => setForm({ ...form, plano })} />
                </div>
                <div className="col-md-4">
                    {image && <img src={image} height={50} />}
                    <Input name="Selecione imagem" type="file"
                        onChangeInputValue={(n, file) => onFile(file)} />
                    {fileError.length > 0 &&
                        <div style={{ color: 'red' }}>
                            {fileError}
                        </div>
                    }
                </div>
            </div>
            <div className="row meta-inputs">
                {form.estrategias.map((estrategia, index) =>
                    <div key={index} className="col-12">
                        <div className="row">
                            <div className="col-4">
                                <Input label={`${index + 1}º Estratégia Nutricional`} type="number" name={`${index + 1} Estratégia Nutricional`}
                                    value={estrategia.kcal} placeholder="Calorias"
                                    onChangeInputValue={(n, kcal) => onChangeEstrategia(index, { ...estrategia, kcal })}
                                    id={`estrategia-${index}`} />
                            </div>
                            <div className="col-2">
                                <Input label="Duração" type="number" name="duracao"
                                    value={estrategia.duration} placeholder="Duração"
                                    disabled={estrategia.status === "ongoing"}
                                    onChangeInputValue={(n, duration) => onChangeEstrategia(index, { ...estrategia, duration })}
                                    id={`estrategia-duration-${index}`} />
                            </div>
                            {
                                estrategia.status === "ongoing" && (
                                    <div className="col-2 form-group align-self-end">
                                        <Button className={"btn-primary"} onSubmit={() => setOpenAddDaysModal(true)} text="Adicionar Dias" />
                                    </div>
                                )
                            }
                            <div className="col-4">
                                <SelectInput options={status} value="value" label="label" name="Status"
                                    selected={estrategia.status}
                                    onSelect={(e) => onChangeEstrategia(index, { ...estrategia, status: e.target.value })} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal closeModal={() => setOpenAddDaysModal(false)} isOpen={openAddDaysModal} id="add-days">
                    <Input label="Duração" type="number" name="duracao"
                        value={addDays} placeholder="Duração"
                        onChangeInputValue={(n, duration) => setAddDays(parseInt(duration))}
                        id={`add-days-input`} />
                    <Button className={"btn-success"} onSubmit={() => addDaysServer()} text="Adicionar Dias" disabled={addDaysLoading} />
            </Modal>
            <div className="d-flex justify-content-end mb-3">
                <Button className={"btn-success"} onSubmit={() => atualizar()} text="Atualizar Cadastro" disabled={loading} />
            </div>
        </>
    );
}

export { Meta };