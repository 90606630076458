import React, { useState, useEffect } from 'react';
import { Api } from '../services';
import { useHistory } from "react-router-dom";


function useAccessToken(isAuthenticated, setIsAuthenticated) {
  const [access_token, setAccessToken] = useState(localStorage.getItem('access_token'));
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {

      const body = Api.link_client({});
      body.grant_type = 'refresh_token'
      body.refresh_token = localStorage.getItem('refresh_token');
      Api.post('/oauth/token', body)
        .then(response => {
          setAccessToken(response.access_token)
          setIsAuthenticated(true);
          localStorage.setItem('access_token', response.access_token)
          localStorage.setItem('refresh_token', response.refresh_token)
        })
        .catch(erro => {
          localStorage.setItem('access_token', "")
          localStorage.setItem('refresh_token', "")
          history.push('/');
        })
    }

  }, [isAuthenticated]);

  return access_token;
}

export { useAccessToken }