import React from 'react';

const ButtonIcon = ({ children, onClick, className, disabled }) => {
  return (
    disabled?
      <button disabled className={`btn btn-primary btn-circle ${className}`} onClick={onClick}>
        {children}
      </button>
    :
    <button className={`btn btn-primary btn-circle ${className}`} onClick={onClick}>
      {children}
    </button>
  );
}

export { ButtonIcon };
