import React, { useState, useEffect } from "react";
import { Api } from "../../../services";
import { useAccessToken } from "../../../hooks";
import { Input, Button, DeleteButton, Loader } from "../../atoms";
import "./style.scss";
import { ActionsStatuses } from "../../molecules";
import { Link } from "react-router-dom/cjs/react-router-dom";

const QuestionnaireForm = ({ currentQuestionnaire, setRefresh }) => {
    const [questionnaireName, setQuestionnaireName] = useState();
    const [erro, setErro] = useState("");
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [sucesso, setSucesso] = useState(false);
    const [form, setForm] = useState([]);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const quetionaryPrintLinks = {
        1: "questionario_retorno",
        2: "avaliacao_emocional",
        3: "protocolo_avaliacao"
    }

    useEffect(() => {
        currentQuestionnaire.quiz_questions
            ? setForm(
                  currentQuestionnaire.quiz_questions.map((quiz) => ({
                      ...quiz,
                      alternatives:
                          quiz.alternatives.map((alternative) => ({
                              ...alternative,
                          })) || [],
                  }))
              )
            : setForm([]);
        setQuestionnaireName(currentQuestionnaire.name);
    }, [currentQuestionnaire]);

    const onAddClick = () => {
        setForm((prevState) => [
            ...prevState,
            {
                input: "",
                type: null,
                alternatives: [],
                image: null,
            },
        ]);
    };
    const onSubmit = () => {
        setLoading(true);
        Api.put(
            `/api/quiz/${currentQuestionnaire.id}`,
            { quiz_name: questionnaireName, quiz_questions: form },
            `Bearer ${access_token}`
        )
            .then((response) => {
                setSucesso(true);
                setRefresh(true);
            })
            .catch((erro) => {
                if (erro.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else {
                    if (erro.errors) {
                        setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
                    } else {
                        setErro(erro.message);
                    }
                }
                console.log(erro);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteInput = (indexDelete) => {
        setForm((prevState) => {
            return prevState.filter((item, index) => index !== indexDelete);
        });
    };

    const changeQuestion = (attr, i, value) => {
        setForm(
            form.map((curr, index) => {
                if (index == i) {
                    curr[attr] = value;
                }
                return curr;
            })
        );
    };

    const changeAlternative = (i, i2, newValue) => {
        setForm(
            form.map((quiz, indexQuiz) => {
                if (indexQuiz === i) {
                    quiz.alternatives = quiz.alternatives.map(
                        (alternative, indexAlternative) => {
                            if (i2 === indexAlternative) {
                                return { ...alternative, title: newValue };
                            }
                            return alternative;
                        }
                    );
                }
                return quiz;
            })
        );
    };

    const addAlternative = (indexQuiz) => {
        setForm(
            form.map((quiz, index) => {
                if (index === indexQuiz) {
                    quiz.alternatives = [...quiz.alternatives, { title: "" }];
                }
                return quiz;
            })
        );
    };

    const removeAlternative = (i, i2) => {
        setForm(
            form.map((quiz, index) => {
                if (index === i) {
                    quiz.alternatives = quiz.alternatives.filter(
                        (alternative, indexAlternative) =>
                            indexAlternative !== i2
                    );
                }
                return quiz;
            })
        );
    };

    const handleOptionChange = (event, index) => {
        switch (event.target.value) {
            case "option1":
                return changeQuestion("type", index, "input");
            case "option2":
                return changeQuestion("type", index, "option");
            case "option3":
                return changeQuestion("type", index, "slider");
            case "option4":
                return changeQuestion("type", index, "single");
            default:
                break;
        }
    };

    const handleImageSelect = (file, i) => {
        if (!file) return changeQuestion("image", i, null);
        console.log(file);
        console.log(i);
        const body = new FormData();
        body.append("image", file);
        Api.post("/api/file/image", body, `Bearer ${access_token}`)
            .then((res) => {
                changeQuestion("image", i, res.data);
            })
            .catch((erro) => {
                if (erro.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else {
                    if (erro.errors) {
                        setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
                    } else {
                        setErro(erro.message);
                    }
                }
                console.log(erro);
            });
    };

    const renderQuestions = () => {
        return form.map((item, i) => (
            <div key={`question-${i}`} className="d-flex flex-column col-12">
                <div className="row">
                    <div className="col-11">
                        <Input
                            id={`input-${item.id}`}
                            name={`input-${item.id}`}
                            onChangeInputValue={(input, text) =>
                                changeQuestion("title", i, text)
                            }
                            label={`Pergunta ${i + 1}`}
                            value={item.title}
                        />
                    </div>
                    <div className="col-1">
                        <DeleteButton onPress={() => deleteInput(i)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {item.image && <img src={item.image} />}
                        <Input
                            id={`image-input-${item.id}`}
                            name={`image-input-${item.id}`}
                            type={"file"}
                            accept={"image/*"}
                            onChangeInputValue={(input, file) =>
                                handleImageSelect(file, i)
                            }
                        />
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <label
                        htmlFor={`label-${item.id}`}
                        dangerouslySetInnerHTML={{ __html: "Tipo de resposta" }}
                    />
                    <div className="d-flex">
                        <div className="radio col">
                            <label>
                                <input
                                    onChange={(event) =>
                                        handleOptionChange(event, i)
                                    }
                                    type="radio"
                                    className="mr-2"
                                    value="option1"
                                    checked={item.type === "input"}
                                />
                                {`Pergunta Aberta`}
                            </label>
                        </div>
                        <div className="radio col">
                            <label>
                                <input
                                    onChange={(event) =>
                                        handleOptionChange(event, i)
                                    }
                                    type="radio"
                                    className="mr-2"
                                    value="option2"
                                    checked={item.type === "option"}
                                />
                                {`Multipla escolha`}
                            </label>
                        </div>
                        <div className="radio col">
                            <label>
                                <input
                                    onChange={(event) =>
                                        handleOptionChange(event, i)
                                    }
                                    type="radio"
                                    className="mr-2"
                                    value="option3"
                                    checked={item.type === "slider"}
                                />
                                {`Escala de 0 a 10`}
                            </label>
                        </div>
                        <div className="radio col">
                            <label>
                                <input
                                    onChange={(event) =>
                                        handleOptionChange(event, i)
                                    }
                                    type="radio"
                                    className="mr-2"
                                    value="option4"
                                    checked={item.type === "single"}
                                />
                                {`Única escolha`}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {(item.type === "option" || item.type === "single") && (
                        <>
                            <Button
                                onSubmit={() => addAlternative(i)}
                                className={
                                    "btn-block btn-danger btn-purple btn-outline "
                                }
                                text={"Adicionar Alternativa"}
                            />
                            <div className="ml-5">
                                {item.alternatives.map((alternative, i2) => (
                                    <div
                                        key={`alternative-${i}-${i2}`}
                                        className="d-flex row justify-content-between alternative"
                                    >
                                        <div className="col-8">
                                            <Input
                                                id={`input-multi-option-${i}-${i2}`}
                                                name={`input-multi-option-${i}-${i2}`}
                                                onChangeInputValue={(
                                                    input,
                                                    text
                                                ) =>
                                                    changeAlternative(
                                                        i,
                                                        i2,
                                                        text
                                                    )
                                                }
                                                label={`Opção ${
                                                    i2 + 1
                                                } da pergunta ${i + 1}`}
                                                value={alternative.title}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <DeleteButton
                                                onPress={() =>
                                                    removeAlternative(i, i2)
                                                }
                                                className={
                                                    "btn-block btn-danger btn-purple"
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <hr />
            </div>
        ));
    };

    return (
        <div className="d-flex h-100 flex-column w-100 questionnaire">
            <div>
                {loading && (
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <Loader />
                        </div>
                    </div>
                )}
                <div className="row w-100 m-0">
                    <div className="col-12">
                        <ActionsStatuses sucesso={sucesso} erro={erro} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1>{questionnaireName}</h1>
                        <Link to={`questionnaire/${quetionaryPrintLinks[currentQuestionnaire.id]}`} className="btn btn-primary">Imprimir</Link>
                    </div>
                </div>
                <hr />
                <div className="questions scroll">{renderQuestions()}</div>
            </div>
            <div className="w-100 row m-0 justify-content-between mt-4">
                <div className="col-3">
                    <Button
                        disabled={loading}
                        onSubmit={onAddClick}
                        className={
                            "btn-block btn-danger btn-purple btn-outline "
                        }
                        text={"Adicionar Pergunta"}
                    />
                </div>
                <div className="col-4">
                    <Button
                        disabled={loading}
                        onSubmit={onSubmit}
                        className={"btn-block btn-submit  "}
                        text={"Atualizar Questionário"}
                    />
                </div>
            </div>
        </div>
    );
};

export { QuestionnaireForm };
