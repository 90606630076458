import React from 'react';
import { InternBackHeader } from '../../molecules';

const InternPageHeader = ({ title, link, buttonAction, buttonText }) => {
  return (
    <div className="row mb-3 d-flex">
      <div className="col-12 d-flex align-items-center justify-content-between">
        <InternBackHeader link={link} title={title}/>
        {buttonAction &&
          <button onClick={() => buttonAction()} className="btn btn-primary">{buttonText}</button>
        }
      </div>
    </div>
  );
}

export { InternPageHeader };
