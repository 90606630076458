import React, { useEffect, useState } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { Paginator, SearchBar, Modal } from '../../molecules';
import { CardItemContainer, Button, Loader } from '../../atoms';
import { Player } from 'video-react';

import './style.scss';

const ListOfMedias = ({ onSelect, currentMedia, select, refresh, setRefresh }) => {
  const ACTION_TYPES = {
    LISTING: 'listing',
    SEARCHING: 'searching',
    DELETING: 'deleting'
  }
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState(ACTION_TYPES.LISTING);
  const [medias, setMedias] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const [isOpen, setIsOpen] = useState(false);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchMedias();
          break;
        case ACTION_TYPES.DELETING:
          deleteMedia();
          break;
        default:
          getMedias();
          break;
      }
    }
  }, [isAuthenticated, page, action]);

  useEffect(() => {
    if(refresh) {
      setRefresh(false);
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchMedias();
          break;
        case ACTION_TYPES.DELETING:
          deleteMedia();
          break;
        default:
          getMedias();
          break;
      }
    }
  }, [refresh])

  const getMedias = () => {
    setIsLoading(true);
    Api.get(`/api/medias?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setMedias(response.data.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const searchMedias = () => {
    setIsLoading(true);
    Api.get(`/api/medias/search/${search}?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setAction(ACTION_TYPES.SEARCHING);
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setMedias(response.data.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const deleteMedia = () => {
    if (currentMedia) {
      Api.delete(`/api/medias/${currentMedia.id}`, {}, `Bearer ${access_token}`)
        .then(response => {
          onSelect(null);
          setAction(ACTION_TYPES.LISTING);
          setPage(1);
        }).catch(erro => {
          if (erro.message == 'Unauthenticated.') {
            setIsAuthenticated(false);
          }
        })
    }
  }


  const renderMedias = () => {
    return medias.map(media =>
      <tr
        onClick={() => onSelect((media == currentMedia) ? null : media)}
        className={(currentMedia && media.id == currentMedia.id) ? "selected" : undefined}
        key={media.id}
      >
        <td><p className="video-name">{media.name}</p></td>
        <td className="text-right">{media.created_at}</td>
      </tr>
    )
  }


  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (search != '') {
      setPage(1);
      searchMedias();
    } else {
      setAction(ACTION_TYPES.LISTING);
      setPage(1);
    }
    onSelect(null);
  }

  const onSubmitDelete = () => {
    setAction(ACTION_TYPES.DELETING);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }


  return (
    <div className="row">
      <div className={`col-12 ${select?"select":undefined}`}>
        <div className="row mb-3">
          <div className="col-md-8">
            <SearchBar select={select} onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
          {(currentMedia && !select) &&
            <div className="col-md-4 d-flex justify-content-end">
              <Button text="Play" onSubmit={() => setIsOpen(true)} className="btn-primary" />
              <Button text="Deletar" onSubmit={onSubmitDelete} className="btn-danger btn-red btn-outline ml-3" />
            </div>
          }
        </div>
        <div className="w-100">
          {isLoading ?
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader />
              </div>
            </div>
            :
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-right">Data de criação</th>
                </tr>
              </thead>
              <tbody>
                {renderMedias()}
              </tbody>
            </table>
          }
          <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
        </div>
        {currentMedia &&
          <Modal closeModal={() => setIsOpen(false)} isOpen={isOpen} id={"modal-player"}>
            <Player
              id={`player-${currentMedia.id}`}
              src={`${process.env.MIX_API_URL}/api/medias/${currentMedia.id}`}
              fluid={true}
              className="mt-3"
            />
          </Modal>
        }
      </div>
    </div>

  );
}

export { ListOfMedias };
