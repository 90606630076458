import React from 'react';
import { Button, Input, SessionTitle } from '../../atoms';
import { Modal } from '..';

const PdfSession = ({ onChangeInputValue, uploading, id, title, icon, name, links = [] }) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const renderLinks = () => {
    return links.map(link => <a target="_blank" href={link.path}>{link.created_at}</a> )
  }

  return (
    <>
      <Modal closeModal={() => setModalOpen(false)} isOpen={modalOpen} id={`modal-arquivos-${title}`}>
        <div className="pl-4 pr-4">
          <div class="d-flex justify-content-between">
            <h3 className="font-weight-bold">Arquivos</h3>
          </div>
        </div>
        <div className="p-4">
          {links && renderLinks()}
        </div>
      </Modal>
      <div className="row">
        <div className="col-12">
          <SessionTitle name={title} icon={icon} />
        </div>
      </div>
      <div className="mt-1 ml-4 row">
        <div className="col-10">
          <Input
            onChangeInputValue={onChangeInputValue}
            label={"<b>Selecione o arquivo</b>"}
            name={name}
            id={id}
            type={"file"}
            disabled={uploading}
            accept={".pdf"} />
        </div>
        {
          links.length > 0 && (
            <div className="col-2">
              <Button text={"Arquivos"} onSubmit={() => setModalOpen(true)}/>
            </div>
          )
        }
      </div>
    </>
  );
}

export { PdfSession };