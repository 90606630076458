import React from 'react';
import { ClientStatus, EditButton } from '../../atoms';
import moment from 'moment';

const ClientTableLine = ({ client, onClickClient }) => {
  return (
    <tr onClick={() => onClickClient(client.id)}>
      <td>{client.user.first_name} {client.user.last_name}</td>
      <td className="status-column">
        <ClientStatus status={client.status} />
      </td>
      <td>{client.type == 'free' ? "Gratuito" : client.plans.length > 0 ? client.plans[0].name : "Pago"}</td>
      <td>{client.birth && moment(client.birth).format('DD/MM/YYYY')}</td>
      <td>{client.stage}</td>
      <td>
        <EditButton className="appear-on-hover" />
      </td>
    </tr>
  );
}

export { ClientTableLine };
