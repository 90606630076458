import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader } from '../../atoms';
import { QuestionTableLine, Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';

const ListOfQuestions = ({ onSelect, currentQuestion, setRefresh, refresh }) => {
  const ACTION_TYPES = {
    LISTING: 'listing',
    SEARCHING: 'searching'
  }
  const [action, setAction] = useState(ACTION_TYPES.LISTING);
  const [questions, setQuestions] = useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })

  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchQuestions();
          break;
        default:
          getQuestions();
          break;
      }
    }
  }, [isAuthenticated, page, action]);

  useEffect(() => {
    if(refresh) {
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchQuestions();
          break;
        default:
          getQuestions();
          break;
      }
      setRefresh(false);
    }
  }, [refresh])

  const getQuestions = () => {
    setIsLoading(true);
    Api.get('/api/questions', `Bearer ${access_token}`)
      .then(response => {
        console.log(response);
        setQuestions(response.data.data)
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const searchQuestions = () => {
    setIsLoading(true);
    Api.get(`/api/questions/search/${search}?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setAction(ACTION_TYPES.SEARCHING);
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setQuestions(response.data.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (search != '') {
      setPage(1);
      searchQuestions();
    } else {
      setAction(ACTION_TYPES.LISTING);
      setPage(1);
    }
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const renderQuestions = () => {
    return questions.map(question =>
      <QuestionTableLine
        key={question.id}
        onClickQuestion={() => {question == currentQuestion ? onSelect(null) : onSelect(question)}}
        question={question}
        selected={question && currentQuestion && question.id == currentQuestion.id}
      />
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-md-8">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Dúvida</th>
                    <th className="text-right">Data de Criação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderQuestions()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>
      </div>
    </div>
  );
}

export { ListOfQuestions };
