import React, { useState } from 'react';
import { Input, Button, Alert } from '../../../atoms';
import { ListOfDiagnosticoNutricional } from '../../../organisms';
import { DiagnosticoNutricionalForm } from '../../../molecules';
import { useAccessToken } from '../../../../hooks';

const DiagnosticoNutricional = ({ token, client, updateClient }) => {

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [currentSelected, setCurrentSelected] = useState(null);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
      </div>
      <Button text="Cadastrar Novo" className="btn-primary" onSubmit={() => setCurrentSelected(null)} />
      <div className="row mt-3">
          <div className="col-md-5">
            <ListOfDiagnosticoNutricional client={client} onSelect={setCurrentSelected} currentSelected={currentSelected} />
          </div>
          <div className="col-md-7">
            <DiagnosticoNutricionalForm currentSelected={currentSelected} token={token} client={client} updateClient={updateClient} setCurrentSelected={setCurrentSelected} />
          </div>
        </div>
    </>
  );
}

export { DiagnosticoNutricional };