import React from 'react';
import { Title, BackButton } from '../../atoms';

const InternBackHeader = ({ link, title }) => {
  return (
    <div className="internal-back-header">
      <BackButton link={link}/>
      <Title title={title}/>
    </div>
  );
}

export { InternBackHeader };
