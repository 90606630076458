import React from 'react';
import { HeaderAccordion, Button } from '../../atoms';
import './style.scss';

const Accordion = ({ title, closed, onClick, body, onEdit }) => {
  return (
    <div className={`d-flex flex-column accordion ${closed ? 'closed' : 'open'}`}>
      <HeaderAccordion title={title} closed={closed} onClick={onClick} />
      <div className='ml-2 mr-2 mb-2 pl-2 pr-2 accordion-body flex-column'>
        <p>{body}</p>
        <Button text={"Editar"} className={"btn-primary float-right"} onSubmit={onEdit} />
      </div>
    </div>
  );
}

export { Accordion };
