import React from 'react';

const SubSideItem = ({ name, icon, selected, onClick }) => {
  return (
    <div className="d-flex flex-fill p-3 m-2 subside-item-profile" onClick={onClick}>
      {
        icon && <img height="30" src={icon} alt="Icone" />
      }
      <small className={selected ? "active" : ""}>{name}</small>
    </div>
  );
}

export { SubSideItem };
