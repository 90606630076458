import React, { useState, useEffect } from "react";
import { Button, Alert } from "../../../atoms";
import { AvisoAguaForm, ListOfAvisosAgua } from "../../../molecules";
import { Api } from "../../../../services";
import { useAccessToken } from "../../../../hooks";
import { ListOfRefeicao } from "../../ListOfRefeicao";

const AvisoAgua = ({ client }) => {
	const [alert, setAlert] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(true);
	const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
	const [mealDaySelected, setMealDaySelected] = useState(null);
	const [avisos, setAvisos] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [loadingEditCreate, setLoadingEditCreate] = useState(false);


	useEffect(() => {
		getAvisos()
	}, [client]);

	const getAvisos = () => {
		Api.get(
			`/api/clients/${client.id}/waterNotification`,
			`Bearer ${access_token}`
		).then(res => {
			const { data } = res;
			console.log(data);
			setAvisos(data);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const adicionarMeals = () => {
		setMealDaySelected({
			hour: "",
		});
	};

	const submitNew = () => {
		setLoadingEditCreate(true);
		Api.post(
			`/api/clients/${client.id}/waterNotification`,
			mealDaySelected,
			`Bearer ${access_token}`
		).then(res => {
			getAvisos();
		})
			.catch((err) => {
				if (err.errors && Object.keys(err.errors).length > 0) {
					setAlert({ message: err.errors[Object.keys(err.errors)[0]][0], type: 'alert-danger' });
				} else {
					setAlert({ message: err.message, type: 'alert-danger' });
				}
			})
			.finally(() => {
				setLoadingEditCreate(false);
				setMealDaySelected(null);
			});
	}

	const editAviso = () => {
		setLoadingEditCreate(true);
		Api.put(
			`/api/clients/${client.id}/waterNotification/${mealDaySelected.id}`,
			mealDaySelected,
			`Bearer ${access_token}`
		).then(res => {
			getAvisos();
		})
			.catch((err) => {
				if (err.errors && Object.keys(err.errors).length > 0) {
					setAlert({ message: err.errors[Object.keys(err.errors)[0]][0], type: 'alert-danger' });
				} else {
					setAlert({ message: err.message, type: 'alert-danger' });
				}
			})
			.finally(() => {
				setLoadingEditCreate(false);
				setMealDaySelected(null);
			});
	}

	const deleteAviso = (id) => {
		Api.delete(
			`/api/clients/${client.id}/waterNotification/${id}`,
			{},
			`Bearer ${access_token}`
		).then(res => {
			getAvisos();
		})
			.catch((err) => {
				if (err.errors && Object.keys(err.errors).length > 0) {
					setAlert({ message: err.errors[Object.keys(err.errors)[0]][0], type: 'alert-danger' });
				} else {
					setAlert({ message: err.message, type: 'alert-danger' });
				}
			})
	}

	return (
		<>
			<div>
				{alert && <Alert text={alert.message} className={alert.type} />}
			</div>
			<hr />
			<div style={Styles.row}>
				<Button
					text="Cadastrar Novo"
					className="btn-primary"
					onSubmit={adicionarMeals}
				/>
			</div>
			<div className="row mt-3">
				<div className="col-md-5">
					<ListOfAvisosAgua
						onSelect={setMealDaySelected}
						avisos={avisos}
						mealDaySelected={mealDaySelected}
						deleteAviso={deleteAviso}
						isLoading={isLoading}
					/>
				</div>
				<div className="col-md-7">
					{mealDaySelected && (
						<AvisoAguaForm
							mealDaySelected={mealDaySelected}
							setMealDaySelected={setMealDaySelected}
							loading={loadingEditCreate}
							submitNew={submitNew}
							editAviso={editAviso}
						/>
					)}
				</div>
			</div>
		</>
	);
};

const Styles = {
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	estrategia: {
		display: "flex",
		marginLeft: "32px",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center"
	},
	estrategia_text: {
		color: "#414141",
		fontWeight: "bold",
		fontSize: "16px",
		marginRight: "8px"
	}
};

export { AvisoAgua };
