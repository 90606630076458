import React from 'react';
import { NewPasswordForm } from '../organisms';

const NewPasswordPage = ({ match }) => {
  return (
    <div className="login-container">
      <div className="login-sidebar">
        <div>
          <div className="login-text">
            <img className="login-logo" src="/img/logo-pnp.svg" />
            <h3>Bem-vindo à recuperação de senha</h3>
          </div>
          <NewPasswordForm token={match.params.token}/>
        </div>
      </div>
      <div className="login-imageholder">
      </div>
    </div>
  )
}

export { NewPasswordPage };
