import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { ActionsStatuses, Topic } from '../../molecules';
import { Button, Loader } from '../../atoms';

const StageForm = ({ currentStage, setRefresh, setCurrentStage }) => {
  const ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  }
  const [action, setAction] = useState(ACTION_TYPES.CREATE);
  const [shouldCall, setShouldCall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [stage, setStage] = useState({
    topics: [
      {
        title: "",
        subtitle: ""
      }
    ]
  });
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated && shouldCall) {
      setShouldCall(false);
      switch (action) {
        case ACTION_TYPES.CREATE:
          createStage();
          break;
        case ACTION_TYPES.DELETE:
          deleteStage();
          break;
        default:
          updateStage();
          break;
      }
    }
  }, [isAuthenticated, shouldCall])

  useEffect(() => {
    if (currentStage && currentStage.id) {
      setStage(currentStage);
    } else {
      setStage({
        topics: [
          {
            title: "",
            subtitle: ""
          }
        ] 
      });
    }
  }, [currentStage])

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  const deleteStage = () => {
    setLoading(true);
    Api.delete(`/api/stages/${currentStage.id}`, {}, `Bearer ${access_token}`)
    .then(_ => {
      setLoading(false);
      setSucesso(true);
      setRefresh(true);
    })
    .catch(erro => {
      if (erro.message == 'Unauthenticated.') {
        setIsAuthenticated(false);
      } else {
        setLoading(false);
        setErro(erro.message);
      }
    })
  }

  const createStage = () => {
    setLoading(true);
    Api.post(`/api/stages`, {topics: stage.topics.filter((topic) => topic.title)}, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
        setCurrentStage({});
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (erro.errors && Array.isArray(Object.keys(erro.errors)) && Object.keys(erro.errors)[0]) {
          setLoading(false);
          setErro(erro.errors[Object.keys(erro.errors)[0]].join(' '));
        } else if (erro.message) {
          setLoading(false);
          setErro(erro.message);
        }
      })
  }

  const updateStage = () => {
    setLoading(true);
    Api.post(`/api/stages/update`, {
        id: stage.id,
        topics: stage.topics.filter((topic) => topic.title)
      }, `Bearer ${access_token}`)
      .then(_ => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (erro.errors && Array.isArray(Object.keys(erro.errors)) && Object.keys(erro.errors)[0]) {
          setLoading(false);
          setErro(erro.errors[Object.keys(erro.errors)[0]].join(' '));
        } else if (erro.message) {
          setLoading(false);
          setErro(erro.message);
        }
      })
  }

  const onSubmit = () => {
    if (currentStage && currentStage.id) {
      setAction(ACTION_TYPES.UPDATE);
      setShouldCall(true);
    } else {
      setAction(ACTION_TYPES.CREATE);
      setShouldCall(true);
    }
  }

  const newTopic = () => {
    setStage({
      ...stage, 
      topics: [
        ...stage.topics, 
        {
          title: "",
          subtitle: ""
        }
      ]
    });
  }

  const removeTopic = (index) => {
    let topics = [...stage.topics];
    topics.splice(index, 1);
    setStage({
      ...stage, 
      topics: topics
    });
  }

  const onDelete = () => {
    setAction(ACTION_TYPES.DELETE);
    setShouldCall(true);
  }

  const changeTopic = (index, topic) => {
    let topics = [...stage.topics];
    topics[index] = topic;
    setStage({...stage, topics: topics});
  }

  const renderTopics = () => {
    return stage.topics.map((topic, index) => 
      <Topic key={"topic-" + index} focus={index == (stage.topics.length - 1)} removeTopic={removeTopic} topic={topic} index={index} changeTopic={changeTopic}/>
    )
  }

  return (
    <div className="row h-100">
      <div className="col-12">
        <div className="row h-100">
          <div className="col-md-8 d-flex flex-fill h-100">
            <div className="scroll" style={{"height": "70vh"}}>
              <div className="h-100 column-flex justify-content-start">
                {renderTopics()}
              </div>
            </div>
          </div>
          <div className={`col-4 d-flex flex-column ${stage ? "justify-content-between" : "justify-content-end"}`}>
            <div className="w-100">
              <Button disabled={loading} onSubmit={newTopic} className={"btn-block btn-submit mb-5"} text={"Adicionar tópico"} />
            </div>
            <div className="w-100 mt-5">
              <Button disabled={loading} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={currentStage && currentStage.id ? "Atualizar Etapa" : "Cadastrar Etapa"} />
              {/* <Button disabled={loading || stage.id == null} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Etapa"} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        {loading &&
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader />
            </div>
          </div>
        }
        <div className="row w-100 m-0">
          <div className="col-12">
            <ActionsStatuses sucesso={sucesso} erro={erro} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { StageForm }
