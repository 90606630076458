import React from 'react';
import { Player } from 'video-react';

const PlayerWithLabel = ({ label, media_id, height }) => {
  return (
    <div>
      <label htmlFor={`player-${media_id}`} dangerouslySetInnerHTML={{ __html: label }}/>
      <Player
        id={`player-${media_id}`}
        src={`${process.env.MIX_API_URL}/api/medias/${media_id}`}
        fluid={true}
      />
    </div>
  );
}

export { PlayerWithLabel };
