import React from "react";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import {
  LoginPage,
  HomePage,
  ChatPage,
  ConfigPage,
  EditUserPage,
  DuvidasPage,
  VideosPage,
  PlanosPage,
  PlanoOptionsPage,
  MediasPage,
  ClientsPage,
  CreateClient,
  EtapasPage,
  TreinamentosPage,
  Foods,
  Questionnaire,
  ReplacementList,
  ForgetPasswordPage,
  NewPasswordPage,
  NotificationPage,
  UsersReport,
  QuestionnairePrint,
} from './components/pages';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/pacientes/:userid" component={EditUserPage} />
        <Route path="/pacientes" component={ClientsPage} />
        <Route path="/cadastrar-paciente" component={CreateClient} />
        <Route path="/chat" component={ChatPage} />
        <Route path="/midias" component={MediasPage} />
        <Route path="/config/duvidas" component={DuvidasPage} />
        <Route path="/config/planos" component={PlanoOptionsPage} />
        <Route path="/config/videos" component={VideosPage} />
        <Route path="/config/treinamentos" component={TreinamentosPage} />
        <Route path="/config" component={ConfigPage} />
        <Route path="/home" component={HomePage} />
        <Route path="/planos" component={PlanosPage} />
        <Route path="/etapas" component={EtapasPage} />
        <Route path="/lista-alimentos" component={Foods} />
        <Route path="/questionnaire" exact component={Questionnaire} />
        <Route path="/questionnaire/:type" component={QuestionnairePrint} />
        <Route path="/replacement-list" component={ReplacementList} />
        <Route path="/redefinir-senha/:token" component={NewPasswordPage} />
        <Route path="/esqueci-minha-senha" component={ForgetPasswordPage} />
        <Route path="/notificacao" component={NotificationPage} />
        <Route path="/relatorio-usuarios-inativos" component={UsersReport} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </BrowserRouter>
  );
}
