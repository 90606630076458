import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { ActionsStatuses, PlayerWithSplash } from '../../molecules';
import { Button, Loader, Input, TextArea, SelectInput } from '../../atoms';
import { ListOfMedias } from '../ListOfMedias';

const TrainingForm = ({ currentTraining, setRefresh, setCurrentTraining }) => {
  const ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  }
  const [action, setAction] = useState(ACTION_TYPES.CREATE);
  const [shouldCall, setShouldCall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [training, setTraining] = useState(null);
  const [stages, setStages] = useState([]);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    getStages();
    if (isAuthenticated && shouldCall) {
      setShouldCall(false);
      switch (action) {
        case ACTION_TYPES.CREATE:
          createTraining();
          break;
        case ACTION_TYPES.DELETE:
          deleteTraining();
          break;
        default:
          updateTraining();
          break;
      }
    }
  }, [isAuthenticated, shouldCall])

  useEffect(() => {
    if (currentTraining && currentTraining.id) {
      setTraining(currentTraining);
    } else {
      setTraining(null);
    }
  }, [currentTraining])

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  const getStages = () => {
    Api.get('/api/allstages', `Bearer ${access_token}`)
      .then(response => {
        setStages(response.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const deleteTraining = () => {
    setLoading(true);
    Api.delete(`/api/trainings/${currentTraining.id}`, {}, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          //setShouldCall(true);
          setLoading(false);
          setErro(erro.message);
        }
      })
  }

  const createTraining = () => {
    setLoading(true);
    Api.post(`/api/trainings`, training, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
        setCurrentTraining(null);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          console.log('erro', erro);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          //setShouldCall(true);
          setLoading(false);
        }
      })
  }

  const updateTraining = () => {
    setLoading(true);
    Api.post(`/api/trainings/update`, training, `Bearer ${access_token}`)
      .then(_ => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          //setShouldCall(true);
          setLoading(false);
        }
      })
  }

  const onSubmit = () => {
    if (currentTraining && currentTraining.id) {
      setAction(ACTION_TYPES.UPDATE);
      setShouldCall(true);
    } else {
      setAction(ACTION_TYPES.CREATE);
      setShouldCall(true);
    }
  }


  const onDelete = () => {
    setAction(ACTION_TYPES.DELETE);
    setShouldCall(true);
  }

  const changeVideo = (media) => {
    let aux = { ...training };
    aux.media = media ? media : null;
    aux.media_id = media ? media.id : null;
    setTraining(aux);
  }

  const onChangeInputValue = (name, value) => {
    let aux = { ...training };
    switch (name) {
      case "stage_id":
        aux.stage_id = value;
        break;
      case "title":
        aux.title = value;
        break;
      default:
        aux.description = value;
        break;
    }
    setTraining(aux);
  }

  return (
    <div className="row">
      <div className="col-8">
        <div className="row">
          <div className="col-3">
            <SelectInput
              id={"stage"}
              label={"id"}
              value={"id"}
              name={"Etapa"}
              selected={training ? training.stage_id : 'Selecione'}
              options={[{ id: 'Selecione' }, ...stages]}
              onSelect={(e) => setTraining({ ...training, stage_id: e.target.value })}
            />
          </div>
          <div className="col-9">
            <Input
              type={"text"}
              id={"title"}
              name={"title"}
              onChangeInputValue={onChangeInputValue}
              label={"<b>Título</b>"}
              value={training ? training.title : ""}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextArea
              label={training ? "Quer atualizar a <b>descrição</b>?" : "<b>Descrição</b> do treinamento"}
              value={training ? training.description : ""}
              onChangeInputValue={onChangeInputValue}
              id={"description"}
              name={"description"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ListOfMedias select={true} currentMedia={training ? training.media : null} type={"select"} onSelect={changeVideo} />
          </div>
        </div>
      </div>
      <div className={`col-4 d-flex flex-column justify-content-end`}>
        <PlayerWithSplash media={training ? training.media : null} />
        <div className="w-100 mt-5">
          <Button disabled={loading} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={currentTraining && currentTraining.id ? "Atualizar Treinamento" : "Cadastrar Treinamento"} />
          <Button disabled={loading || (currentTraining == null) ? true : false} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Treinamento"} />
        </div>
        {loading &&
          <div className="mt-2 w-100 text-center">
            <Loader />
          </div>
        }
        <div className="w-100 mt-3">
          <ActionsStatuses sucesso={sucesso} erro={erro} />
        </div>
      </div>
    </div>
  )
}

export { TrainingForm }
