import React, { useState, useEffect } from 'react'
import { useAccessToken } from '../../../hooks'
import { Api } from '../../../services'

const ListOfNotifications = () => {
  const [notifications, setNotifications] = useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const accessToken = useAccessToken(isAuthenticated, setIsAuthenticated)

  useEffect(() => {
    Api.get('/api/notification', `Bearer ${accessToken}`)
      .then(({ data }) => {
        setNotifications(data)
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false)
        }
      })
  }, [])

  return (
    <div className='row'>
      <div className='col-12'>
        <table class='table'>
          <thead>
            <tr>
              <th scope='col'>Data Enviada</th>
              <th scope='col'>Mensagem</th>
            </tr>
          </thead>
          <tbody>
            {
              notifications.map((n) => (
                <tr key={n.id}>
                  <td>{n.created_at}</td>
                  <td>{n.message}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export { ListOfNotifications }
