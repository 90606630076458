import React, { useState } from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ListOfTraining, TrainingForm } from '../organisms';

const TreinamentosPage = () => {
  const [currentTraining, setCurrentTraining] = useState(null);
  const [cadastrar, setCadastrar] = useState(false);
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/config"} title={"Treinamentos"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ListOfTraining refresh={refresh} setCadastrar={setCadastrar} setRefresh={setRefresh} onSelect={setCurrentTraining} currentTraining={currentTraining} />
          </div>
          <div className="col-md-7">
            {(currentTraining || cadastrar) && <TrainingForm setRefresh={setRefresh} currentTraining={currentTraining} setCurrentTraining={setCurrentTraining} />}
          </div>
        </div>
      </DashboardContent>
    </div>
  )
}

export { TreinamentosPage }
