import React from 'react';
import { CardItem } from '../../molecules';

const ConfigOptions = () => {
  const options = [
    {
      link: '/config/duvidas',
      content: {
        image: '/img/duvidas.svg',
        title: 'Dúvidas',
        text: 'Clique para adicionar perguntas frequentes ou editar a lista de dúvidas.'
      }
    },
    {
      link: '/config/videos',
      content: {
        image: '/img/video.svg',
        title: 'Vídeos das telas',
        text: 'Clique e gerencie os vídeos de cada tela do seu App.'
      }
    },
    {
      link: '/config/treinamentos',
      content: {
        image: '/img/duvidas.svg',
        title: 'Treinamentos',
        text: 'Descrição de treinamentos'
      }
    }
  ];

  const renderOtions = () => {
    return options.map(option =>
      <div key={option.link} className="col-md-4">
        <CardItem link={option.link} content={option.content}/>
      </div>
      )
  }

  return (
    <div className="row">
      {renderOtions()}
    </div>
  );
}

export { ConfigOptions };
