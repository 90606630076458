import React from 'react';

const Input = ({ label, type, name, id, onChangeInputValue, value, accept, disabled, small, readOnly, className= "", noLabel=false, focus,placeholder }) => {
  return (
    <div className="form-group">
      { !noLabel && <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} /> }
      {disabled ?
        <input placeholder={placeholder} value={value} disabled onChange={() => onChangeInputValue(name, type == 'file' ? event.target.files[0] : event.target.value)} accept={accept} type={type} className={`form-control ${className}`} name={name} id={id} />
        :
        <input placeholder={placeholder} autoFocus={focus} readOnly={readOnly} value={value} onChange={() => onChangeInputValue(name, type == 'file' ? event.target.files[0] : event.target.value)} accept={accept} type={type} className={`form-control ${className}`} name={name} id={id} />
      }
      {small && <small>{small}</small>}
    </div>
  )
}

export { Input }
