import React from 'react';
import { Link } from 'react-router-dom';
import { CardItemContainer, CardItemIcon, CardItemText } from '../../atoms';

const CardItem = ({ link, content, spotlight }) => {
  return (
    <Link className={`card-item-link ${spotlight == true? "spotlight" : ""}`} to={link}>
      <CardItemContainer>
        <CardItemIcon image={content.image}/>
        <CardItemText title={content.title} text={content.text}/>
      </CardItemContainer>
    </Link>
  );
}

export { CardItem };
