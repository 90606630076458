import React, { useState, useEffect } from "react";
import { useAccessToken } from "../../../hooks";
import { Api } from "../../../services";
import { Input, Loader } from "../../atoms";
import "./QuestionnairePrint.scss";

const QuestionnairePrint = ({ match }) => {
    const [form, setForm] = useState(null);
    const [erro, setErro] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [loading, setLoading] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

    useEffect(() => {
        if (access_token) {
            setLoading(true);
            Api.get(
                `/api/quiz/${match.params.type}/show`,
                `Bearer ${access_token}`
            )
                .then((response) => {
                    setForm(response.data);
                })
                .catch((erro) => {
                    if (erro.message == "Unauthenticated.") {
                        setIsAuthenticated(false);
                    } else {
                        if (erro.errors) {
                            setErro(
                                erro.errors[Object.keys(erro.errors)[0]][0]
                            );
                        } else {
                            setErro(erro.message);
                        }
                    }
                    console.log(erro);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [access_token]);

    const renderResponseByType = (item) => {
        switch (item.type) {
            case "option":
                return (
                    <>
                        <p className="mb-3">Marcação múltipla:</p>
                        {item.alternatives.map((alternative) => (
                            <>
                                <label>
                                    <input
                                        onChange={(event) =>
                                            handleOptionChange(event, i)
                                        }
                                        type="checkbox"
                                        className="mr-2"
                                        value="option2"
                                    />
                                    {alternative.title}
                                </label>
                            </>
                        ))}
                    </>
                );
                break;
            case "single":
                return (
                    <>
                        <p className="mb-3">Marcação única':</p>
                        {item.alternatives.map((alternative) => (
                            <>
                                <label>
                                    <input
                                        onChange={(event) =>
                                            handleOptionChange(event, i)
                                        }
                                        type="radio"
                                        className="mr-2"
                                        value="option2"
                                    />
                                    {alternative.title}
                                </label>
                            </>
                        ))}
                    </>
                );
                break;
            case "slider":
                return <>
                    <label>De 1 a 10:</label>
                    <input/>
                </>;
                break;
            default:
                return <>
                    <label>Resposta:</label>
                    <textarea rows={2}/>
                </>;
                break;
        }
    };

    const renderQuestions = (questions) => {
        return questions.map((item, i) => (
            <div key={`question-${i}`} className="d-flex flex-column col-12">
                <div className="row">
                    <div className="col-11">
                        <Input
                            id={`input-${item.id}`}
                            name={`input-${item.id}`}
                            label={`Pergunta ${i + 1}`}
                            value={item.title}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {item.image && <img src={item.image} />}
                    </div>
                </div>
                <div className="d-flex flex-column p-5">
                    {renderResponseByType(item)}
                </div>
                <hr />
            </div>
        ));
    };

    return (
        <div className="printContainer">
            {loading && (
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <Loader />
                    </div>
                </div>
            )}
            {form && (
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <h1>{form.name}</h1>
                        <button
                            className="btn btn-primary noPrint"
                            onClick={() => {
                                window.print();
                            }}
                        >
                            Imprimir
                        </button>
                    </div>
                    <div className="mt-5">
                        {renderQuestions(form.quiz_questions)}
                    </div>
                </>
            )}
        </div>
    );
};

export { QuestionnairePrint };
