import React, { useState, useEffect } from 'react';
import { Loader } from '../../atoms';
import { Player } from 'video-react';

const PlayerWithSplash = ({ media }) => {
  const [playing, setPlaying] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    setPlaying(false);
    if (media) setLoadingImage(!!media.thumbnail_url)
  }, [media])


  return (
    media ?
      <div className="player-with-splash w-100">
        {playing ?
          <Player
            id={`player-${media.id}`}
            autoPlay={true}
            src={`${process.env.MIX_API_URL}/api/medias/${media.id}`}
            fluid
          />
          :
          media.thumbnail_url ?
            <div>
              {
                loadingImage && (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                )
              }
              <img onError={() => setLoadingImage(false)} onLoad={() => setLoadingImage(false)} onClick={() => setPlaying(true)} src={media.thumbnail_url} />
            </div>
            :
            <Player
              id={`player-${media.id}`}
              src={`${process.env.MIX_API_URL}/api/medias/${media.id}`}
              fluid
            />
        }
      </div>
      : null
  );
}

export { PlayerWithSplash };
