import React from 'react';

const PlanTableLine = ({ plan, onClickPlan, selected }) => {
  return (
    <tr className={selected ? "selected" : undefined} onClick={onClickPlan}>
      <td >{plan.name}</td>
      <td className="text-right">
        {plan.updated_at}
      </td>
    </tr>
  );
}

export { PlanTableLine };
