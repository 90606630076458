import React from 'react';
import './style.scss';

const SelectInput = ({ id, name, label, hideLabel, options, value, selected, onSelect }) => {

  return (
    <div className="form-group select-input">
      {!hideLabel &&
        <label htmlFor={id}>{name}</label>
      }
      <select className="form-control" id={id} value={selected} onChange={onSelect}>
        {options.map((option, index) =>
          <option key={`option-${name}-${index}`} value={value ? option[value]: option} >{label ? option[label]: option}</option>
        )}
      </select>
    </div>
  );
}

export { SelectInput };
