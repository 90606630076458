import React from 'react';

const NoData = ({ message }) => {

  return (
    <div className="d-flex flex-fill flex-column no-data">
      <img alt="no-data" height="200" src="/img/no-data.svg" />
      <h5 className="text-align-center">{message ? message : "Não encontrado"}</h5>
    </div>
  );
}

export { NoData };
