import React from 'react'

const Status = ({ status, className }) => {
  return (
    <div className={`status largura-140 mr-5 text-center ${className}`}>
      {status}
    </div>
  )
}

export { Status }
