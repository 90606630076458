import React, { useState, useEffect } from "react";
import { TextArea, Button, Input, Alert } from "../../atoms";
import { Modal, Accordion } from "../../molecules";
import { Api } from "../../../services";
import { useAccessToken } from "../../../hooks";
import "./style.scss";
import momentjs from "moment";

const ObservationModal = ({ client, tab_name }) => {
    const [openModelObservation, setOpenModelObservation] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [form, setForm] = useState({});
    const [observationList, setObservationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        getObservationList();
        setForm({ comment: "", title: "" })
        setAlert(null);
    }, [client.id, tab_name]);

    useEffect(() => {
      if (alert) {
        setTimeout(() => {
          setAlert(null)
        }, [2000])
      }
    }, [alert])

    const getObservationList = () => {
        Api.get(
            `/api/clients/${client.id}/observation/${tab_name}`,
            `Bearer ${access_token}`
        )
            .then((res) => {
                setObservationList(res.data);
            })
            .catch((err) => err);
    };

    const update = () => {
        setLoading(true);
        setAlert(null);
        Api.post(
            `/api/clients/${client.id}/observation/${tab_name}/${form.id}`,
            { ...form },
            `Bearer ${access_token}`
        )
            .then((res) => {
                getObservationList();
                setAlert({ message: "Alterado com sucesso", type: "alert-success" });
                // setOpenModelObservation(false);
            })
            .catch((err) => {
                if (err.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else if (
                    err.errors &&
                    Array.isArray(Object.keys(err.errors)) &&
                    Object.keys(err.errors)[0]
                ) {
                    setAlert({
                        message:
                            err.errors[Object.keys(err.errors)[0]].join(" "),
                        type: "alert-danger",
                    });
                } else if (err.message) {
                    setAlert({ message: err.message, type: "alert-danger" });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const create = () => {
        setLoading(true);
        setAlert(null);
        Api.post(
            `/api/clients/${client.id}/observation/${tab_name}`,
            { ...form },
            `Bearer ${access_token}`
        )
            .then((res) => {
                getObservationList();
                setForm({ comment: "", title: "" })
                setAlert({ message: "criado com sucesso", type: "alert-success" });
                // setOpenModelObservation(false);
            })
            .catch((err) => {
                if (err.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else if (
                    err.errors &&
                    Array.isArray(Object.keys(err.errors)) &&
                    Object.keys(err.errors)[0]
                ) {
                    setAlert({
                        message:
                            err.errors[Object.keys(err.errors)[0]].join(" "),
                        type: "alert-danger",
                    });
                } else if (err.message) {
                    setAlert({ message: err.message, type: "alert-danger" });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="mx-1">
            <div className="d-flex">
                <Button
                    className="btn-light-primary"
                    onSubmit={() => setOpenModelObservation(true)}
                    text="Observação"
                />
            </div>
            <Modal
                size="large"
                closeModal={() => setOpenModelObservation(false)}
                isOpen={openModelObservation}
                id={"modal-observation-nutricional"}
            >
                <div className="pl-4 pr-4">
                    {alert && (
                        <Alert text={alert.message} className={alert.type} />
                    )}
                    <div className="d-flex row">
                        <div className="col flex-column observation-list">
                            {observationList.map((obs, i) => (
                                <Accordion
                                    title={
                                        obs.title ||
                                        obs.created_at.split(" ")[0]
                                    }
                                    closed={!obs.open}
                                    onClick={() =>
                                        setObservationList(
                                            observationList.map((o) =>
                                                o.id === obs.id
                                                    ? { ...o, open: !obs.open }
                                                    : { ...o, open: false }
                                            )
                                        )
                                    }
                                    onEdit={() => { setAlert(null); setForm(obs)}}
                                    body={obs.comment}
                                />
                            ))}
                        </div>
                        <div className="col flex-column">
                            <Input
                                label="Titulo"
                                value={form.title}
                                onChangeInputValue={(name, value) =>
                                    setForm({ ...form, title: value })
                                }
                            />
                            <TextArea
                                label="Comentário"
                                row={6}
                                inputClassname={"bg-white"}
                                value={form.comment}
                                onChangeInputValue={(_, value) => {
                                    setForm({ ...form, comment: value });
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-5">
                        <Button
                            text={"Nova Observação"}
                            onSubmit={() => setForm({ comment: "", title: "" })}
                            className="btn-submit"
                        />
                        <Button
                            text={
                                form.id
                                    ? "Atualizar Observação"
                                    : "Criar Observação"
                            }
                            onSubmit={() => (form.id ? update() : create())}
                            disabled={!form.comment || loading}
                            className="btn-submit"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export { ObservationModal };
