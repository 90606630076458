import React, { useState } from 'react';
import { CardItemContainer, Input, Button } from '../../atoms';
import {
  TabCadastro,
  ExamesBioquimicos,
  AvaliacaoFisica,
  AvaliacaoEmocional,
  DiagnosticoNutricional,
  PatientJourney
} from '../../organisms';

const CreateClientContainer = ({ }) => {

  return (
    <CardItemContainer>
      <div className="create-client-form h-100 d-flex">
        <TabCadastro />
      </div>
    </CardItemContainer>
  );
}

export { CreateClientContainer };
