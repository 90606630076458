import React from 'react'
import { Loader } from '../../atoms';

const ListOfAvisosAgua = ({ isLoading, avisos, onSelect, deleteAviso, mealDaySelected }) => {
	const renderAvisos = () => {
		console.log("AVISOS: ", avisos);
		return avisos.map((meal) => (
			<tr
				className={mealDaySelected && meal.id === mealDaySelected.id && mealDaySelected.meal_id === meal.meal_id ? "tr-selected" : ""}
				onClick={() => onSelect(meal)}
				key={`${meal.id}-${meal.meal_id}`}
			>
				<td>{meal.hour}</td>
				<td className="text-right" style={{ width: '30px' }}>
					<div className="delete-button" onClick={e => {
						e.stopPropagation();
						deleteAviso(meal.id);
					}}>
						<img className="delete" src="/img/delete.svg" />
					</div>
				</td>
			</tr>
		)
		);
	}

	return (
		<div className="row">
			<div className="col-12">
				{isLoading ?
					<div className="row">
						<div className="col-12 d-flex justify-content-center">
							<Loader />
						</div>
					</div>
					:
					<table className="table">
						<thead>
							<tr>
								<th>Horário</th>
								<th className="text-right">Excluir</th>
							</tr>
						</thead>
						<tbody>
							{renderAvisos()}
						</tbody>
					</table>
				}
			</div>
		</div>
	)
}

export { ListOfAvisosAgua }
