import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { HomeOptions } from '../organisms';

function HomePage() {
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <HomeOptions />
      </DashboardContent>
    </div>
  )
}

export { HomePage };
