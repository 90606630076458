import React, { useState } from 'react';
import { LoginFormGroup } from '../../molecules';
import { useHistory } from "react-router-dom";
import { Api } from '../../../services/Api';

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isBusy, setIsBusy] = useState(false)
  const history = useHistory();

  const onChangeInputValue = (name, value) => {
    if (name == 'email') {
      setEmail(value);
    } else {
      setPassword(value);
    }
  }

  const onSubmit = () => {
    setIsBusy(true);
    setError("");
    const body = Api.link_client({
      username: email,
      password: password
    });
    Api.post('/oauth/token', body)
      .then(response => {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        history.push('/home');
      })
      .catch(erro => {
        if (erro.message === "Email not found") {
          setError("E-mail não encontrado");
        } else if (erro.error === "invalid_grant") {
          setError("Senha incorreta");
        } else {
          setError("Ocorreu um erro, tente novamente mais tarde");
        }
      })
      .finally(() => setIsBusy(false));
  }

  return (
    <LoginFormGroup email={email} isBusy={isBusy} password={password} error={error} onChangeInputValue={onChangeInputValue} onSubmit={onSubmit.bind(this)} />
  );
}

export { LoginForm };
