import React from 'react';
import { ButtonIcon } from '../../atoms';

const Paginator = ({previousPage, nextPage, pagination, page}) => {
  return (
    <div className="row paginator">
      <div className="col-12 d-flex justify-content-end align-items-center">
        {pagination.to && <span className="mr-2">{(page == 1) ? 1 : ((page - 1) * 10)}-{pagination.to} de {pagination.total}</span>}
        <ButtonIcon disabled={page == 1} onClick={previousPage} >
          <img className="flip-left" src="/img/arrow-right.svg" alt="imagem do botão de voltar" />
        </ButtonIcon>
        <ButtonIcon disabled={pagination.to == pagination.total} onClick={nextPage} className="ml-2">
          <img src="/img/arrow-right.svg" alt="imagem do botão de prosseguir" />
        </ButtonIcon>
      </div>
    </div>
  );
}

export { Paginator };
