import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, NotificationForm, ListOfNotifications } from '../organisms';

const NotificationPage = ({ }) => {
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <InternPageHeader link={"/home"} title={"Notificação no App"} />
        <div className="card">
          <NotificationForm />
          <ListOfNotifications />
        </div>
      </DashboardContent>
    </div>
  )
}

export { NotificationPage };
