import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader, Button } from '../../atoms';
import { Paginator } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';

const ListOfStages = ({ onSelect, currentStage, setRefresh, refresh }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stages, setStages] = useState([]);  
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      getStages();
    }
  }, [isAuthenticated, page])

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getStages();
    }
  }, [refresh]);

  const getStages = () => {
    setIsLoading(true);
    Api.get('/api/stages', `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setStages(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const renderStages = () => {
    return stages.map((stage, index) =>
      <tr
        className={(stage.id == (currentStage ? currentStage.id : null)) ? "selected" : undefined}
        onClick={() => onSelect((stage.id == (currentStage ? currentStage.id : null)) ? null : stage)}
        key={"stage" + stage.id}
      >
        <td>Etapa {index + 1}</td>
        <td className="text-right">{stage.updated_at}</td>
      </tr>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-md-4">
            <Button onSubmit={() => onSelect({})} className={"btn-block btn-danger btn-purple "} text={'Cadastrar'} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="text-right">Data de cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  {renderStages()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>
      </div>
    </div>
  )
}

export { ListOfStages }
