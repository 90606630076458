import React, { useState } from 'react';
import { TextArea, Button, Alert } from '../../atoms';
import { Api } from '../../../services/Api';
import { useAccessToken } from '../../../hooks';


const NotificationForm = ({ }) => {
  const [message, setMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  const onChangeInputValue = (_, value) => {
    setMessage(value);
  }

  const onSubmit = () => {
    setAlert(null);
    setIsBusy(true);
    const body = {
      message
    };
    Api.post(`/api/notification`, body, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setMessage("");
      })
      .catch(erro => {
        setAlert({ message: erro.message, type: 'alert-danger' });
      })
      .finally(() => setIsBusy(false));
  }

  return (
    <div className="form-container">
      {
        alert && <Alert text={alert.message} className={alert.type} />
      }
      <div>
        <TextArea row={3}
          id="message"
          label="Mensagem"
          name="message"
          value={message}
          onChangeInputValue={onChangeInputValue} />
        <div className="mt-2 actions mb-5">
          <Button disabled={isBusy} onSubmit={onSubmit} className={"btn-submit"} text={"ENVIAR"} />
        </div>
        <div className="mt-3">
          {/* <ActionsStatuses sucesso={success} erro={error} /> */}
        </div>
      </div>
    </div>
  )
}

export { NotificationForm }
