import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const SideBarLink = ({ image, to, active, badge }) => {
  return (
    <Link className={`menu-item position-relative ${active?'active':''}`} to={to}>
      {
        badge ? (<span className="badge">{badge}</span>) : null
      }
      <img className="icon" src={image} />
    </Link>
  );
}

export { SideBarLink };
