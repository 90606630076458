import React, { useState, useEffect } from 'react';
import { SelectInput, Button, Input, Alert } from '../../atoms';
import { Modal } from '../../molecules';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import './style.scss';

const NutritionalInfo = ({ client, updateClient }) => {

  const [openModelRecomNutricional, setOpenModelRecomNutricional] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setForm(client && client.recomendation_nutritional ? { ...client.recomendation_nutritional } : {});
  }, [client])

  const updateRecomendation = () => {
    setLoading(true)
    setAlert(null)
    Api.put(`/api/clients/${client.id}/nutritionalrecomendation`, { ...form }, `Bearer ${access_token}`).then(res => {
      updateClient(res.data);
      setAlert({ message: res.message, type: 'alert-success' });
      setOpenModelRecomNutricional(false);
    })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="mx-1">
      <div className="d-flex">
        <Button className="btn-light-primary" onSubmit={() => setOpenModelRecomNutricional(true)} text="Recomendação Nutricional" />
      </div>
      <Modal closeModal={() => setOpenModelRecomNutricional(false)} isOpen={openModelRecomNutricional} id={"modal-recomendacao-nutricional"}>
        <div className="pl-4 pr-4">
          <h3 className="font-weight-bold">Recomendação Nutricional Atual</h3>
          {
            alert && <Alert text={alert.message} className={alert.type} />
          }
          <div className="d-flex flex-column">
            <Input value={form.total} className="input-nutritional-info recomendation-total"
              type="text" id="total-caloria" onChangeInputValue={(total, value) => setForm({ ...form, total: value })} />
          </div>
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-2 offset-6"><small className="font-weight-bold">% Proteína</small></div>
            <div className="col-2"><small className="font-weight-bold">% Carboídrato</small></div>
            <div className="col-2"><small className="font-weight-bold">% Lípidio</small></div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-2 offset-6">
              <Input noLabel={true} value={form.proteina_total} className="input-nutritional-info"
                type="text" id="proteina_total" onChangeInputValue={(proteina_total, value) => setForm({ ...form, proteina_total: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.carboidrato_total} className="input-nutritional-info"
                type="text" id="carboidrato_total" onChangeInputValue={(carboidrato_total, value) => setForm({ ...form, carboidrato_total: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.lipidio_total} className="input-nutritional-info"
                type="text" id="lipidio_total" onChangeInputValue={(lipidio_total, value) => setForm({ ...form, lipidio_total: value })} />
            </div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-6 label-table">
              <small className="font-weight-bold">Distribuição por grama</small>
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.proteina_grama} className="input-nutritional-info"
                type="text" id="proteina_grama" onChangeInputValue={(proteina_grama, value) => setForm({ ...form, proteina_grama: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.carboidrato_grama} className="input-nutritional-info"
                type="text" id="carboidrato_grama" onChangeInputValue={(carboidrato_grama, value) => setForm({ ...form, carboidrato_grama: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.lipidio_grama} className="input-nutritional-info"
                type="text" id="lipidio_grama" onChangeInputValue={(lipidio_grama, value) => setForm({ ...form, lipidio_grama: value })} />
            </div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-6 label-table">
              <small className="font-weight-bold">Distribuição por kcal</small>
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.proteina_kcal} className="input-nutritional-info"
                type="text" id="proteina_kcal" onChangeInputValue={(proteina_kcal, value) => setForm({ ...form, proteina_kcal: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.carboidrato_kcal} className="input-nutritional-info"
                type="text" id="carboidrato_kcal" onChangeInputValue={(carboidrato_kcal, value) => setForm({ ...form, carboidrato_kcal: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.lipidio_kcal} className="input-nutritional-info"
                type="text" id="lipidio_kcal" onChangeInputValue={(lipidio_kcal, value) => setForm({ ...form, lipidio_kcal: value })} />
            </div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-6 label-table">
              <small className="font-weight-bold">Distribuição por kilos</small>
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.proteina_kilos} className="input-nutritional-info"
                type="text" id="proteina_kilos" onChangeInputValue={(proteina_kilos, value) => setForm({ ...form, proteina_kilos: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.carboidrato_kilos} className="input-nutritional-info"
                type="text" id="carboidrato_kilos" onChangeInputValue={(carboidrato_kilos, value) => setForm({ ...form, carboidrato_kilos: value })} />
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.lipidio_kilos} className="input-nutritional-info"
                type="text" id="lipidio_kilos" onChangeInputValue={(lipidio_kilos, value) => setForm({ ...form, lipidio_kilos: value })} />
            </div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-6 label-table">
              <small className="font-weight-bold">Fibras</small>
            </div>
            <div className="col-2">
              <Input noLabel={true} value={form.proteina_fibras} className="input-nutritional-info"
                type="text" id="proteina_fibras" onChangeInputValue={(proteina_fibras, value) => setForm({ ...form, proteina_fibras: value })} />
            </div>
          </div>
          {/* end line */}
          {/* start line */}
          <div className="flex-row d-flex row-table">
            <div className="col-6 label-table">
              <small className="font-weight-bold">Água</small>
            </div>
            <div className="col-4">
              <Input noLabel={true} value={form.water} className="input-nutritional-info"
                type="text" id="water" onChangeInputValue={(water, value) => setForm({ ...form, water: value })} />
            </div>
          </div>
          {/* end line */}
          <div className="d-flex justify-content-end mt-5">
            <Button text={"Atualizar Recomendação"} onSubmit={() => updateRecomendation()} className="btn-submit" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export { NutritionalInfo };
