import React from 'react';
import { Link } from 'react-router-dom';


const ForgetPasswordLink = ({ text, to }) => {
  return (
    <Link to={to}>{text}</Link>
  )
}

export { ForgetPasswordLink }
