import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader, Button } from '../../atoms';
import './style.scss';

const ListOfAnswers = ({answers}) => {

  const renderAnswer = () => {
    return answers.map((answer, index) =>
      <div key={index} className="component-answer">
        <p className="question" >{answer.title} </p>
        <h5 className="answer" > {answer.answers.answer} </h5>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <CardItemContainer>
          <div className="w-100 answer-card">
            {renderAnswer()}
          </div>
        </CardItemContainer>
      </div>
    </div>
  )
}

export { ListOfAnswers }
