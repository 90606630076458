import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader, Button } from '../../atoms';
import { Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';

const ListOfTraining = ({ onSelect, currentTraining, setRefresh, refresh, setCadastrar }) => {
  const ACTION_TYPES = {
    LISTING: 'listing',
    SEARCHING: 'searching',
  }
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState(ACTION_TYPES.LISTING);
  const [training, setTrainings] = useState([])  
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchTrainings();
          break;
        default:
          getTrainings();
          break;
      }
    }
  }, [isAuthenticated, page, action])

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchTrainings();
          break;
        default:
          getTrainings();
          break;
      }
    }
  }, [refresh]);

  const getTrainings = () => {
    setIsLoading(true);
    Api.get('/api/trainings', `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setTrainings(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const searchTrainings = () => {
    setIsLoading(true);
    Api.get(`/api/trainings/search/${search}?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setAction(ACTION_TYPES.SEARCHING);
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setTrainings(response.data.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const renderTrainings = () => {
    return training.map((training, index) =>
      <tr
        className={(training.id == (currentTraining ? currentTraining.id : null)) ? "selected" : undefined}
        onClick={() => onSelect((training.id == (currentTraining ? currentTraining.id : null)) ? null : training)}
        key={"training" + training.id}
      >
        <td>{training.stage_id} Etapa - {training.title}</td>
        <td className="text-right">{training.updated_at}</td>
      </tr>
    )
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (search != '') {
      setPage(1);
      searchTrainings();
    } else {
      setAction(ACTION_TYPES.LISTING);
      setPage(1);
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-md-8">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
          <div className="col-md-4">
            <Button onSubmit={() => setCadastrar(true)} className={"btn-block btn-danger btn-purple "} text={'Cadastrar'} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="text-right">Data de Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTrainings()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>
      </div>
    </div>
  )
}

export { ListOfTraining }
