import React, { useState, useEffect } from "react";
import { Input, Button, Alert } from "../../atoms";
import { useAccessToken } from "../../../hooks";
import { Api } from "../../../services";
import "./style.scss";

const FoodForm = ({ current, setCurrent, setRefresh }) => {
    const [form, setForm] = useState({ name: '', calories: '', qtd: '', foods: [] });
    const [isBusy, setIsBusy] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

    useEffect(() => {
        setForm({ name: '', calories: '', qtd: '', foods: [], ...current });
    }, [current]);

    const create = () => {
        setAlert(null);
        setIsBusy(true);
        Api.post(`/api/food`, form, `Bearer ${access_token}`)
            .then(res => {
                setAlert({ message: res.message, type: "alert-success" });
                setForm({ name: '', calories: '', qtd: '', foods: [] });
                setCurrent(null);
                setRefresh(1);
            })
            .catch(err => {
                if (err.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else if (
                    err.errors &&
                    Array.isArray(Object.keys(err.errors)) &&
                    Object.keys(err.errors)[0]
                ) {
                    setAlert({
                        message: err.errors[Object.keys(err.errors)[0]].join(
                            " "
                        ),
                        type: "alert-danger"
                    });
                } else if (err.message) {
                    setAlert({ message: err.message, type: "alert-danger" });
                }
            })
            .finally(() => {
                setIsBusy(false);
                document
                    .getElementsByClassName("card-item-container")[0]
                    .scrollTo(0, 0);
            });
    };

    const deleteEvaluation = () => {
        setAlert(null);
        setIsBusy(true);
        Api.delete(`/api/food/${current.id}`, {}, `Bearer ${access_token}`)
            .then(res => {
                setAlert({ message: res.message, type: "alert-success" });
                setCurrent(null);
                setRefresh(1);
            })
            .catch(err => {
                if (err.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else if (
                    err.errors &&
                    Array.isArray(Object.keys(err.errors)) &&
                    Object.keys(err.errors)[0]
                ) {
                    setAlert({
                        message: err.errors[Object.keys(err.errors)[0]].join(
                            " "
                        ),
                        type: "alert-danger"
                    });
                } else if (err.message) {
                    setAlert({ message: err.message, type: "alert-danger" });
                }
            })
            .finally(() => {
                setIsBusy(false);
                document
                    .getElementsByClassName("card-item-container")[0]
                    .scrollTo(0, 0);
            });
    };

    const updateEvaluate = () => {
        setAlert(null);
        setIsBusy(true);
        Api.put(`/api/food/${current.id}`, form, `Bearer ${access_token}`)
            .then(res => {
                setAlert({ message: res.message, type: "alert-success" });
                setCurrent(null);
                setRefresh(1);
            })
            .catch(err => {
                if (err.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                } else if (
                    err.errors &&
                    Array.isArray(Object.keys(err.errors)) &&
                    Object.keys(err.errors)[0]
                ) {
                    setAlert({
                        message: err.errors[Object.keys(err.errors)[0]].join(
                            " "
                        ),
                        type: "alert-danger"
                    });
                } else if (err.message) {
                    setAlert({ message: err.message, type: "alert-danger" });
                }
            })
            .finally(() => {
                setIsBusy(false);
                document
                    .getElementsByClassName("card-item-container")[0]
                    .scrollTo(0, 0);
            });
    };

    const changeFood = (index, key, value) => {
        setForm({
            ...form,
            foods: form.foods.map((food, i) => {
                if (i === index) {
                    return { ...food, [key]: value };
                }
                return food;
            })
        });
    };

    const addFood = () => {
        setForm({
            ...form,
            foods: [...(form.foods || []), { name: '', qtd: '' }]
        });
    };

    const deleteFood = index => {
        setForm({
            ...form,
            foods: form.foods.filter((food, i) => i !== index)
        });
    };

    const { name, calories, qtd, foods } = form;

    return (
        <>
            <div className="d-flex flex-column">
                {alert && <Alert text={alert.message} className={alert.type} />}
                <div className="row">
                    <div className="col-5">
                        <Input
                            label="Grupo"
                            value={name}
                            onChangeInputValue={(name, value) =>
                                setForm({ ...form, name: value })
                            }
                        />
                    </div>
                    <div className="col-3">
                        <Input
                            label="Quantidade"
                            value={qtd}
                            onChangeInputValue={(qtd, value) =>
                                setForm({ ...form, qtd: value })
                            }
                        />
                    </div>
                    <div className="col-4">
                        <Input
                            label="Caloria"
                            value={calories}
                            type="number"
                            onChangeInputValue={(calories, value) =>
                                setForm({ ...form, calories: value })
                            }
                        />
                    </div>
                </div>
                <div className="row food-list">
                    <div className="col-12">
                        {foods &&
                            foods.map((food, i) => (
                                <div
                                    key={`food-${food.id}`}
                                    className="row food-line"
                                >
                                    <div className="col-7">
                                        <Input
                                            label="Nome"
                                            noLabel={i !== 0}
                                            value={food.name}
                                            type="text"
                                            name="name"
                                            onChangeInputValue={(key, value) =>
                                                changeFood(i, key, value)
                                            }
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Input
                                            label="Qtd"
                                            noLabel={i !== 0}
                                            value={food.qtd}
                                            type="text"
                                            name="qtd"
                                            onChangeInputValue={(key, value) =>
                                                changeFood(i, key, value)
                                            }
                                        />
                                    </div>
                                    <div className="col-1">
                                        <Button
                                            className={
                                                "btn-danger btn-red btn-outline"
                                            }
                                            onSubmit={() => deleteFood(i)}
                                            text="X"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="mt-3 ml-4">
                    <Button
                        className={"btn-primary"}
                        disabled={!name}
                        onSubmit={() => addFood()}
                        text="Adicionar Alimento de Substituição"
                    />
                </div>
                <div className="mt-3 actions mb-1 d-flex justify-content-between">
                    {current && (
                        <Button
                            className={"btn-danger btn-red btn-outline"}
                            onSubmit={() => deleteEvaluation()}
                            text="Excluir Cadastro"
                        />
                    )}
                    {current && (
                        <Button
                            className={"btn-primary btn-outline"}
                            onSubmit={() => create()}
                            text="Duplicar Cadastro"
                        />
                    )}
                    <Button
                        disabled={isBusy || !name}
                        text={current ? "Atualizar Cadastro" : "Criar Cadastro"}
                        onSubmit={() => (current ? updateEvaluate() : create())}
                        className="btn-submit"
                    />
                </div>
            </div>
        </>
    );
};

export { FoodForm };
