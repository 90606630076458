import React, { useState } from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, VideoForm, ListOfVideos } from '../organisms';

const VideosPage = () => {
  const [currentVideo, setCurrentVideo] = useState(null)

  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/config"} title={"Vídeos das telas"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ListOfVideos onSelect={setCurrentVideo} currentVideo={currentVideo} />
          </div>
          <div className="col-md-7">
            {currentVideo && <VideoForm currentVideo={currentVideo} />}
          </div>
        </div>
      </DashboardContent>
    </div>
  )
}

export { VideosPage }
