import React, { useState } from 'react';
import { Input, Button } from '../../atoms';
import { ActionsStatuses } from '../../molecules';
import { Api } from '../../../services/Api';

const ForgetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [isBuse, setIsBusy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false)

  const onChangeInputValue = (name, value) => {
    if (name == 'email') {
      setEmail(value);
      setError("");
    }
  }

  const onSubmit = () => {
    if (email != "") {
      setIsBusy(true);
      setError("");
      const body = {
        email: email
      };
      Api.post('/api/password/forgot', body)
        .then(_ => {
          setSuccess(true);
        })
        .catch(erro => {
          if (erro.message === "Email not found") {
            setError("Email não encontrado");
          } else {
            setError("Ocorreu um erro, tente novamente mais tarde");
          }
        })
        .finally(() => setIsBusy(false));
    } else {
      setError("Digite um endereço de e-mail");
    }

  }

  return (
    <div className="form-container">
      <Input onChangeInputValue={onChangeInputValue} value={email} label={"Informe seu e-mail"} name={"email"} id={"email"} type={"email"} />
      <div className="button-container">
        <Button disabled={isBuse} onSubmit={onSubmit} className={"btn-primary btn-block"} text={"ENVIAR"} />
      </div>
      <div className="mt-3">
        <ActionsStatuses sucesso={success} erro={error} />
      </div>
    </div>
  )
}

export { ForgetPasswordForm }
