import React from 'react';

const ChatProfile = ({ nome, photo_url, stage }) => {
  return (
    <div className="chat-profile d-flex">
      <div>
        {/* A imagem tem que ser dinamica */}
        <img height="100" src={photo_url} alt="Foto do cliente" />
      </div>
      <div>
        <h2>{nome}</h2>
        {/* Informações de plano e ultima consulta tbm serão dinamicas */}
        <p>{stage && `${stage}ª Etapa,`} <b>Última Consulta</b> 22/12/2019</p>
      </div>
    </div>
  );
}

export { ChatProfile };
