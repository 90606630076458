import React, { useState, useEffect } from 'react';
import { SideBar, EditClient } from '../organisms';
import { SubSideItem, Loader, NoData, DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, FoodContainer } from '../organisms';
import { CreateClienteProfile } from '../molecules';
import { useAccessToken } from '../../hooks';
import { Api } from '../../services';
import { CardItemContainer } from '../atoms';

function Foods({ match }) {
  const [tab, setTab] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [client, setClient] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    if (isAuthenticated) {
      const { userid } = match.params;
      Api.get(`/api/clients/${userid}`, `Bearer ${access_token}`)
        .then(response => {
          setClient(response.data);
        })
        .catch(erro => {
          if (erro.message == 'Unauthenticated.') {
            setIsAuthenticated(false);
          }
        })
        .finally(() => setloading(false));
    }
  }, [isAuthenticated, match]);

  return (
    <div className="dashboard-container create-client-container">
      <SideBar />
      <DashboardContent onClickItemMenu={tabIndex => setTab(tabIndex)}>
        <InternPageHeader link={"/home"} title={"Lista de Substituição"} />
        {
          loading ?
            <div className="d-flex justify-content-center"> <Loader /> </div> : (
              <div className="d-flex food-card flex-fill">
                <CardItemContainer>
                  <FoodContainer />
                </CardItemContainer>
              </div>
            )
        }
      </DashboardContent>
    </div>
  )
}

export { Foods };
