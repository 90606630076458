import React, { useState, useEffect } from 'react';
import { SideBarMenu } from '../../molecules';
import { useLocation } from "react-router-dom";
import ChatFB from '../../../services/chat';


const SideBar = () => {
  let location = useLocation();
  const [links, setLinks] = useState([
    {
      to: '/home',
      image: '/img/homeicon.svg',
      active: location.pathname.split('/')[1] != 'chat'
    },
    {
      to: '/chat',
      image: '/img/chat.svg',
      active: location.pathname.split('/')[1] == 'chat',
      badge: 0
    }
  ]);

  useEffect(() => {
    ChatFB.onSubscriptionCount((count) => {
      setLinks(links.map((link) => {
        if(link.to === '/chat') {
          return({...link, badge: count});
        }
        return link;
      }))
    })
  }, []);


  return (
    <div className="dashboard-sidebar">
      <SideBarMenu links={links}/>
    </div>
  );
}

export { SideBar };
