import React from 'react';


const DeleteButton = ({ onPress }) => {
  return (
    <div onClick={onPress} className="delete-button" >
      <img src="/img/delete.svg" alt="imagem do botão de deletar" />
    </div>
  )
};

export { DeleteButton }