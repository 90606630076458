import React, { useState } from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ListOfStages, StageForm } from '../organisms';

const EtapasPage = () => {
  const [currentStage, setCurrentStage] = useState(null)
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/config/planos"} title={"Etapas"} />
          </div>
        </div>
        <div className="row h-100">
          <div className="col-md-5">
            <ListOfStages refresh={refresh} setRefresh={setRefresh} onSelect={setCurrentStage} currentStage={currentStage}/>
          </div>
          <div className="col-md-7">
            { currentStage && <StageForm setRefresh={setRefresh} currentStage={currentStage} setCurrentStage={setCurrentStage} /> }
          </div>
        </div>
      </DashboardContent>
    </div>
  )
}

export { EtapasPage }
