import React from 'react';
import { Input, ForgetPasswordLink, Button } from '../../atoms';

const LoginFormGroup = ({ onChangeInputValue, email, password, onSubmit, error, isBusy }) => {
  return (
    <div className="form-container">
      {
        error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )
      }
      <Input onChangeInputValue={onChangeInputValue} value={email} label={"Login"} name={"email"} id={"email"} type={"email"} />
      <Input onChangeInputValue={onChangeInputValue} password={password} label={"Senha"} name={"password"} id={"password"} type={"password"} />
      <div className="button-container">
        <Button disabled={isBusy} onSubmit={onSubmit} className={"btn-primary btn-block"} text={"ENTRAR"}/>
      </div>
      <div className="forget-password-link-container text-center">
        <ForgetPasswordLink to="esqueci-minha-senha" text={"Esqueci minha senha"}/>
      </div>
    </div>
  );
}

export { LoginFormGroup };
