import React, { useState, useEffect } from 'react';
import { Paginator, SearchBar } from '../../molecules';
import { CardItemContainer, Loader } from '../../atoms';
import ChatInstance from '../../../services/chat';
import firebase from 'firebase';
import momentjs from 'moment';

const ListOfChats = ({ currentChat, onSelect, setIndex }) => {
  const firestore = firebase.firestore();
  const [chats, setChats] = useState([]);
  const [chatsFiltered, setChatsFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(false); //mudar pra true quando for fazer as requests de fato
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  });

  useEffect(() => {
    ChatInstance.onSubscription((chats) => setChats(chats));
  }, [false]);

  useEffect(() => {
    const filtered = chats.filter(chat => {
      if(search) {
        return (chat.nome || "").toUpperCase().indexOf(search.toUpperCase()) > -1;
      }
      return true;
    });
    setChatsFiltered(filtered);
  }, [chats, search]);

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (search != '') {
      setPage(1);
      searchQuestions();
    } else {
      setAction(ACTION_TYPES.LISTING);
      setPage(1);
    }
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }


  const renderChats = () => {
    return chatsFiltered.map((chat, index) =>
      <tr 
        onClick={() => {
          if(chat == currentChat) {
            onSelect(null);
            setIndex(null);
          } else {
            setIndex(index);
            onSelect(chat)
          }
        }} 
        key={chat.id} 
        className={chat == currentChat ? "selected" : undefined}
      >
        <td>{chat.nome}</td>
        <td className="text-right">{chat.data}</td>
      </tr>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-md-8">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="text-right">Data da útilma mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {renderChats()}
                </tbody>
              </table>
            }
            {/* <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} /> */}
          </div>
        </CardItemContainer>
      </div>
    </div>
  );
}

export { ListOfChats };
