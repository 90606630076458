import React from 'react';

const TextArea = ({ label, className, inputClassname, name, id, onChangeInputValue, value, disabled, placeholder, row=4 }) => {
  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
      <textarea
        className={`form-control ${inputClassname}`}
        id={id}
        onChange={(event) => onChangeInputValue(name, event.target.value)}
        name={name}
        rows={row}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

export { TextArea };
