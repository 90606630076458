import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { Input, TextArea, Button, Loader } from '../../atoms';
import { ListOfMedias } from '../ListOfMedias';
import { PlayerWithSplash, ActionsStatuses } from '../../molecules';



const DuvidaForm = ({ currentQuestion, setRefresh }) => {
  const ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  }
  const [action, setAction] = useState(ACTION_TYPES.CREATE);
  const [shouldCall, setShouldCall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [question, setQuestion] = useState({ reply: {
    media: null
  } });
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated && shouldCall) {
      switch (action) {
        case ACTION_TYPES.CREATE:
          createQuestion();
          break;
        case ACTION_TYPES.DELETE:
          deleteQuestion();
          break;
        default:
          updateQuestion();
          break;
      }
    }
  }, [isAuthenticated, shouldCall])

  useEffect(() => {
    if (currentQuestion) {
      setQuestion(currentQuestion);
    } else {
      setQuestion({ reply: {
        reply: ''
      } });
    }
  }, [currentQuestion])

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  const updateQuestion = () => {
    setLoading(true);
    Api.put(`/api/questions`, {
      id: question.id,
      question: question.question,
      reply: question.reply.reply,
      media_id: question.reply.media_id
    }, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
        setShouldCall(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setLoading(false);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          setShouldCall(false);
        }
      })
  }

  const createQuestion = () => {
    setLoading(true);
    Api.post(`/api/questions`, {
      question: question.question,
      reply: question.reply.reply,
      media_id: question.reply.media_id
    }, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
        setShouldCall(false);
        setQuestion({ reply: {
          reply: ''
        } });
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setLoading(false);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          setShouldCall(false);
        }
      })
  }

  const onSubmit = () => {
    if (currentQuestion) {
      setAction(ACTION_TYPES.UPDATE);
      setShouldCall(true);
    } else {
      setAction(ACTION_TYPES.CREATE);
      setShouldCall(true);
    }
  }

  const onDelete = () => {
    setAction(ACTION_TYPES.DELETE);
    setShouldCall(true);
  }

  const deleteQuestion = () => {
    setLoading(true);
    Api.delete(`/api/questions/${currentQuestion.id}`, {}, `Bearer ${access_token}`)
      .then(_ => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
        setShouldCall(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setLoading(false);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          setShouldCall(false);
        }
      })
  }

  const changeVideo = (media) => {
    let question_aux = { ...question };
    question_aux.reply.media = media ? media : null;
    question_aux.reply.media_id = media ? media.id : null;
    setQuestion(question_aux);
  }

  const onChangeInputValue = (name, value) => {
    let question_aux = { ...question };
    if (name == "question") {
      question_aux.question = value;
    } else if (name == "reply") {
      question_aux.reply.reply = value;
    } else {
      question_aux.media_id = value
    }
    setQuestion(question_aux);
  }


  return (
    <div className="row">
      <div className="col-12">
        <Input
          id={"question"}
          name={"question"}
          onChangeInputValue={onChangeInputValue}
          label={question.id ? "Quer atualizar a <b>dúvida</b>?" : "Qual <b>dúvida</b> você quer cadastrar?"}
          value={question.question ? question.question : ""}
        />
        <TextArea
          label={question.id ? "Quer atualizar a <b>descrição</b>?" : "Aqui você <b>descreverá</b> o vídeo em <b>xxx caracteres.</b>"}
          value={question.reply ? question.reply.reply : ""}
          onChangeInputValue={onChangeInputValue}
          id={"reply"}
          name={"reply"}
        />
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-8">
            <ListOfMedias select={true} currentMedia={question.reply.media} type={"select"} onSelect={changeVideo} />
          </div>
          <div className={`col-4 d-flex flex-column ${question.reply.media ? "justify-content-between" : "justify-content-end"}`}>
            <PlayerWithSplash media={question.reply.media} />
            <div className="w-100 mt-5">
              <Button disabled={loading} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={currentQuestion ? "Atualizar Dúvida" : "Cadastrar Dúvida"} />
              <Button disabled={loading || !currentQuestion} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Dúvida"} />
            </div>
          </div>
        </div>
        {loading &&
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader />
            </div>
          </div>
        }
        <div className="row w-100 m-0">
          <div className="col-12">
            <ActionsStatuses sucesso={sucesso} erro={erro} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { DuvidaForm };
