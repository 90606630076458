import React, { useState, useEffect } from 'react';
import { Input, Button, TextArea } from '../../atoms';
import './style.scss';

const ReplacementForm = ({ currentGroup }) => {
  const [group, setGroup] = useState({})

  useEffect(() => {
    setGroup(currentGroup)
  }, [currentGroup])

  const onSubmit = () => {
    console.log("Form Data", group)
  }

  const onDelete = () => {
    console.log("Delete Group", group)

  }

  return (
    <div className="d-flex h-100 flex-column  justify-content-between w-100" >
      <div className="col-12 d-flex flex-column mbneg-5">
        <div className="row justify-content-between" >
          <div className="col-6" >
            <Input
              id={"questionnaire"}
              name={"questionnaire"}
              onChangeInputValue={(name, text) => setGroup((prev) => ({ ...prev, name: text }))}
              label={'Grupo'}
              value={group.name}
            />
          </div>
          <div className="col-6" >
            <Input
              id={"questionnaire"}
              name={"questionnaire"}
              onChangeInputValue={(name, text) => setGroup((prev) => ({ ...prev, kcal: text }))}
              label={'Caloria'}
              value={group.kcal}
            />
          </div>
        </div>
        <div className="row d-flex flex-fill" >
          <div className="d-flex col-12 " >
            <TextArea
              label='Alimentos'
              onChangeInputValue={(name, text) => setGroup((prev) => ({ ...prev, foods: text }))}
              value={group.foods}
              className="d-flex flex-fill mb-5 flex-column "
              inputClassname="d-flex flex-fill"
              placeholder='Separe os alimentos por ;'
            />
          </div>
        </div>

      </div>
      <div className="w-100 row m-0 justify-content-end">
        <div className="mr-0 col-3" >
          <Button disabled={false} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline "} text={'Deletar'} />
        </div>
        <div className="ml-0 col-4" >
          <Button disabled={false} onSubmit={onSubmit} className={"btn-block btn-submit  "} text={"Atualizar Cadastro"} />
        </div>
      </div>
    </div>
  )
};

export { ReplacementForm }