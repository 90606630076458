import React from 'react';
import { CardItem } from '../../molecules';

const PlanoOptions = () => {
  const options = [
    {
      link: '/planos',
      content: {
        image: '/img/planos.svg',
        title: 'Cadastro de planos',
        text: 'Cadastre e gerencie planos.'
      }
    },
    {
      link: '/etapas',
      content: {
        image: '/img/planos.svg',
        title: 'Cadastro de etapas',
        text: 'Cadastre e gerencie etapas.'
      }
    },

  ];

  const renderOtions = () => {
    return options.map(option =>
      <div key={option.link} className="col-md-4">
        <CardItem spotlight={option.spotlight} link={option.link} content={option.content}/>
      </div>
      )
  }

  return (
    <div className="row">
      {renderOtions()}
    </div>
  );
}

export { PlanoOptions };
