import React, { useState } from 'react';
import { VideoInput } from '../../molecules';
import { Input, UploadFileButton } from '../../atoms';
import { useAccessToken } from '../../../hooks';
import { Player } from 'video-react';


const VideoUpload = ({ onSuccess, onErro }) => {
  const [wantUpload, setWantUpload] = useState(false);
  const [video, setVideo] = useState("");
  const [name, setName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);


  const onChangeInputValue = (name, value) => {
    if (name == 'video') {
      setVideo(value);
    } else {
      value = value.replace(/[^a-zA-Z0-9 _]/g, "");
      value = value.replace(" ", "_");
      value = value.toLowerCase();
      setName(value);
    }
  }

  return (
    wantUpload ?
      <div>
        <Input
          label={"Insira o <b>nome do vídeo</b>:"}
          type={"text"}
          name={"nome"}
          id={"nome-video"}
          onChangeInputValue={onChangeInputValue}
          value={name}
          small={"Note que espaços e caracteres especiais não são permitidos"}
        />
        <VideoInput
          name={name}
          resourceURL={"/api/videos/video"}
          onSuccess={onSuccess}
          onErro={onErro}
          videoChanged={onChangeInputValue}
          authToken={`Bearer ${access_token}`}
        />
        <div className="mt-5">
          {video &&
            <Player
              src={video}
              fluid={false}
              height={300}
            />}
        </div>
      </div>
      :
      <UploadFileButton onClick={() => setWantUpload(true)} text={"Clique para enviar um vídeo"}/>
  );
}

export { VideoUpload };
