import React from 'react';
import { TextArea, Button } from '../../atoms';

const ChatForm = ({ onSubmit, onChangeInputValue, value }) => {
  return (
    <div className="w-100 text-right">
      <TextArea
        name={"message"}
        id={"message"}
        onChangeInputValue={onChangeInputValue}
        value={value}
        placeholder={"Digite sua mensagem e ela aparecerá no chat do seu paciente."}
      />
      <Button text={"Enviar mensagem"} onSubmit={onSubmit} className="btn-submit"/>
    </div>
  );
}

export { ChatForm };
