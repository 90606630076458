import React, { useState } from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent, CardItemContainer, Button } from '../atoms';
import { MediaForm, ListOfMedias, InternPageHeader } from '../organisms';

const MediasPage = () => {
  const [ currentMedia, setCurrentMedia ] = useState(null);
  const [refresh, setRefresh] = useState(false);
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/home"} title={"Vídeos"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ListOfMedias refresh={refresh} setRefresh={setRefresh} onSelect={setCurrentMedia} currentMedia={currentMedia} />
          </div>
          <div className="col-md-7">
            <MediaForm setRefresh={setRefresh} currentMedia={currentMedia} />
          </div>
        </div>
      </DashboardContent>
    </div>
  );
}

export { MediasPage };
