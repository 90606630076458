import React, { useState, useEffect } from 'react';
import { Button, Alert } from '../../../atoms';
import { PdfSession } from '../../../molecules';
import { useAccessToken } from '../../../../hooks';
import { Api } from '../../../../services';

const PDFTab = ({ client, updateClient }) => {

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [form, setForm] = useState({});
  const [links, setLinks] = useState({});
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if(client) {
      Api.get(`/api/clients/${client.id}/arquivospdf`, `Bearer ${access_token}`)
        .then((res) => {
          setLinks({ ...res.data });
          console.log('res', res);
        });
    }
  }, [client]);

  const onChangeFile = (key, value) => {
    setForm({ ...form, [key]: value });
  }

  const update = () => {
    setAlert(null);
    setIsBusy(true);
    const data = getFormData(form);
    Api.post(`/api/clients/${client.id}/arquivospdf`, data, `Bearer ${access_token}`)
      .then((res) => {
        updateClient({...client});
        setAlert({ message: res.message, type: 'alert-success' });
      })
      .catch((err) => {
        setAlert({ message: res.message, type: 'alert-danger' });
      })
      .finally(() => setIsBusy(false));

  }

  const getFormData = (formParams) => {
    let formData = new FormData();
    Object.keys(formParams).forEach((key) => {
      if (formParams[key]) {
        formData.append(key, formParams[key]);
      }
    });
    return formData;
  }

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
      </div>
      <div className="row">
        <div className="col-12 mt-1">
          <PdfSession id="rotina-alimentar" name="rotina_alimentar" links={links['rotina_alimentar']} onChangeInputValue={onChangeFile} uploading={false} title="Rotina Alimentar" icon="/img/rotina.svg" />
        </div>
        <div className="col-12 mt-1">
          <PdfSession id="suplementacao" name="suplementacao" links={links['suplementacao']} onChangeInputValue={onChangeFile} uploading={false} title="Suplementação" icon="/img/suplementacao_solid.svg" />
        </div>
        <div className="col-12 mt-1">
          <PdfSession id="contrato" name="contrato" links={links['contrato']} onChangeInputValue={onChangeFile} uploading={false} title="Contrato" icon="/img/rotina.svg" />
        </div>
      </div>
      <div className="actions">
        <Button disabled={isBusy} text="Atualizar" onSubmit={update} className="btn-submit" />
      </div>
    </>
  );
}

export { PDFTab };