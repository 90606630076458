import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader, Button } from '../../atoms';
import { PlanTableLine, Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';


const ListOfPlans = ({ onSelect, currentPlan, setRefresh, refresh }) => {
  const ACTION_TYPES = {
    LISTING: 'listing',
    SEARCHING: 'searching',
  }
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [action, setAction] = useState(ACTION_TYPES.LISTING);
  const [plans, setPlans] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);


  useEffect(() => {
    if (isAuthenticated) {
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchPlans();
          break;
        default:
          getPlans();
          break;
      }
    }
  }, [isAuthenticated, page, action]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      switch (action) {
        case ACTION_TYPES.SEARCHING:
          searchPlans();
          break;
        default:
          getPlans();
          break;
      }
    }
  }, [refresh]);

  const getPlans = () => {
    setIsLoading(true);
    Api.get('/api/plans', `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setPlans(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const searchPlans = () => {
    setIsLoading(true);
    Api.get(`/api/plans/search/${search}?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setAction(ACTION_TYPES.SEARCHING);
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setPlans(response.data.data);
        setIsLoading(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (search != '') {
      setPage(1);
      searchPlans();
    } else {
      setAction(ACTION_TYPES.LISTING);
      setPage(1);
    }
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const renderPlans = () => {
    return plans.map(plan =>
      <PlanTableLine
        onClickPlan={() => onSelect((plan == currentPlan) ? null : plan)}
        key={plan.id}
        plan={plan}
        selected={plan == currentPlan}
      />
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3 align-items-center d-flex">
          <div className="col-md-8">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
          <div className="col-md-4">
            <Button onSubmit={() => onSelect(null)} className={"btn-block btn-primary"} text={"Novo Plano"} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Plano</th>
                    <th className="text-right">Data de Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {renderPlans()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>
      </div>
    </div>
  );
}

export { ListOfPlans };
