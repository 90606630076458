import React, { useState } from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ListOfQuestions, DuvidaForm } from '../organisms';

function DuvidasPage() {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/config"} title={"Dúvidas"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ListOfQuestions refresh={refresh} setRefresh={setRefresh} onSelect={setCurrentQuestion} currentQuestion={currentQuestion} />
          </div>
          <div className="col-md-7">
            <DuvidaForm setRefresh={setRefresh} currentQuestion={currentQuestion} />
          </div>
        </div>
      </DashboardContent>
    </div>
  )
}

export { DuvidasPage };
