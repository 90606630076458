import React, { useState, useEffect } from "react";
import { SelectInput, Status, Statistic } from "../../atoms";
import { useAccessToken } from "../../../hooks";
import { SearchBar } from "../../molecules";
import { Api } from "../../../services";

const RefeicoesModal = ({ client }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [days, setDays] = useState([]);
    const [meals, setMeals] = useState([]);
    const [report, setReport] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            getDays();
        }
    }, [client]);

    useEffect(() => {
        getMealsReply();
    }, [selectedDay]);

    const getMealsReply = () => {
        if (selectedDay) {
            Api.get(
                `/api/clients/${client.id}/replies/day/${selectedDay.id}?search=${search}`,
                `Bearer ${access_token}`
            )
                .then((response) => {
                    console.log(response);
                    setMeals(response.data.meals);
                    setReport(response.data.report);
                })
                .catch((erro) => {
                    if (erro.message == "Unauthenticated.") {
                        setIsAuthenticated(false);
                    }
                });
        }
    };

    const getDays = () => {
        Api.get(
            `/api/clients/${client.id}/foodstrategy/days`,
            `Bearer ${access_token}`
        )
            .then((response) => {
                setDays(response.data);
                setSelectedDay(response.data[0]);
            })
            .catch((erro) => {
                if (erro.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                }
            });
    };

    const onSubmitSearch = (e) => {
        e.preventDefault();
        getMealsReply();
    };

    const onChangeInputValue = (_, value) => {
        setSearch(value);
    };

    const renderStatus = (status) => {
        switch (status) {
            case "done":
                return <Status className={"verde"} status={"Concluido"} />;
            case "pending":
                return <Status className={"roxo"} status={"Em Andamento"} />;
            case "skipped":
                return <Status className={"vermelho"} status={"Pulou"} />;
            default:
                return <Status className={"cinza"} status={"Refeição Extra"} />;
        }
    };

    const renderMealsReply = () => {
        if (meals) {
            return meals.map((meal, key) => (
                <tr key={key}>
                    <td>{meal.name ? meal.name : "Refeição Extra"}</td>
                    <td>Dia {selectedDay.day_number}</td>
                    <td>{renderStatus(meal.status)}</td>
                    <td>
                        {meal.expectedTime
                            ? meal.expectedTime
                            : meal.time
                            ? meal.time
                            : " - "}
                    </td>
                    <td>{meal.doneTime ? meal.doneTime : "-"}</td>
                </tr>
            ));
        }
    };

    return (
        <div className="px-4 mb-4">
            <div className="row">
                <div className="col-8">
                    <div className="row">
                        <div className="col-12 d-flex align-items-end gap-1">
                            <SelectInput
                                id="select-dia"
                                name="Dia"
                                value={"id"}
                                label="day_number"
                                options={days}
                                selected={
                                    selectedDay ? selectedDay.id : "Selecione"
                                }
                                onSelect={(e) =>
                                    setSelectedDay(
                                        days.find(
                                            (day) => e.target.value == day.id
                                        )
                                    )
                                }
                            />
                            <div className="mb-3 w-100">
                                <SearchBar
                                    onSubmit={onSubmitSearch}
                                    onChangeInputValue={onChangeInputValue}
                                    isGray={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-gray">
                                <thead>
                                    <tr>
                                        <th>Refeição</th>
                                        <th>Dia</th>
                                        <th>Status</th>
                                        <th>Sugerido</th>
                                        <th>Efetuado</th>
                                    </tr>
                                </thead>
                                <tbody className="scroll">
                                    {renderMealsReply()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <Statistic
                        percentage={
                            report
                                ? report.strategy_days_progress.toFixed(2) + "%"
                                : ""
                        }
                        text={"Duração da Estratégia"}
                        color={"roxo"}
                    />
                    <Statistic
                        percentage={
                            report
                                ? report.strategy_meals_progress.toFixed(2) +
                                  "%"
                                : ""
                        }
                        text={"Refeições Concluídas"}
                        color={"verde"}
                    />
                    <Statistic
                        percentage={
                            report
                                ? report.strategy_skipped_progress.toFixed(2) +
                                  "%"
                                : ""
                        }
                        text={"Refeições Puladas"}
                        color={"vermelho"}
                    />
                    <Statistic
                        percentage={
                            report ? report.extra_meals_count.toFixed(2) : ""
                        }
                        text={"Refeições Extras"}
                        color={"amarelo"}
                    />
                </div>
            </div>
        </div>
    );
};

export { RefeicoesModal };
