import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader } from '../../atoms';
import { Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';
import './style.scss';

const ListOfFood = ({ onSelect, selected, setRefresh, refresh }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [foods, setFoods] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      getFoodList();
    }
  }, [isAuthenticated, page]);

  useEffect(() => {
    if(refresh === 1) {
      setRefresh(0);
      getFoodList();
    }
  }, [refresh]);

  const getFoodList = () => {
    setIsLoading(true);

    Api.get(`/api/food?search=${search}&page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setFoods(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getFoodList();
    setPage(1);
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const renderFoods = () => {
    return foods.map(food =>
      <tr
        onClick={() => onSelect((food == selected) ? null : food)}
        className={selected && (food.id == selected.id) ? "selected" : undefined}
        key={food.id}
      >
        <td>{food.name} {food.qtd}</td>
        <td className="text-right">{food.updated_at}</td>
      </tr>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column list-food">
      <div className="row mb-3 d-flex">
        <div className="col-12 search-food">
          <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
        </div>
      </div>
      <CardItemContainer>
        <div className="w-100 h-100 d-flex flex-fill flex-column">
          {isLoading ?
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader />
              </div>
            </div>
            :
            <div className="table-component">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-right">Última Atualização</th>
                </tr>
              </thead>
              <tbody>
                {renderFoods()}
              </tbody>
            </table>
            </div>
          }
          <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
        </div>
      </CardItemContainer>
    </div>
  );
}

export { ListOfFood }
