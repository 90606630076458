import React from 'react';
import { Input } from '../../atoms';

const ImageInput = ({ file, value, onChangeInputValue, label, name }) => {
  return (
    <div className="image-input">
      <Input
        onChangeInputValue={onChangeInputValue}
        label={label}
        value={value}
        name={name} 
        id={name} 
        type={"file"}
        accept={"image/png, image/jpeg"}
        />
      {file?
        <img src={file}/>
        :null
      }
    </div>
  );
}

export { ImageInput };
