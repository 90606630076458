import { Api } from "../services";
import { useState, useEffect } from "react";

export const useFood = (access_token, isAuth) => {
    const [food, setFood] = useState([]);

    useEffect(() => {
        if (isAuth) {
            Api.get('/api/food/list', `Bearer ${access_token}`).then(payload => {
                setFood(payload.data);
            })
        }
    }, []);

    return food;
}