import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader } from '../../atoms';
import { Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';
import './style.scss';

const ListOfAvaliacaoFisica = ({ onSelect, currentEvaluation, client }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [evaluations, setEvalueations] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      getEvaluarions();
    }
  }, [isAuthenticated, page, client])

  const getEvaluarions = () => {
    setIsLoading(true);
    Api.get(`/api/clients/${client.id}/physicalevaluations?search=${search}&page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        console.log(response)
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setEvalueations(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getEvaluarions();
    setPage(1);
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const renderEvaluations = () => {
    return evaluations.map(evaluation =>
      <tr
        onClick={() => onSelect((evaluation == currentEvaluation) ? null : evaluation)}
        className={currentEvaluation && (evaluation.id == currentEvaluation.id) ? "selected" : undefined}
        key={evaluation.id}
      >
        <td>{evaluation.name}</td>
        <td className="text-right">{evaluation.updated_at}</td>
      </tr>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-3">
          <div className="col-12 search-exames-bioquimicos">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="text-right">Última Atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {renderEvaluations()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>
      </div>
    </div>
  );
}

export { ListOfAvaliacaoFisica }
