import React, { useState, useEffect } from 'react';
import { useAccessToken } from '../../../hooks';
import { Loader, DeleteButton } from '../../atoms';
import { Api } from '../../../services';

function ListOfQuestionnaires({ currentQuestionnaire, onSelect, refresh, setRefresh }) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [questionnaires, setQuestionnaires] = useState([])

  useEffect(() => {
    if (isAuthenticated) {
      getQuestionnaires();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      getQuestionnaires();
    }
  }, [refresh]);

  const getQuestionnaires = () => {
    setIsLoading(true);
    Api.get(`/api/quiz?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setQuestionnaires(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const renderQuestionnaires = () => {
    return questionnaires.map((questionnaire, index) =>
      <tr
        className={(questionnaire.id == (currentQuestionnaire ? currentQuestionnaire.id : null)) ? "selected" : undefined}
        onClick={() => onSelect((questionnaire.id == (currentQuestionnaire ? currentQuestionnaire.id : null)) ? null : questionnaire)}
        key={`questionnaire-${index}`}
      >
        <td>{questionnaire.name}</td>
        <td className="text-right">{questionnaire.updated_at}</td>
      </tr>
    )
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  return (
    <div className="w-100 p-3 borderRadius-5 backgroundGray"  >
      {false ?
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader />
          </div>
        </div>
        :
        <table className="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th className="text-right">Data</th>
              {/* <th className="text-right">Excluir</th> */}
            </tr>
          </thead>
          <tbody>
            {renderQuestionnaires()}
          </tbody>
        </table>
      }

    </div>

  )
}

export { ListOfQuestionnaires };
