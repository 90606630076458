import React, { useState } from 'react';
import { CardItemContainer } from '../../atoms';
import {
  TabCadastro,
  ExamesBioquimicos,
  AvaliacaoFisica,
  AvaliacaoEmocional,
  DiagnosticoNutricional,
  PatientJourney,
  EstrategiaAlimentar,
  PDFTab,
  Meta,
  AvisoAgua
} from '../../organisms';

const EditClient = ({ tab, token, client, updateClient }) => {
  const [form, setForm] = useState({});

  function getFormTab(formTab) {
    setForm({ ...form, ...formTab });//MESCLAR FORMS
  }

  return (
    <CardItemContainer>
      <div className="create-client-form h-100 d-flex">
        {tab == 0 &&
          <div className="w-100">
            <>
              <TabCadastro updateClient={updateClient} client={client} getFormData={form => getFormTab(form)} />
            </>
          </div>
        }
        {tab == 1 &&
          <div className="w-100">
            <Meta updateClient={updateClient} client={client} token={token} />
          </div>
        }
        {tab == 2 &&
          <div className="w-100">
            <EstrategiaAlimentar updateClient={updateClient} client={client} getFormData={form => getFormTab(form)} token={token} />
          </div>
        }
        {tab == 3 &&
          <div className="w-100">
            <ExamesBioquimicos updateClient={updateClient} client={client} getFormData={form => getFormTab(form)} token={token} />
          </div>
        }
        {tab == 4 &&
          <div className="w-100">
            <AvaliacaoFisica updateClient={updateClient} client={client} token={token} />
          </div>
        }
        {tab == 5 &&
          <div className="w-100">
            <AvaliacaoEmocional updateClient={updateClient} client={client} token={token} />
          </div>
        }
        {tab == 6 &&
          <div className="w-100">
            <DiagnosticoNutricional updateClient={updateClient} client={client} token={token} />
          </div>
        }
        {tab == 7 &&
          <div className="w-100">
            <PDFTab updateClient={updateClient} client={client} />
          </div>
        }
        {tab == 8 &&
          <div className="w-100">
            <PatientJourney updateClient={updateClient} client={client} token={token} />
          </div>
        }
        {tab == 9 &&
          <div className="w-100">
            <AvisoAgua updateClient={updateClient} client={client} token={token} />
          </div>
        }
      </div>
    </CardItemContainer>
  );
}

export { EditClient };
