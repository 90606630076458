import React, { useState, useEffect } from "react";
import { Input, Button, DeleteButton, Alert } from "../../atoms";
import { Api } from "../../../services";
import { Modal } from "..";
import { useAccessToken } from "../../../hooks";
import "./style.scss";

const TrueIntityModal = ({ client, updateClient }) => {
    const [openTrueIntity, setOpenTrueIntity] = useState(false);
    const [trueIntities, setTrueIntities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [alert, setAlert] = useState(null);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

    useEffect(() => {
        if (client) {
            setTrueIntities([...client.true_identities]);
            setAlert(null);
        }
    }, [client]);

    useEffect(() => {
      if (alert) {
        setTimeout(() => {
          setAlert(null)
        }, [2000])
      }
    }, [alert])

    const addTrueIntity = () => {
        setTrueIntities([...trueIntities, { description: "" }]);
    };

    const rmTrueIntity = (index) => {
        setTrueIntities(trueIntities.filter((t, i) => i !== index));
    };

    const updateTrueIntity = (index, value) => {
        setTrueIntities(
            trueIntities.map((t, i) =>
                i === index ? { ...t, description: value } : t
            )
        );
    };

    const saveTrueIntity = () => {
        setAlert(null);
        setLoading(true);
        Api.post(
            `/api/clients/${client.id}/trueIntity`,
            { trueIntities: trueIntities.filter((t) => !!t.description) },
            `Bearer ${access_token}`
        )
            .then((res) => {
              setAlert({ message: "Alterado com sucesso", type: "alert-success" });
            })
            .catch((err) => {
              if (err.message == "Unauthenticated.") {
                setIsAuthenticated(false);
              } else if (
                  err.errors &&
                  Array.isArray(Object.keys(err.errors)) &&
                  Object.keys(err.errors)[0]
              ) {
                  setAlert({
                      message:
                          err.errors[Object.keys(err.errors)[0]].join(" "),
                      type: "alert-danger",
                  });
              } else if (err.message) {
                  setAlert({ message: err.message, type: "alert-danger" });
              }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="mx-1">
            <div className="d-flex">
                <Button
                    className="btn btn-light-primary"
                    onSubmit={() => setOpenTrueIntity(true)}
                    text="Verdadeira Identidade"
                />
            </div>
            <Modal
                closeModal={() => setOpenTrueIntity(false)}
                isOpen={openTrueIntity}
                id={"modal-verdadeira-identidade"}
            >
                <div className="pl-4 pr-4">
                    {alert && (
                        <Alert text={alert.message} className={alert.type} />
                    )}
                    <div class="d-flex justify-content-between">
                        <h3 className="font-weight-bold">
                            Verdadeira Identidade
                        </h3>
                    </div>
                    <div>
                        {trueIntities.map((intity, index) => (
                            <div
                                key={`alternative-${intity.id}`}
                                className="d-flex row justify-content-between alternative"
                            >
                                <div className="col-11">
                                    <Input
                                        noLabel
                                        id={`index-true-intity-${intity.id}`}
                                        name={`index-true-intity-${intity.id}`}
                                        onChangeInputValue={(input, text) =>
                                            updateTrueIntity(index, text)
                                        }
                                        value={intity.description}
                                    />
                                </div>
                                <div className="col-1">
                                    <DeleteButton
                                        onPress={() => rmTrueIntity(index)}
                                        className={
                                            "btn-block btn-danger btn-purple"
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                        <Button
                            text={"Nova Identidade"}
                            onSubmit={() => addTrueIntity()}
                            className="btn-submit"
                        />
                        <Button
                            text="Salvar"
                            onSubmit={() => saveTrueIntity()}
                            disabled={loading}
                            className="btn-submit"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export { TrueIntityModal };
