import React from 'react';
import { SideBarLink } from '../../atoms';

const SideBarMenu = ({ links }) => {
  const renderMenu = () => {
    return links.map(link => 
      <SideBarLink key={link.to} to={link.to} active={link.active} image={link.image} badge={link.badge} />
    )
  }

  return (
    <div className="sidebar-menu">
      {renderMenu()}
      <div className="spacer"></div>
      <SideBarLink to={"/"} image={"/img/user.svg"} />
    </div>
  );
}

export { SideBarMenu };
