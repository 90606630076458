import React, { useState, useEffect } from 'react';
import { Input, Button, Alert } from '../../../atoms';
import { ListOfAvaliacaoFisica } from '../../../organisms';
import { AvaliacaoFisicaForm } from '../../../molecules';
import { useAccessToken } from '../../../../hooks';

const AvaliacaoFisica = ({ token, client, updateClient }) => {

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
      </div>
      <Button text="Cadastrar Novo" className="btn-primary" onSubmit={() => setCurrentEvaluation(null)} />
      <div className="row mt-3">
          <div className="col-md-5">
            <ListOfAvaliacaoFisica client={client} onSelect={setCurrentEvaluation} currentEvaluation={currentEvaluation} />
          </div>
          <div className="col-md-7">
            <AvaliacaoFisicaForm evaluation={currentEvaluation} token={token} client={client} updateClient={updateClient} setCurrentEvaluation={setCurrentEvaluation} />
          </div>
        </div>
    </>
  );
}

export { AvaliacaoFisica };