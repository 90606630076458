import React from 'react';

const CardItemIcon = ({image}) => {
  return (
    <div className="card-icon-container">
      <img className="card-icon-image" src={image}/>
    </div>
  );
}

export { CardItemIcon };
