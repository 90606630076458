import React, { useState, useEffect } from 'react';
import { ChatProfile, ChatMessageList, ChatForm } from '../../molecules';
import firebase from 'firebase';
import ChatService from '../../../services/chat';

var setUnSubscribe = null;
var setUnSubscribeLocally = null;

const Chat = ({ currentChat, index }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const firestore = firebase.firestore();
  const [loading, setLoading] = useState(false);
  const [countMessages, setCountMessages] = useState(0);

  useEffect(() => {
    if(setUnSubscribeLocally) {
      setUnSubscribeLocally();
    }
    if(index != null) {
      setUnSubscribeLocally = ChatService.onSubscription((chats) => {
        setCountMessages(chats[index].unread_messages || 0);
      })
    }
  }, [index]);

  useEffect(() => {
    setLoading(true);
    if (setUnSubscribe) {
      setUnSubscribe();
    }
    setWasRead()
    const subscription = firestore.collection('users')
      .doc(currentChat.id)
      .collection('messages')
      .orderBy('created_at', 'asc')
      .onSnapshot((doc) => {
        setMessages(doc.docs.map((messages) => {
          return messages.data();
        }));
        setLoading(false);
        scrollToBottom();
      });
    setUnSubscribe = subscription;
    return subscription;
  }, [currentChat]);

  useEffect(() => {
    return () => {
      if (setUnSubscribe) {
        setUnSubscribe();
      }
    }
  }, []);

  const setWasRead = async () => {
    if(!currentChat.was_read_adm) {
      const chatRef = await getChatByUserId(currentChat.id);
      chatRef.update({
        was_read_adm: true
      });
    }
  }

  const scrollToBottom = () => {
    const objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const getChatByUserId = async (userId) => {
    const userIdToString = `${userId}`;
    return firestore.collection('users').doc(userIdToString);
  }

  const onSubmit = async () => {
    try {
      const newDate = new Date();
      const chatRef = await getChatByUserId(currentChat.id);
      chatRef.update({
        last_message: newDate,
        unread_messages: (countMessages + 1),
        was_read_adm: true
      });
      await chatRef.collection('messages').add({
        created_at: newDate,
        send_by_user: false,
        text: message
      });
      setMessage("");
    } catch (error) {
      console.log('ERROR CREATE MESSAGE', error)
    }
  }

  const onChangeInputValue = (name, value) => {
    setMessage(value);
  }

  return (
    <div className="chat">
      <ChatProfile nome={currentChat.nome} photo_url={currentChat.photo_url} stage={currentChat.stage} />
      <ChatMessageList loading={loading} messages={messages} />
      <ChatForm onChangeInputValue={onChangeInputValue} value={message} onSubmit={onSubmit} />
    </div>
  );
}

export { Chat };
