import React from 'react';

const ClientStatus = ({ status }) => {
  const clientStatus = {
    aguardando_agendamento: 'Aguardando Agendamento',
    questionario_respondido: 'Questionário Respondido',
    questionario_retorno: 'Questionário de Retorno',
    estrategia_andamento: 'Estrategia em Andamento',
    sem_estrategia: 'Sem Estratégia'
  }

  return (
    <div className={'status ' + status}>
      {clientStatus[status]}
    </div>
  );
}

export { ClientStatus };
