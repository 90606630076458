import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader } from '../../atoms';
import { Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';
import './style.scss';

/**
 * 
 * @param {array<object>} estrategias 
 * @param {getter} selectedStrat //estrategia selecionada
 * @param {setter} setSelectedStrat//seta estrategia selecionada (edit)
 */
const ListOfRefeicao = ({ onSelect, client, selectedStrat, mealDaySelected, refresh, setRefresh }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [meals, setMeals] = useState([])
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  })
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (refresh) {
      getDays();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (isAuthenticated && selectedStrat) {
      getDays();
    }
  }, [isAuthenticated, page, client, selectedStrat]);

  useEffect(() => {
    setPage(1);
  }, [selectedStrat]);

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const getDays = () => {
    if (selectedStrat) {
      setIsLoading(true);
      Api.get(`/api/clients/${client.id}/foodstrategy/${selectedStrat.id}/days?search=${search}&page=${page}`, `Bearer ${access_token}`)
        .then(response => {
          setPagination({
            lastPage: response.last_page,
            to: response.to,
            total: response.total
          });
          setIsLoading(false);
          setMeals(response.data)
        })
        .catch(erro => {
          if (erro.message == 'Unauthenticated.') {
            setIsAuthenticated(false);
          }
        })
    }
  }

  const excluirMealsDay = (mealSelected) => {
    setPage(1);
    Api.delete(`/api/clients/${client.id}/foodstrategy/${selectedStrat.id}/day/${mealSelected.id}/meal/${mealSelected.meal_id}`, {}, `Bearer ${access_token}`)
      .then(res => {
        getDays();
        onSelect(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      })
  }

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getDays();
    setPage(1);
    onSelect(null);
  }

  const onChangeInputValue = (_, value) => {
    setSearch(value);
  }

  const renderMealsDays = () => {
    return meals.map((meal) =>
      <tr
        className={mealDaySelected && meal.id === mealDaySelected.id && mealDaySelected.meal_id === meal.meal_id ? "tr-selected" : ""}
        onClick={() => onSelect(meal)}
        key={`${meal.id}-${meal.meal_id}`}
      >
        <td>{meal.name}</td>
        <td>{meal.time}</td>
        <td>{meal.day_number}</td>
        <td className="text-right" style={{ width: '30px' }}>
          <div className="delete-button" onClick={e => {
            e.stopPropagation();
            excluirMealsDay(meal);
          }}>
            <img className="delete" src="/img/delete.svg" />
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className="row">
      <div className="col-12 list-refeicao">
        <div className="row mb-3">
          <div className="col-12">
            <SearchBar onSubmit={onSubmitSearch} onChangeInputValue={onChangeInputValue} />
          </div>
        </div>
        <CardItemContainer>
          <div className="w-100">
            {isLoading ?
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
              :
              <table className="table">
                <thead>
                  <tr>
                    <th>Refeição</th>
                    <th>Horário</th>
                    <th>Dia</th>
                    <th className="text-right">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {renderMealsDays()}
                </tbody>
              </table>
            }
            <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
          </div>
        </CardItemContainer>

      </div>
    </div>
  );
}

export { ListOfRefeicao }
