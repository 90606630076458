import React from 'react';
import { SearchInput, SearchButton } from '../../atoms';

const SearchBar = ({ onSubmit, onChangeInputValue, placeholder, isGray, select }) => {
  return (
    <div className="w-100">
      <div className={`search ${select ? "select" : undefined}`}>
        <SearchInput
          placeholder={placeholder}
          onChangeInputValue={onChangeInputValue}
          name="search-input"
          id="search-input"
          inputClassName={isGray && "search-gray"}
          enterPress={onSubmit}
        />
        <SearchButton
          buttonClassName={isGray && "search-gray"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}

export { SearchBar };
