import React, { useState, useEffect } from "react";
import { SelectInput, Button } from "../../atoms";
import { RefeicoesModal } from "../../organisms";
import { Modal } from "../../molecules";
import "./style.scss";
import { Api } from "../../../services";
import { useAccessToken } from "../../../hooks";

const CreateClienteProfile = ({ client, updateClient }) => {
    const [openModal, setOpenModal] = useState(false);
    const [plan, setPlan] = useState(
        client.plans && client.plans.length > 0 ? client.plans[0].id : ""
    );
    const [stage, setStage] = useState(client.stage);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [etapas, setEtapas] = useState([]);
    const [plans, setPlans] = useState([{ id: 0, name: "Free" }]);

    useEffect(() => {
        const id = client.plans.length > 0 ? client.plans[0].id : "";
        if (plan != id) changePlan();
    }, [plan, isAuthenticated]);

    useEffect(() => {
        if (stage != client.stage) changeStage();
    }, [stage, isAuthenticated]);

    useEffect(() => {
        console.log(client);
        if (isAuthenticated) {
            getPlans();
        }
    }, []);

    useEffect(() => {
        if (plans.length > 1) {
            refreshEtapa();
        }
    }, [plans]);

    const getPlans = () => {
        Api.get("/api/plan/list", `Bearer ${access_token}`)
            .then((response) => {
                console.log("response.data", response.data);
                setPlans([{ id: 0, name: "Free" }, ...response.data]);
                refreshEtapa();
            })
            .catch((erro) => {
                if (erro.message == "Unauthenticated.") {
                    setIsAuthenticated(false);
                }
            });
    };

    const changePlan = () => {
        Api.put(
            `/api/clients/${client.id}/plan/${plan}/change`,
            {},
            `Bearer ${access_token}`
        )
            .then((res) => {
                updateClient(res.data);
                refreshEtapa();
            })
            .catch((err) => console.log(err));
    };

    const refreshEtapa = () => {
        const planFound = plans.find((currentPlan) => currentPlan.id == plan);
        console.log("refreshEtapa", planFound);
        const countStages = [];
        let count = 1;
        while (planFound.stages >= count) {
            countStages.push({ id: count, name: count });
            count++;
        }
        setEtapas(countStages);
    };

    const changeStage = (e) => {
        Api.put(
            `/api/clients/${client.id}/stage/${stage}/change`,
            {},
            `Bearer ${access_token}`
        )
            .then((res) => updateClient(res.data))
            .catch((err) => console.log(err));
    };

    return (
        <>
            <div className="profile d-flex flex-row justify-content-between">
                <div className="d-flex">
                    <div>
                        {/* A imagem tem que ser dinamica */}
                        <img src={client.photo_url} alt="Foto do cliente" />
                    </div>
                    <div>
                        <h2>{`${client.user.first_name} ${client.user.last_name}`}</h2>
                        {/* Informações de plano e ultima consulta tbm serão dinamicas */}
                        {/* <p><b>Última Consulta</b> {"20/12/2020"}</p> */}
                    </div>
                </div>
                <div className="col-5 d-flex flex-row p-0 justify-content-end">
                    <div className="form-plano d-flex">
                        <div className="button-align-end">
                            <Button
                                text={"Performance do Paciente"}
                                className={"transparent purple"}
                                onSubmit={() => setOpenModal(true)}
                            />
                        </div>
                        <div className="ml-2">
                            <SelectInput
                                id={"plano"}
                                name={"Plano"}
                                label={"name"}
                                options={plans}
                                value={"id"}
                                selected={plan}
                                onSelect={(e) => setPlan(e.target.value)}
                            />
                        </div>
                        <div className="ml-2 select-etapa">
                            <SelectInput
                                id={"etapa"}
                                name={"Etapa"}
                                label={"name"}
                                options={etapas}
                                value={"value"}
                                selected={stage}
                                onSelect={(e) => setStage(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* <MealsReplyList client={client} setOpenModal={setOpenModal} openModal={openModal} /> */}
            </div>

            <Modal
                size={"large"}
                closeModal={() => setOpenModal(false)}
                isOpen={openModal}
                id={"modal-refeicoes"}
            >
                <RefeicoesModal client={client} />
            </Modal>
        </>
    );
};

export { CreateClienteProfile };
