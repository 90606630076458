import React from "react";
import { CardItem } from "../../molecules";

const HomeOptions = () => {
    const options = [
        {
            link: "/pacientes",
            spotlight: true,
            content: {
                image: "/img/pacientes.svg",
                title: "Pacientes",
                text: "Clique e gerencie os pacientes.",
            },
        },
        {
            link: "/config",
            content: {
                image: "/img/interface-app.svg",
                title: "Interface do meu App",
                text: "Clique para modificar a interface.",
            },
        },
        {
            link: "/midias",
            content: {
                image: "/img/video.svg",
                title: "Vídeos",
                text: "Clique e gerencie os vídeos.",
            },
        },
        {
            link: "/questionnaire",
            content: {
                image: "/img/questionarios.svg",
                title: "Meus Questionários",
                text: "Clique e gerencie os questionarios",
            },
        },
        {
            link: "/config/planos",
            content: {
                image: "/img/planos.svg",
                title: "Planos",
                text: "Clique para editar os planos e adicionar um banner para cada um.",
            },
        },
        {
            link: "/lista-alimentos",
            content: {
                image: "/img/lista-sub.svg",
                title: "Lista de Substituição",
                text: "Clique para editar as lista de substituição.",
            },
        },
        {
            link: "/notificacao",
            content: {
                image: "/img/lista-sub.svg",
                title: "Notificação",
                text: "Clique para enviar notificação.",
            },
        },
        {
            link: "/relatorio-usuarios-inativos",
            content: {
                image: "/img/questionarios.svg",
                title: "Relatório de Usuários Inativos",
                text: "Clique para ver o relatório de usuários que tem mais de 8 dias sem acessar o app.",
            },
        },
    ];

    const renderOtions = () => {
        return options.map((option) => (
            <div key={option.link} className="col-md-4">
                <CardItem
                    spotlight={option.spotlight}
                    link={option.link}
                    content={option.content}
                />
            </div>
        ));
    };

    return <div className="row">{renderOtions()}</div>;
};

export { HomeOptions };
