import React, { useState, useEffect } from 'react';
import { CardItemContainer, Loader } from '../../atoms';
import { Paginator, SearchBar } from '../../molecules';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';


function ListOfInactiveUsers() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    to: null,
    total: null
  });

  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      getUserList();
    }
  }, [isAuthenticated, page]);

  const getUserList = () => {
    setIsLoading(true);

    Api.get(`/api/lastaccess/report?page=${page}`, `Bearer ${access_token}`)
      .then(response => {
        setPagination({
          lastPage: response.data.last_page,
          to: response.data.to,
          total: response.data.total
        });
        setIsLoading(false);
        setUsers(response.data.data)
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        }
      })
  }

  const nextPage = () => {
    if (page != pagination.lastPage) {
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  }

  const renderUsers = () => {
    return users.map(user => <tr key={`last-access-client-${user.client_id}`}>
      <td>{`${user.client.user.first_name} ${user.client.user.last_name}`}</td>
      <td>{user.client.cpf ?? ''}</td>
      <td>{user.client.user.email ?? ''}</td>
      <td>{user.client.phone_number ?? ''}</td>
      <td>{user.last_access ?? "Sem acesso registrado"}</td>
    </tr>)
  }


  return (<div>
    {isLoading ?
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader />
        </div>
      </div>
      :
      <div className="table-component">
        <table className="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Último acesso</th>
            </tr>
          </thead>
          <tbody>
            {renderUsers()}
          </tbody>
        </table>
      </div>
    }
    <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />

  </div>)
}

export { ListOfInactiveUsers }