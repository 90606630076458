import React, { useState, useEffect } from 'react';
import { Input, Button, Alert } from '../../../atoms';
import { ListOfBiochemicalTest } from '../../../organisms';
import { ExameBioquimicoForm } from '../../../molecules';
import { Api } from '../../../../services';
import { useAccessToken } from '../../../../hooks';

const ExamesBioquimicos = ({ getFormData, token, client, updateClient }) => {

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [currentExame, setCurrentExame] = useState(null);

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
      </div>
      <Button text="Cadastrar Novo" className="btn-primary" onSubmit={() => setCurrentExame(null)} />
      <div className="row mt-3">
          <div className="col-md-5">
            <ListOfBiochemicalTest client={client} onSelect={setCurrentExame} currentExame={currentExame} />
          </div>
          <div className="col-md-7">
            <ExameBioquimicoForm client={client} updateClient={updateClient} exame={currentExame} setCurrentExame={setCurrentExame} />
          </div>
        </div>
    </>
  );
}

export { ExamesBioquimicos };