import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { PlanoOptions, InternPageHeader } from '../organisms';

function PlanoOptionsPage() {
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-12">
            <InternPageHeader link={"/home"} title={"Planos"} />
          </div>
        </div>
        <PlanoOptions />
      </DashboardContent>
    </div>
  )
}

export { PlanoOptionsPage };
