import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { PlayerWithSplash, ActionsStatuses, ImageInput } from '../../molecules';
import { Input, TextArea, Button, Loader } from '../../atoms';
import { ListOfMedias } from '../ListOfMedias';


const PlanForm = ({ currentPlan, setRefresh, setCurrentPlan }) => {
  const ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
  }
  const [action, setAction] = useState(ACTION_TYPES.CREATE);
  const [shouldCall, setShouldCall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [plan, setPlan] = useState({});
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated && shouldCall) {
      setShouldCall(false);
      switch (action) {
        case ACTION_TYPES.CREATE:
          createPlan();
          break;
        case ACTION_TYPES.DELETE:
          deletePlan();
          break;
        default:
          updatePlan();
          break;
      }
    }
  }, [isAuthenticated, shouldCall])

  useEffect(() => {
    console.log(currentPlan, 'currentPlan');
    if (currentPlan) {
      setPlan(currentPlan);
    } else {
      setPlan({});
    }
  }, [currentPlan])

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  const deletePlan = () => {
    setLoading(true);
    Api.delete(`/api/plans/${currentPlan.id}`, {}, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setLoading(false);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
        }
      })
  }

  const createPlan = () => {
    setLoading(true);
    // let formData = new FormData();
    // formData.append('name', plan.name);
    // formData.append('stages', plan.stages);
    // formData.append('description', plan.description);
    // formData.append('media_id', plan.media_id);
    const body = getFormData(plan);

    Api.post(`/api/plans`, body, `Bearer ${access_token}`)
      .then(response => {
        setPlan({});
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
          setLoading(false);
        }
      })
  }

  function getFormData(formParams) {
    let formData = new FormData();
    Object.keys(formParams).forEach((key) => {
      if (formParams[key]) {
        formData.append(key, formParams[key]);
      }
    });
    return formData;
  }

  const updatePlan = () => {
    setLoading(true);
    const body = getFormData(plan);
    Api.post(`/api/plans/update`, body, `Bearer ${access_token}`)
      .then(_ => {
        setLoading(false);
        setSucesso(true);
        setRefresh(true);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setLoading(false);
          if(erro.errors) {
            setErro(erro.errors[Object.keys(erro.errors)[0]][0]);
          } else {
            setErro(erro.message);
          }
        }
      })
  }

  const onSubmit = () => {
    if (currentPlan) {
      setAction(ACTION_TYPES.UPDATE);
    } else {
      setAction(ACTION_TYPES.CREATE);
    }
    setShouldCall(true);
  }

  const onDelete = () => {
    setAction(ACTION_TYPES.DELETE);
    setShouldCall(true);
  }

  const changeVideo = (media) => {
    let plan_aux = { ...plan };
    plan_aux.media = media ? media : null;
    plan_aux.media_id = media ? media.id : null;
    setPlan(plan_aux);
  }

  const onChangeInputValue = (name, value) => {
    let plan_aux = { ...plan };
    if (name == "banner") {
      plan_aux.photo_url = URL.createObjectURL(value);
      plan_aux.photo_file = value;
    } else if(name === "logo") {
      plan_aux.banner_url = URL.createObjectURL(value);
      plan_aux.logo_file = value;
    }else {
      plan_aux[name] = value;
    }
    setPlan(plan_aux);
  }


  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-10">
                <Input
                  type={"text"}
                  id={"name"}
                  name={"name"}
                  onChangeInputValue={onChangeInputValue}
                  label={"<b>Nome</b>"}
                  value={plan.name || ""}
                />
              </div>
              <div className="col-2">
                <Input
                  type={"number"}
                  id={"stages"}
                  name={"stages"}
                  onChangeInputValue={onChangeInputValue}
                  label={"<b>Etapas</b>"}
                  value={plan.stages || ""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextArea
                  label={plan.id ? "Quer atualizar a <b>descrição</b>?" : "Aqui você <b>descreverá</b> o plano"}
                  value={plan.description || ""}
                  onChangeInputValue={onChangeInputValue}
                  id={"description"}
                  name={"description"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ImageInput
              onChangeInputValue={onChangeInputValue}
              file={plan.banner_url ? plan.banner_url : undefined}
              label={"<b>Selecione a logo</b>"}
              name={"logo"}
            />
            <ImageInput
              onChangeInputValue={onChangeInputValue}
              file={plan.photo_url ? plan.photo_url : undefined}
              label={"<b>Selecione o banner</b>"}
              name={"banner"}
            />
            <div className="w-100 mt-5">
              <Button disabled={loading} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={currentPlan ? "Atualizar Plano" : "Cadastrar Plano"} />
              <Button disabled={loading || plan.id == null} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Plano"} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        {/* <div className="row">
          <div className="col-8">
            <ListOfMedias select={true} currentMedia={plan.media} type={"select"} onSelect={changeVideo} />
          </div>
          <div className={`col-4 d-flex flex-column ${plan.media ? "justify-content-between" : "justify-content-end"}`}>
            <PlayerWithSplash media={plan.media} />
            <div className="w-100 mt-5">
              <Button disabled={loading} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={currentPlan ? "Atualizar Plano" : "Cadastrar Plano"} />
              <Button disabled={loading || plan.id == null} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Plano"} />
            </div>
          </div>
        </div> */}
        {loading &&
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader />
            </div>
          </div>
        }
        <div className="row w-100 m-0">
          <div className="col-12">
            <ActionsStatuses sucesso={sucesso} erro={erro} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { PlanForm };
