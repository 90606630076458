import React, { useState, useEffect } from 'react';
import { Input, Button, Alert } from '../../atoms';
import { ListOfMediasWithPlayer } from '../../organisms';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';

const ExameBioquimicoForm = ({ exame, client, updateClient, setCurrentExame }) => {

  useEffect(() => {
    setForm({
      collection_date: exame ? exame.collection_date : "",
      delivery_date: exame ? exame.delivery_date : "",
      name: exame ? exame.name : "",
      result: exame ? exame.result : "",
      grade: exame ? exame.grade : "",
      nutritional_recommendation: exame ? exame.nutritional_recommendation : "",
      media_id: exame ? exame.media_id : "",
      media: exame ? exame.media : null,
    });
  }, [exame])

  const [form, setForm] = useState({});

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [isBusy, setIsBusy] = useState(false);

  const { collection_date, delivery_date, name, result, grade, nutritional_recommendation, media } = form;

  const changeVideo = (media) => {
    setForm({ ...form, media: media, media_id: media ? media.id : null });
    console.log('media', media)
  }

  const create = () => {
    setAlert(null);
    setIsBusy(true);
    Api.post(`/api/clients/${client.id}/biochemicaltests`, form, `Bearer ${access_token}`)
      .then(res => {
        setCurrentExame(null);
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({ ...client, biochemical_tests: [...(client.biochemical_tests || []), res.data] });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const update = () => {
    setAlert(null);
    setIsBusy(true);
    Api.put(`/api/clients/biochemicaltests/${exame.id}`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({
          ...client, biochemical_tests: (client.biochemical_tests || []).map(exame => {
            if (exame.id === res.data.id) {
              return res.data;
            }
            return exame;
          })
        });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const deleteExame = () => {
    setAlert(null);
    setIsBusy(true);
    Api.delete(`/api/clients/biochemicaltests/${exame.id}`, {}, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({
          ...client,
          biochemical_tests: (client.biochemical_tests || []).filter(exame => exame.id !== res.data.id) });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
        <div className="row">
          <div className="col-4">
            <Input label="Data de Coleta" type="date" value={collection_date}
              onChangeInputValue={(collection_date, value) => setForm({ ...form, collection_date: value })} />
          </div>
          <div className="col-4">
            <Input label="Data de Entrega" type="date" value={delivery_date}
              onChangeInputValue={(delivery_date, value) => setForm({ ...form, delivery_date: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-7">
            <Input label="Vitamina" value={name}
              onChangeInputValue={(name, value) => setForm({ ...form, name: value })} />
          </div>
          <div className="col-2">
            <Input label="Resultado" value={result}
              onChangeInputValue={(result, value) => setForm({ ...form, result: value })} />
          </div>
          <div className="col-3 d-flex align-self-end form-group">
            <select className="form-control" id="grade" value={grade} onChange={(e) => setForm({ ...form, grade: e.target.value })}>
              <option value="">Nivel</option>
              <option value="Alto">Alto</option>
              <option value="Médio">Médio</option>
              <option value="Baixo">Baixo</option>
            </select>
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="nutritional_recommendation">Recomendação Nutricional</label>
              <textarea className="form-control" id="nutritional_recommendation" rows="3"
                value={nutritional_recommendation} onChange={(e) => setForm({ ...form, nutritional_recommendation: e.target.value })}></textarea>
            </div>
          </div>
        </div>
        {/* Linha */}
        <div className="row mt-2">
          <div className="col-12">
            <ListOfMediasWithPlayer select={true} currentMedia={media} type={"select"} onSelect={changeVideo} />
          </div>
        </div>
        <div className="mt-5 actions mb-5">
          {exame && <Button text="Excluir avaliação" className="btn-danger" onSubmit={() => deleteExame()} />}
          <Button disabled={isBusy} text={exame ? "Atualizar Exame" : "Criar Exame"} onSubmit={() => exame ? update() : create()} className="btn-submit" />
        </div>
      </div>
    </>
  );
}

export { ExameBioquimicoForm };
