import React, { useState, useEffect } from 'react';
import { Input, Button, Alert, TextArea } from '../../atoms';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';

const PatientJourneyForm = ({ currentSelected, client, updateClient, setCurrentSelected }) => {

  useEffect(() => {
    setForm({
      title: currentSelected ? currentSelected.title : "",
      description: currentSelected ? currentSelected.description : ""
    });
  }, [currentSelected])

  const [form, setForm] = useState({});

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [isBusy, setIsBusy] = useState(false);

  const create = () => {
    setAlert(null);
    setIsBusy(true);
    Api.post(`/api/clients/${client.id}/patientJourney`, form, `Bearer ${access_token}`)
      .then(res => {
        setForm({
          title: "",
          description: ""
        });
        setCurrentSelected(null);
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({ ...client, emotional_evaluations: [...(client.emotional_evaluations || []), res.data] });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const update = () => {
    setAlert(null);
    setIsBusy(true);
    Api.put(`/api/clients/${client.id}/patientJourney/${currentSelected.id}`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({
          ...client, emotional_evaluations: (client.emotional_evaluations || []).map(evaluation => {
            if (evaluation.id === res.data.id) {
              return res.data;
            }
            return evaluation;
          })
        });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const deleteExame = () => {
    setAlert(null);
    setIsBusy(true);
    Api.delete(`/api/clients/${client.id}/patientJourney/${currentSelected.id}`, {}, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setCurrentSelected(null);
        updateClient({
          ...client,
          emotional_evaluations: (client.emotional_evaluations || []).filter(evaluation => evaluation.id !== res.data.id)
        });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const { title, description } = form;

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
        <div className="row">
          <div className="col-12">
            <Input label="Titulo" value={title}
              onChangeInputValue={(title, value) => setForm({ ...form, title: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12">
            <TextArea label="Descrição" value={description} row={6}
              onChangeInputValue={(description, value) => setForm({ ...form, description: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="mt-5 actions mb-5">
          {currentSelected && <a onClick={() => deleteExame()} >Excluir Cadastro</a>}
          <Button disabled={isBusy} text={currentSelected ? "Atualizar" : "Criar"} onSubmit={() => currentSelected ? update() : create()} className="btn-submit" />
        </div>
      </div>
    </>
  );
}

export { PatientJourneyForm };