import React, { useState } from 'react';
import { Input, Button } from '../../atoms';
import { ActionsStatuses } from '../../molecules';
import { Api } from '../../../services/Api';


const NewPasswordForm = ({token}) => {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isBuse, setIsBusy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const onChangeInputValue = (name, value) => {
    setError("");
    if (name == 'email') {
      setEmail(value);
    } else if (name == 'password1') {
      setPassword1(value);
    } else {
      setPassword2(value);
    }
  }

  const onSubmit = () => {
    if (email != "" && password1 != "" && password2 != "") {
      if (password1 == password2) {
        setIsBusy(true);
        setError("");
        const body = {
          email: email,
          password: password1
        };
        Api.post(`/api/password/reset/${token}`, body)
        .then(_ => {
          setSuccess(true);
        })
        .catch(erro => {
          if (erro.message === "Email Invalido") {
            setError("Não encontramos uma solicitação de troca de senha para esse e-mail");
          } else if (erro.message == "Token Invalido") {
            setError("Sua solicitação de troca de senha não foi encontrada, repita o processo")
          }else {
            setError("Ocorreu um erro, tente novamente mais tarde");
          }
        })
        .finally(() => setIsBusy(false));
      } else {
        setError("As senhas não correspondem");
      }
    } else {
      setError("Preencha os campos do formulário");
    }
  }

  return (
    <div className="form-container">
      <Input onChangeInputValue={onChangeInputValue} value={email} label={"Informe seu e-mail"} name={"email"} id={"email"} type={"email"} />
      <Input onChangeInputValue={onChangeInputValue} value={password1} label={"Informe a nova senha"} name={"password1"} id={"password1"} type={"password"} />
      <Input onChangeInputValue={onChangeInputValue} value={password2} label={"Repita a senha"} name={"password2"} id={"password2"} type={"password"} />
      <div className="button-container">
        <Button disabled={isBuse} onSubmit={onSubmit} className={"btn-primary btn-block"} text={"ENVIAR"}/>
      </div>
      <div className="mt-3">
        <ActionsStatuses sucesso={success} erro={error} />
      </div>
    </div>
  )
}

export { NewPasswordForm }
