import React from 'react';

const QuestionTableLine = ({ question, onClickQuestion, selected }) => {
  return (
    <tr className={selected ? "selected" : undefined}>
      <td onClick={onClickQuestion}>{question.question}</td>
      <td onClick={onClickQuestion} className="text-right">
        {question.created_at}
      </td>
    </tr>
  );
}

export { QuestionTableLine };
