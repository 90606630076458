import React from 'react';
import './style.scss';

const Button = ({ className, text, onSubmit, disabled = false }) => {
  return (
    <button disabled={disabled} onClick={onSubmit} className={`btn ${className}`}>{text}</button>
  )
}

export { Button }
