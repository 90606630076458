import React, { useState } from 'react';
import { SideBar, QuestionnaireForm } from '../../organisms';
import { DashboardContainer as DashboardContent, CardItemContainer } from '../../atoms';
import { InternPageHeader, ListOfQuestionnaires } from '../../organisms';
import './styles.scss';

function Questionnaire() {

	const [currentQuestionnaire, setCurrentQuestionnaire] = useState(null);
	const [refresh, setRefresh] = useState(false);

	return (
		<div className="dashboard-container d-flex flex-column">
			<SideBar />
			<DashboardContent>
				<div className="row d-flex">
					<div className="col-12">
						<InternPageHeader link={"/home"} title={"Meus Questionários"} />
					</div>
				</div>
				<CardItemContainer>
					<div className="row d-flex flex-fill h-100 w-100" >
						<div className="col-3 d-flex" >
								<ListOfQuestionnaires currentQuestionnaire={currentQuestionnaire} refresh={refresh} setRefresh={setRefresh} onSelect={setCurrentQuestionnaire} />
							</div>
						<div className="col-9 d-flex" >
							{currentQuestionnaire && <QuestionnaireForm currentQuestionnaire={currentQuestionnaire} setRefresh={setRefresh} />}
						</div>
					</div>
				</CardItemContainer>
			</DashboardContent>
		</div>
	)
}

export { Questionnaire };
