import React, { useState, useEffect } from 'react';
import { SelectInput, Button } from '../../atoms';
import { Modal } from '..';
import './style.scss';
import { ListOfAnswers } from '../../organisms';

const AnswersInfo = ({ answers, type, enableSelect, selectValues, buttonLabel }) => {

  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (selectValues && selectValues.length) {
      const size = selectValues.length;
      setSelected(selectValues[size - 1]);
    } else {
      setSelected("");
    }
  }, [selectValues]);

  const [openModelRecomNutricional, setOpenModelRecomNutricional] = useState(false);

  const metadata = (selected) => {
    if (selected) {
      return answers[type][selected]['metadata'];
    } else {
      return answers[type]['metadata'];
    }
  }

  const getdata = (selected) => {
    if (selected) {
      return answers[type][selected]['data'];
    } else {
      return answers[type]['data'];
    }
  }

  return (
    <>
      {(answers && type && !enableSelect && answers[type] && answers[type]['data'] && answers[type]['data'].length > 0
        || enableSelect && answers && selected && type && answers[type] && answers[type][selected]['data'] && answers[type][selected]['data'].length > 0
      ) &&
        (<div className="mx-1">
          <div className="d-flex">
            <Button className="btn-light-green" onSubmit={() => setOpenModelRecomNutricional(true)} text={buttonLabel || "Questionário Respondido"} />
          </div>
          <Modal closeModal={() => setOpenModelRecomNutricional(false)} isOpen={openModelRecomNutricional} id={"modal-questionario-respondido"}>
            <div className="pl-4 pr-4">
              <div class="d-flex justify-content-between">
                <h3 className="font-weight-bold">Questionário Respondido</h3>
                { selectValues && <SelectInput id={'etapa'} name={'Etapa'} options={selectValues} selected={selected} onSelect={(e) => setSelected(e.target.value)} /> }
              </div>
              <small>
                {
                  metadata(selected)['stage'] &&
                  <>
                    <b>Etapa:</b> {metadata(selected)['stage']},
                  </>
                } <b>Data: </b> {metadata(selected)['date']}</small>
              <div className="mt-3">
                <ListOfAnswers answers={getdata(selected)} />
              </div>
            </div>
          </Modal>
        </div>)
      }
    </>
  );
}

export { AnswersInfo };
