import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ListOfInactiveUsers } from '../organisms';


const UsersReport = () => {
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <InternPageHeader link={"/home"} title={"Relatório de usuários inativos"} />
        <div className="card">
          <ListOfInactiveUsers />
        </div>
      </DashboardContent>
    </div>
  )
}

export { UsersReport };
