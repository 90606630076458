import React, { useState, useEffect } from 'react';
import { SideBar, CreateClientContainer } from '../organisms';
import { SubSideItem, Loader, NoData, DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader } from '../organisms';
import { CreateClienteProfile } from '../molecules';
import { useAccessToken } from '../../hooks';
import { Api } from '../../services';

function CreateClient({ }) {

  return (
    <div className="dashboard-container create-client-container">
      <SideBar />
      <DashboardContent>
        <InternPageHeader link={"/pacientes"} title={"Cadastrar Paciente"} />
        <CreateClientContainer />
      </DashboardContent>
    </div>
  )
}

export { CreateClient };
