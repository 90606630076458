import React from 'react';

const UploadFileButton = ({ onClick, text }) => {
  return (
    <div onClick={onClick} className="upload-video">
      <img src="/img/upload.svg" alt="icone de upload de arquivo" />
      <span>{text}</span>
    </div>
  );
}

export { UploadFileButton };
