import React from 'react';
import { Link } from 'react-router-dom';

const BackButton = ({ link }) => {
  return (
    <Link to={link}>
      <img className="backbutton" src="/img/back.svg" />
    </Link>
  );
}

export { BackButton };
