import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { useAccessToken } from '../../../hooks';
import { PlayerWithSplash, ActionsStatuses } from '../../molecules';
import { Button, Loader } from '../../atoms';
import { ListOfMedias } from '../ListOfMedias';

const VideoForm = ({ currentVideo }) => {
  const [shouldCall, setShouldCall] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [media, setMedia] = useState(null);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);


  useEffect(() => {
    setMedia(null);
  }, [currentVideo])

  useEffect(() => {
    if (isAuthenticated && shouldCall) {
      updateVideo();
    }
  }, [isAuthenticated, shouldCall])

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  const updateVideo = () => {
    setLoading(true);
    Api.post(`/api/videos/update`, {
      id: currentVideo.id,
      media_id: media.id
    }, `Bearer ${access_token}`)
      .then(response => {
        setLoading(false);
        setSucesso(true);
        setShouldCall(false);
      })
      .catch(erro => {
        if (erro.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else {
          setShouldCall(false);
          setLoading(false);
          setErro(erro.message);
        }
      })
  }

  const onSubmit = () => {
    setShouldCall(true);
  }

  const changeVideo = (media) => {
    setMedia(media ? media : null);
  }

  return (
    <div className="row">
      <div className="col-8">
        <ListOfMedias select={true} currentMedia={media ? media : (currentVideo ? currentVideo.media : null)} type={"select"} onSelect={changeVideo} />
      </div>
      <div className="col-4">
        <PlayerWithSplash media={media ? media : (currentVideo ? currentVideo.media : null)} />
        <div className="w-100 mt-5">
          <Button disabled={loading || media == null} onSubmit={onSubmit} className={"btn-block btn-submit mb-5"} text={"Escolher este vídeo"} />
          {/* <Button disabled={loading || plan.id == null} onSubmit={onDelete} className={"btn-block btn-danger btn-red btn-outline mt-3"} text={"Apagar Plano"} /> */}
        </div>
      </div>
      <div className="col-12">
        {loading &&
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader />
            </div>
          </div>
        }
        <div className="row w-100 m-0">
          <div className="col-12">
            <ActionsStatuses sucesso={sucesso} erro={erro} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { VideoForm };
