import React, { useEffect } from 'react';

const Modal = ({ id, isOpen, closeModal, children, size }) => {
  useEffect(() => {
    window.onclick = function (event) {
      let modal = document.getElementById(id);
      if (event.target == modal) {
        closeModal();
      }
    }
  }, [])


  return (
    <div id={id} className={`modal align-items-center ${isOpen ? "open d-flex" : ""}`}>
      <div className={`modal-content ${size == "large" ? "large" : ""}`}>
        <div className="row">
          <div className="col-12 text-right">
            <span onClick={closeModal} className="close">&times;</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Modal };
