import React from 'react';

const SearchInput = ({ name, placeholder, onChangeInputValue, inputClassName, id, enterPress }) => {

  return (
    <input
      placeholder={placeholder || "Pesquisar"}
      className={`search-input ${inputClassName}`}
      name={name}
      id={id}
      type="text"
      onKeyPress={event => {
        if (event.key === 'Enter' && enterPress) {
          enterPress();
        }
      }}
      onChange={() => onChangeInputValue(name, event.target.value)}
    />
  );
}

export { SearchInput };
