import React from 'react';

const CardItemText = ({ title, text }) => {
  return (
    <div className="card-content">
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
}

export { CardItemText };
