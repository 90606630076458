import React, { useState, useEffect } from 'react';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';
import { CardItemContainer, Loader, Button, SelectInput } from '../../atoms';
import { ClientTableLine, Paginator, SearchBar } from '../../molecules';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from 'moment';

import './style.scss'


const ListOfClients = () => {
    const [clients, setClients] = useState([])
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        lastPage: 0,
        to: null,
        total: null
    })
    const [filter, setFilter] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            getClients();
        }
    }, [isAuthenticated, page]);

    useEffect(() => {
        if(page !== 1) {
            setPage(1);
        } else {
            getClients();
        }
    }, [filter]);

    const getClients = () => {
        setLoading(true);
        let url = `/api/clients?page=${page}&search=${search}`;
        const filtersKeys = Object.keys(filter);
        if(filtersKeys.length > 0) {
            filtersKeys.forEach((key) => url += `&${key}=${filter[key]}`);
        }
        Api.get(url, `Bearer ${access_token}`)
            .then(response => {
                console.log('/api/clients', response)
                setPagination({
                    lastPage: response.data.last_page,
                    to: response.data.to,
                    total: response.data.total
                });
                setClients(response.data.data)
            })
            .catch(erro => {
                console.log('erro', erro)
                if (erro.message == 'Unauthenticated.') {
                    setIsAuthenticated(false);
                }
            })
            .finally(() => setLoading(false));
    }

    const onClickClient = (id) => {
        history.push('/pacientes/' + id)
    }

    const nextPage = () => {
        if (page != pagination.lastPage) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page != 1) {
            setPage(page - 1);
        }
    }

    const renderClients = () => {
        return clients.map(client =>
            <ClientTableLine onClickClient={onClickClient} key={client.id} client={client} />
        )
    }

    const onChangeFilter = (e, key) => {
        setFilter({ ...filter, [key]: e.target.value });
    }

    return (
        <>
            <div className="clients-actionbar d-flex justify-content-between">
                <div className="col-4 p-0">
                    <SearchBar onSubmit={() =>{ setPage(1); getClients()}} onChangeInputValue={(_, value) => setSearch(value)} />
                </div>
                <div className="col-2 p-0">
                    <SelectInput id="select-status" hideLabel label="name"
                        options={[
                            { value: "", name: 'Todos Status' },
                            { value: "aguardando_agendamento", name: 'Aguardando Agendamento' },
                            { value: "questionario_respondido", name: 'Questionário Respondido' },
                            { value: "questionario_retorno", name: 'Questionário de Retorno' },
                            { value: "estrategia_andamento", name: 'Estrategia em Andamento' },
                            { value: "sem_estrategia", name: 'Sem Estratégia' }
                        ]}
                        value="value" selected={filter.status} onSelect={(e) => onChangeFilter(e, "status")} />
                </div>
                <div className="col-2 p-0">
                    <SelectInput id="select-plano" hideLabel label="name"
                        options={[
                            { value: "", name: 'Todos Planos' },
                            { value: "plan", name: 'Plano Pago' },
                            { value: "free", name: 'Gratuito' },
                        ]}
                        value="value" selected={filter.type} onSelect={(e) => onChangeFilter(e, "type")} />
                </div>
                <div className="col-2 p-0">
                    <SelectInput id="aniversario" hideLabel label="name"
                        options={[
                            { value: "", name: 'Todos' },
                            { value: moment().format('MM-DD'), name: 'Aniversariantes' },
                        ]}
                        value="value" selected={filter.birth} onSelect={(e) => onChangeFilter(e, "birth")} />
                </div>
                <Link to={"/cadastrar-paciente"}>
                    <Button text="Cadastrar Novo Cliente" className="cadastrar-novo-cliente-button" />
                </Link>
            </div>
            <CardItemContainer>
                <div className="d-flex flex-fill flex-column h-100">
                    <div className="d-flex flex-fill overflow-y-auto">
                        {
                            loading ?
                                (<div className="d-flex flex-fill justify-content-center"><Loader /></div>)
                                :
                                (
                                    <table className="table table-clients">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th className="text-center">Status</th>
                                                <th>Plano</th>
                                                <th>Nascimento</th>
                                                <th>Etapa</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderClients()}
                                        </tbody>
                                    </table>
                                )
                        }
                    </div>
                    <div className="mt-4">
                        <Paginator previousPage={previousPage} nextPage={nextPage} page={page} pagination={pagination} />
                    </div>
                </div>
            </CardItemContainer>
        </>
    );
}

export { ListOfClients };
