import React, { useState } from 'react';
import { SideBar, ListOfChats, Chat } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import firebase from 'firebase';

function ChatPage() {
  const [currentChat, setCurrentChat] = useState();
  const [index, setIndex] = useState(null);

  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <div className="row">
          <div className="col-md-5">
            <ListOfChats onSelect={setCurrentChat} setIndex={setIndex} currentChat={currentChat}/>
          </div>
          <div className="col-md-7">
            {currentChat && <Chat index={index} currentChat={currentChat}/>}
          </div>
        </div>
      </DashboardContent>
    </div>
  )
}

export { ChatPage };
