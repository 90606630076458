import React from 'react';
import './style.scss';

const SessionTitle = ({ name, icon }) => {
  return (
    <div className="d-flex flex-fill session-title">
      {
        icon && <img height="30" src={icon} alt="Icone" />
      }
      <small className="session-title-name">{name}</small>
    </div>
  );
}

export { SessionTitle };
