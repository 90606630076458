import React from 'react';

const ProgressBar = ({ porcentage }) => {
  return (
    <div className="progress my-3">
      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${porcentage}%`}} aria-valuenow={porcentage} aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  );
}

export { ProgressBar };
