import React from 'react';
import { Input, Button } from '../../atoms';

const AvisoAguaForm = ({ setMealDaySelected, submitNew, editAviso, mealDaySelected, loading }) => {
	return (
		<div className='row'>
			<div className="col-12 mb-5">
				<Input label="Horário" value={mealDaySelected.hour} type="time" onChangeInputValue={(c, horario) => setMealDaySelected({ ...mealDaySelected, hour: horario })} />
			</div>
			<div className="col-12">
				<Button className={"btn-success"} disabled={loading}
					onSubmit={() => mealDaySelected && mealDaySelected.id ? editAviso() : submitNew()}
					text={mealDaySelected && mealDaySelected.id ? "Atualizar" : "Cadastrar"} />
			</div>
		</div>
	)
}

export { AvisoAguaForm }
