import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ListOfClients } from '../organisms';

function ClientsPage() {
  return (
    <div className="dashboard-container">
      <SideBar />
      <DashboardContent>
        <InternPageHeader link={"/home"} title={"Pacientes"} />
        <ListOfClients />
      </DashboardContent>
    </div>
  )
}

export { ClientsPage };
