import React, { useState } from 'react';
import { SideBar, ListOfGroups, InternPageHeader, ReplacementForm } from '../../organisms';
import { DashboardContainer as DashboardContent, CardItemContainer, Button } from '../../atoms';

import './styles.scss';
import { SearchBar } from '../../molecules';

function ReplacementList() {

	const [currentGroup, setCurrentGroup] = useState({})

	return (
		<div className="dashboard-container d-flex flex-column">
			<SideBar />
			<DashboardContent>
				<div className="row d-flex">
					<div className="col-12">
						<InternPageHeader link={"/home"} title={"Lista de Substituição"} />
					</div>
				</div>
				<CardItemContainer>
					<div className="row d-flex flex-fill h-100 w-100" >
						<div className="col-lg-5 flex-column d-flex col-12" >
							<div className="mb-4" >
								<Button className="btn-danger btn-purple" text="Cadastrar Novo" />
							</div>
							<SearchBar placeholder='Pesquisar' isGray />
							<div className="w-100 mt-4 flex-fill d-flex ">
								<ListOfGroups currentGroup={currentGroup} onSelect={setCurrentGroup} />
							</div>
						</div>
						<div className="col-lg-7 mt-5 col-12 " >
							<ReplacementForm currentGroup={currentGroup} />
						</div>
					</div>
				</CardItemContainer>
			</DashboardContent>
		</div>
	)
}

export { ReplacementList };
