import React, { useState, useEffect } from 'react';
import { Input, Button, Alert, SessionTitle } from '../../atoms';
import { useAccessToken } from '../../../hooks';
import { Api } from '../../../services';

const AvaliacaoFisicaForm = ({ evaluation, client, setCurrentEvaluation, updateClient }) => {
  useEffect(() => {
    setForm({
      name: evaluation ? evaluation.name : "",
      evaluation_date: evaluation ? evaluation.evaluation_date : "",
      heigth: evaluation ? evaluation.heigth : "",
      weigth: evaluation ? evaluation.weigth : "",
      physical_exercise: evaluation ? evaluation.physical_exercise : "",
      resistance: evaluation ? evaluation.resistance : "",
      imc: evaluation ? evaluation.imc : "",
      gmb: evaluation ? evaluation.gmb : "",
      weigth_reference: evaluation ? evaluation.weigth_reference : "",
      fat_reference: evaluation ? evaluation.fat_reference : "",
      fat_percentage: evaluation ? evaluation.fat_percentage : "",
      lean_reference: evaluation ? evaluation.lean_reference : "",
      lean_percentage: evaluation ? evaluation.lean_percentage : "",
      arm: evaluation ? evaluation.arm : "",
      waist: evaluation ? evaluation.waist : "",
      abdomem: evaluation ? evaluation.abdomem : "",
      hip: evaluation ? evaluation.hip : "",
      distal_thigh: evaluation ? evaluation.distal_thigh : "",
      medium_thigh: evaluation ? evaluation.medium_thigh : "",
    });
  }, [evaluation])

  const [form, setForm] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  const createEvaluate = () => {
    setAlert(null);
    setIsBusy(true);
    Api.post(`/api/clients/${client.id}/physicalevaluations`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({ ...client, physical_evaluations: [...(client.physical_evaluations || []), res.data ]});
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const deleteEvaluation = () => {
    setAlert(null);
    setIsBusy(true);
    Api.delete(`/api/clients/physicalevaluations/${evaluation.id}`, {}, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        updateClient({
          ...client,
          physical_evaluations: (client.physical_evaluations || []).filter(evaluationCurr => evaluationCurr.id !== res.data.id) });
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const updateEvaluate = () => {
    setAlert(null);
    setIsBusy(true);
    Api.put(`/api/clients/physicalevaluations/${evaluation.id}`, form, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        setCurrentEvaluation(null);
        updateClient({ 
          ...client,
          physical_evaluations: (client.physical_evaluations || []).map(evaluation => {
            if(evaluation.id === res.data.id) {
              return res.data;
            }
            return evaluation;
          })});
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        setIsBusy(false);
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      });
  }

  const { name, evaluation_date, weigth, heigth, physical_exercise, resistance, imc, gmb,
    weigth_reference, fat_reference, fat_percentage, lean_reference, lean_percentage, arm,
    waist, abdomem, hip, distal_thigh, medium_thigh
  } = form;

  return (
    <>
      <div>
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
        <div className="row">
          <div className="col-7">
            <Input label="Retorno" value={name}
              onChangeInputValue={(name, value) => setForm({ ...form, name: value })} />
          </div>
          <div className="col-5">
            <Input label="Data de Avaliação" type="date" value={evaluation_date}
              onChangeInputValue={(evaluation_date, value) => setForm({ ...form, evaluation_date: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12 mb-3">
            <SessionTitle name="Dados Informados" icon="/img/homem-de-negocios.svg" />
          </div>
          <div className="col-3">
            <Input label="Altura" value={heigth} type="number"
              onChangeInputValue={(heigth, value) => setForm({ ...form, heigth: value })} />
          </div>
          <div className="col-3">
            <Input label="Peso" value={weigth} type="number"
              onChangeInputValue={(weigth, value) => setForm({ ...form, weigth: value })} />
          </div>
          <div className="col-6">
            <Input label="Exercício físico" value={physical_exercise}
              onChangeInputValue={(physical_exercise, value) => setForm({ ...form, physical_exercise: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12 mb-3">
            <SessionTitle name="Dados Calculados" icon="/img/accounting.svg" />
          </div>
          <div className="col-4">
            <Input label="Resistência" value={resistance} type="number"
              onChangeInputValue={(resistance, value) => setForm({ ...form, resistance: value })} />
          </div>
          <div className="col-4">
            <Input label="IMC" value={imc} type="number"
              onChangeInputValue={(imc, value) => setForm({ ...form, imc: value })} />
          </div>
          <div className="col-4">
            <Input label="GMB" value={gmb} type="number"
              onChangeInputValue={(gmb, value) => setForm({ ...form, gmb: value })} />
          </div>
          <div className="col-4">
            <Input label="Peso de Referência" value={weigth_reference}
              onChangeInputValue={(weigth_reference, value) => setForm({ ...form, weigth_reference: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12 mb-3">
            <SessionTitle name="Gordura" icon="/img/libra.svg" />
          </div>
          <div className="col-4">
            <Input label="Percentual Ideal" value={fat_reference}
              onChangeInputValue={(fat_reference, value) => setForm({ ...form, fat_reference: value })} />
          </div>
          <div className="col-4">
            <Input label="Percentual Atual" value={fat_percentage}
              onChangeInputValue={(fat_percentage, value) => setForm({ ...form, fat_percentage: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12 mb-3">
            <SessionTitle name="Massa Magra" icon="/img/libra.svg" />
          </div>
          <div className="col-4">
            <Input label="Percentual Ideal" value={lean_reference}
              onChangeInputValue={(lean_reference, value) => setForm({ ...form, lean_reference: value })} />
          </div>
          <div className="col-4">
            <Input label="Percentual Atual" value={lean_percentage}
              onChangeInputValue={(lean_percentage, value) => setForm({ ...form, lean_percentage: value })} />
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-12 mb-3">
            <SessionTitle name="Circuferências" icon="/img/libra.svg" />
          </div>
          <div className="col-4">
            <Input label="Braço" value={arm} type="number"
              onChangeInputValue={(arm, value) => setForm({ ...form, arm: value })} />
          </div>
          <div className="col-4">
            <Input label="Cintura" value={waist} type="number"
              onChangeInputValue={(waist, value) => setForm({ ...form, waist: value })} />
          </div>
          <div className="col-4">
            <Input label="Abdomen" value={abdomem} type="number"
              onChangeInputValue={(abdomem, value) => setForm({ ...form, abdomem: value })} />
          </div>
          <div className="col-4">
            <Input label="Quadril" value={hip} type="number"
              onChangeInputValue={(hip, value) => setForm({ ...form, hip: value })} />
          </div>
          <div className="col-4">
            <Input label="Coxa Distal" value={distal_thigh} type="number"
              onChangeInputValue={(distal_thigh, value) => setForm({ ...form, distal_thigh: value })} />
          </div>
          <div className="col-4">
            <Input label="Coxa Medial" value={medium_thigh} type="number"
              onChangeInputValue={(medium_thigh, value) => setForm({ ...form, medium_thigh: value })} />
          </div>
        </div>
        <div className="mt-5 actions mb-5">
          {evaluation && <Button text="Excluir avaliação" className="btn-danger" onSubmit={() => deleteEvaluation()} />}
          <Button disabled={isBusy} text={evaluation ? "Atualizar Cadastro" : "Criar Cadastro"} onSubmit={() => evaluation ? updateEvaluate() : createEvaluate() } className="btn-submit" />
        </div>
      </div>
    </>
  );
}

export { AvaliacaoFisicaForm };