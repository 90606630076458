import React from 'react';
import { useState } from "react";

const SubSide = ({ subsides, onClick }) => {

  const [selected, setSelected] = useState(0);

  const selectItem = (index) => {
    onClick(index);
    setSelected(index);
  }

  return (
    <div className="flex-column subside-profile d-flex">
      {
        subsides.map((component, index) => (
          <div key={index} onClick={() => selectItem(index)} className={selected === index ? "active subsite-item-cell" : "subsite-item-cell"}>{ component } </div>
        ))
      }
    </div>
  );
}

export { SubSide };
