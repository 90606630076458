import React, { useState, useEffect } from 'react';
import { Input, Button, Alert, SelectInput, TextArea } from '../../atoms';
import { useAccessToken, useFood } from '../../../hooks';
import { Api } from '../../../services/Api';
import './style.scss';

const EstrategiaAlimentarForm = ({ duracao, mealDaySelected, foods, selectedStrat, client, setRefresh, adicionarMeals }) => {
    const [alert, setAlert] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [dias, setDias] = useState([]);
    const food = useFood(access_token, isAuthenticated);
    const [mealDayForm, setMealDayForm] = useState({ options: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let d = [{ day_number: 0, text: 'Todos os dias' }];
        for (let i = 0; i < duracao; i++) {
            d.push({ day_number: i + 1, text: 'Dia ' + (i + 1) });
        }
        setDias(d);
    }, [duracao]);

    //Ao carregar as comidas, adiciona um opção e uma comida como padrão (opcao é obrigatória)
    useEffect(() => {
        setMealDayForm({ ...mealDaySelected });
        console.log('mealDaySelected', mealDaySelected);
        if (mealDaySelected && !mealDaySelected.options.length && food[0]) {
            adicionarOpcao(true)
        }
    }, [food, mealDaySelected]);

    function escolherComida(index, indexFood, foodid) {
        setMealDayForm({
            ...mealDayForm, options: mealDayForm.options.map((option, i) => {
                if (index === i) {
                    option.foods = option.foods.map((food, i2) => {
                        if (indexFood === i2) {
                            return foods.find(foodCurr => foodCurr.id == foodid);
                        }
                        return food;
                    });
                }
                return option;
            })
        });
    }

    function addOpcaoComida(index) {
      if (foods.length) {
        setMealDayForm({
            ...mealDayForm, options: mealDayForm.options.map((option, i) => {
                if (index === i) {
                    option.foods = [...option.foods, foods[0]];
                }
                return option;
            })
        });
      }
    }

    /**
     * Adicionar uma opção de refeição, contendo array de comidas dentro dela (required at lest one)
     * e sugestão de preparo. Cada estratégia pode ter mais de uma opção e mais de uma comida, tendo
     * uma como obrigatório. Fazer alterações na estratégia selecionada e também mudar no array de estratégias
     * @param {Boolean} createArr //criar array novo, usado apenas caso a estratégia não tenha nenhuma opcao (brand new)
     */
    const adicionarOpcao = (createArr) => {
        let aux = { ...mealDayForm };
        aux.options = createArr ? [] : [...aux.options, { foods: [], sugestao: "" }];
        setMealDayForm(aux);
    }

    const removerOpcao = (index) => {
        setMealDayForm({ ...mealDayForm, options: mealDayForm.options.filter((option, i) => i !== index) });
    }

    const cadastrar = () => {
        setAlert(null);
        setLoading(true);
        Api.post(`/api/clients/${client.id}/foodstrategy/${selectedStrat.id}/day/meal`, mealDayForm, `Bearer ${access_token}`)
            .then(res => {
                setAlert({ message: "Refeição cadastrada!", type: 'alert-success' });
                setRefresh(true);
                adicionarMeals();
            })
            .catch((err) => {
                if (err.errors && Object.keys(err.errors).length > 0) {
                    setAlert({ message: err.errors[Object.keys(err.errors)[0]][0], type: 'alert-danger' });
                } else {
                    setAlert({ message: err.message, type: 'alert-danger' });
                }
            })
            .finally(() => {
                setLoading(false);
                document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
            })
    }

    const atualizar = () => {
        setAlert(null);
        setLoading(true);
        Api.put(`/api/clients/${client.id}/foodstrategy/${selectedStrat.id}/day/${mealDayForm.id}/meal/${mealDayForm.meal_id}`, mealDayForm, `Bearer ${access_token}`)
            .then(res => {
                setAlert({ message: "Refeição Atualizada!", type: 'alert-success' });
                setRefresh(true);
            })
            .catch((err) => {
                if (err.errors && Object.keys(err.errors).length > 0) {
                    setAlert({ message: err.errors[Object.keys(err.errors)[0]][0], type: 'alert-danger' });
                } else {
                    setAlert({ message: err.message, type: 'alert-danger' });
                }
            })
            .finally(() => {
                setLoading(false);
                document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
            })
    }

    const excluirFood = (optionIndex, foodIndex) => {
        setMealDayForm({
            ...mealDayForm, options: mealDayForm.options.map((option, i1) => {
                if (i1 == optionIndex) {
                    option.foods = option.foods.filter((food, i2) => i2 != foodIndex);
                }
                return option;
            })
        });
    }

    return (
        <>
            <div className="estrategia-alimentar-form">
                {mealDayForm != null &&
                    <div>
                        {
                            alert && <Alert text={alert.message} className={alert.type} />
                        }
                        <div className="dia">
                            <SelectInput value={'day_number'}
                                label="text" options={dias} hideLabel
                                selected={mealDayForm.day_number}
                                onSelect={(e) => {
                                    const d = e.target.value.replace(/\D/g, '');
                                    setMealDayForm({ ...mealDayForm, day_number: parseInt(d) });
                                }} />
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input label="Refeição" value={mealDayForm.name} onChangeInputValue={(c, refeicao) => setMealDayForm({ ...mealDayForm, name: refeicao })} />
                            </div>
                            <div className="col-4">
                                <Input label="Horário" value={mealDayForm.time} type="time" onChangeInputValue={(c, horario) => setMealDayForm({ ...mealDayForm, time: horario })} />
                            </div>
                            <div className="col-4">
                                <Input label="Calorias" type="number" value={mealDayForm.cal} onChangeInputValue={(c, calorias) => setMealDayForm({ ...mealDayForm, cal: calorias })} />
                            </div>
                            <div className="col-12 estrategia-opcoes">
                                <div className="d-flex justify-content-end mb-3">
                                    <Button className={"btn-primary"} text="Adicionar nova opção"
                                        onSubmit={() => adicionarOpcao()} />
                                </div>
                                {mealDayForm.options.map((opcao, index) =>
                                    <div key={index}>
                                        <div className="opcao">
                                            <div>Opção {index + 1}</div>
                                            <div className="d-flex flex-row">
                                                {mealDayForm.options.length > 1 &&
                                                    <div className="icon-button" onClick={() => removerOpcao(index)}>
                                                        <img src="/img/delete-g.svg" style={{ width: '20px' }} />
                                                    </div>
                                                }
                                                <div className={`icon-button ${!foods.length ? 'disabled' : ''}`} onClick={() => addOpcaoComida(index)}>
                                                    <img src="/img/add.svg" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ padding: 16 }}>
                                            {opcao.foods.map((comida, indexFood) => (
                                                <div key={`${indexFood}-${index}`} className="row">
                                                    <div className="col-10">
                                                        <SelectInput value={'id'}
                                                            label="name"
                                                            options={foods.map((food) => ({...food, name: `${food.name} ${food.qtd}`}))}
                                                            hideLabel
                                                            selected={comida.id}
                                                            onSelect={(e) => {
                                                                escolherComida(index, indexFood, Number(e.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="delete-button" onClick={e => {
                                                            e.stopPropagation();
                                                            excluirFood(index, indexFood);
                                                        }}>
                                                            <img className="delete" src="/img/delete.svg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                            <TextArea
                                                value={opcao.preparation}
                                                row={3}
                                                onChangeInputValue={(c, sugestao) => {
                                                    setMealDayForm({
                                                        ...mealDayForm, options: mealDayForm.options.map((option, i2) => {
                                                            if (index == i2) {
                                                                option.preparation = sugestao;
                                                            }
                                                            return option;
                                                        })
                                                    });
                                                }}
                                                label="Sugestão de Preparo" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <Button className={"btn-success"} disabled={loading}
                                onSubmit={() => mealDaySelected && mealDaySelected.id ? atualizar() : cadastrar()}
                                text={mealDaySelected && mealDaySelected.id ? "Atualizar" : "Cadastrar"} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export { EstrategiaAlimentarForm };