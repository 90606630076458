import React from 'react';

const SearchButton = ({ onClick, buttonClassName }) => {
  return (
    <button type="submit" className={`search-button ${buttonClassName}`} onClick={onClick}>
      <img src="/img/search.svg" alt="imagem do botão de busca" />
    </button>
  );
}

export { SearchButton };
