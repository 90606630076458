import React from 'react';
import { LoginForm } from '../organisms';

const LoginPage = ({history}) => {
  return (
    <div className="login-container">
      <div className="login-sidebar">
        <div>
          <div className="login-text">
            <img className="login-logo" src="/img/logo-pnp.svg" />
            <h3>Bem-vindo ao Painel Administrativo</h3>
            <p>Acompanhe a evolução do seus pacientes e gerencie todas as informações coletadas pelo Aplicativo.</p>
          </div>
          <LoginForm history={history}/>
        </div>
      </div>
      <div className="login-imageholder">
      </div>
    </div>
  )
}

export { LoginPage };
