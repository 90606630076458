import React, { useState } from 'react';
import { Loader, DeleteButton } from '../../atoms';

function ListOfGroups({ currentGroup, onSelect }) {
  const [groups, setGroups] = useState([
    {
      id: 0,
      name: 'Grupo A',
      kcal: '654',
      foods: ' Maçã/Pera:1 unid. M (110g); Pêssego: 2 unid. M (170g); Tangerina: 2 unid. P (180g) ; Uva: 12 unid. M (100g) ; Água de Coco: (330 ml) ;Salada de Fruta (sem açúcar): (120ml)'
    },
    {
      id: 1,
      name: 'Grupo B',
      kcal: '654',
      foods: "Golgi Berry: 2 col. de sopa rasa (24g) ; Coco Seco: 2 col. de sob. (14g) ; Farinha de Amêndoas: 1 col. de sopa (12g) ; Farinha de Coco Orgânica: 1 col. de sopa (13g); Substituto do LEITE: Coalha da Light desnatada: (100ml) "
    },
    {
      id: 2,
      name: 'Grupo C',
      kcal: '654',
      foods: 'Batata doce (70g);  Abacaxi: 2 rodelas M (140g); Goiaba (130g); Nectarina: 2 unid. M (140g); Acerola: 18 unid. M (216g); Ameixa Fresca: 1 unid. M (130g) '
    },
  ])

  const renderGroups = () => {
    return groups.map((group, index) =>
      <tr
        className={(group.id == (currentGroup ? currentGroup.id : null)) ? "selected" : undefined}
        onClick={() => onSelect((group.id == (currentGroup ? currentGroup.id : null)) ? null : group)}
        key={`group-${index}`}
      >
        <td>{group.name}</td>
        <td className="text-right">{group.kcal}</td>
      </tr>
    )
  }

  return (
    <div className="w-100 p-3 borderRadius-5 backgroundGray"  >
      {false ?
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader />
          </div>
        </div>
        :
        <table className="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th className="text-right">Kcal</th>
            </tr>
          </thead>
          <tbody>
            {renderGroups()}
          </tbody>
        </table>
      }

    </div>

  )
}

export { ListOfGroups };
