import React, { useEffect } from 'react';
import { Loader } from '../../atoms';
import './style.scss';

const ChatMessageList = ({ messages, loading }) => {
  useEffect(() => {
    let chatObj = document.getElementById("chat");
    chatObj.scrollTop = chatObj.scrollHeight;
  }, [])

  return (
    <div id="chat" className="chat-message-list ">
      {loading ?
        <div className="loader">
          <Loader />
        </div>
        :
        <div className="d-flex justify-content-end flex-column py-3">
          {
            messages.map((message, i) => (
              <div key={i} className={`message ${message.send_by_user ? 'client' : 'admin'}`}>
                <p>{message.text}</p>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
}

export { ChatMessageList };
