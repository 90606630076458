import React, { useState, useEffect } from "react";
import { Input, Button, Alert, SelectInput, LinkButton } from "../../../atoms";
import { EstrategiaAlimentarForm, Modal } from "../../../molecules";
import { Api } from "../../../../services";
import { useAccessToken } from "../../../../hooks";
import { ListOfRefeicao } from "../../ListOfRefeicao";

const EstrategiaAlimentar = ({ client }) => {
    const [alert, setAlert] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
    const [mealDaySelected, setMealDaySelected] = useState(null);
    const [duracao, setDuracao] = useState(1); //N de dias da estratégia
    const [estrategias, setEstrategias] = useState([]); //gerencia estrategias entre components children
    const [selectedStrat, setSelectedStrat] = useState(null); //estrategia selecionada para editar
    const [selectedDestiny, setSelectedDestiny] = useState(null); //estrategia selecionada como destino de uma cópia
    const [foods, setFoods] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [pdf, setPdf] = useState(null);
    const [openDuplicarEstrategiaModal, setOpenDuplicarEstrategiaModal] =
        useState(false);
    const [loadingCopy, setLoadingCopy] = useState(false);
    const [loadingGeneratePDF, setLoadingGeneratePDf] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [copyError, setCopyError] = useState(null);

    useEffect(() => {
        getStrategies();
    }, [client]);

    const getStrategies = () => {
        Api.get(
            `/api/clients/${client.id}/foodstrategy/all`,
            `Bearer ${access_token}`
        ).then((res) => {
            const { data } = res;
            const estrategiaList = data.map((stra) => {
                stra.text = `${stra.strategy_number}º Estrategia`;
                return stra;
            });
            setEstrategias(estrategiaList);
            console.log("setSelectedStrat", estrategiaList[0]);
            setSelectedStrat(estrategiaList[0]);
            setDuracao(estrategiaList[0] ? estrategiaList[0].duration : 1);
        });
    };

    const getPDF = () => {
        setLoadingPDF(true);
        Api.get(
            `/api/clients/${client.id}/foodstrategy/${selectedStrat.id}`,
            `Bearer ${access_token}`
        )
            .then((res) => {
                const { data } = res;
                if (data.pdf) {
                    setPdf(data.pdf);
                }
            })
            .finally(() => {
                setLoadingPDF(false);
            });
    };

    useEffect(() => {
        setPdf(null);
        if (selectedStrat) {
            getPDF();
        }
    }, [client, selectedStrat]);

    useEffect(() => {
        Api.get(`/api/food/getOnlyFood`, `Bearer ${access_token}`).then(
            (res) => {
                const { data } = res;
                setFoods(data);
            }
        );
    }, []);

    const adicionarMeals = () => {
        setMealDaySelected({
            day_number: 0,
            options: [{ foods: [], preparation: "" }],
            name: "",
            time: "",
            cal: "",
        });
    };

    const excluirMealsDay = (meal) => {
        setSelectedStrat(null);
    };

    const handleCloneStrategy = () => {
        setLoadingCopy(true);
        setCopyError(null);
        Api.post(
            `/api/clients/${client.id}/foodstrategy/copy`,
            {
                origin: selectedStrat.id,
                destiny: selectedDestiny.id,
            },
            `Bearer ${access_token}`
        )
            .then((_) => {
                getStrategies();
                setOpenDuplicarEstrategiaModal(false);
            })
            .catch((e) => {
                setCopyError(e.message ?? "Ocorreu um erro inesperado.");
            })
            .finally((_) => {
                setLoadingCopy(false);
            });
    };

    const handleGenerateFoodStrategyPDF = () => {
        console.log()
        setLoadingGeneratePDf(true);
        Api.post(
            `/api/clients/${client.id}/foodstrategy/${selectedStrat.id}/pdf`,
            {},
            `Bearer ${access_token}`
        )
            .then((_) => {
                getPDF();
            })
            .catch((e) => {
                console.log(e);
            })
            .finally((_) => {
                setLoadingGeneratePDf(false);
            });
    };

    return (
        <>
            <div>
                {alert && <Alert text={alert.message} className={alert.type} />}
            </div>
            <div className="row">
                <div className="col-5">
                    <SelectInput
                        id="estrategia"
                        label="text"
                        name="Estratégia"
                        onSelect={(e) =>
                            setSelectedStrat(
                                estrategias.find(
                                    (stra) => e.target.value == stra.id
                                )
                            )
                        }
                        selected={selectedStrat && selectedStrat.id}
                        value={"id"}
                        options={estrategias}
                    />
                </div>
                <div className="col-6 d-flex align-items-end">
                    <button
                        className="btn btn-primary mb-3"
                        onClick={() => {
                            setOpenDuplicarEstrategiaModal(true);
                        }}
                    >
                        Duplicar estratégia
                    </button>
                    <Modal
                        closeModal={() => {
                            setOpenDuplicarEstrategiaModal(false);
                        }}
                        isOpen={openDuplicarEstrategiaModal}
                        id="openDuplicarEstrategiaModal"
                    >
                        {copyError && (
                            <div className="row">
                                <div className="col-12">
                                    <Alert
                                        className="alert-danger"
                                        text={copyError}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-6">
                                <SelectInput
                                    name="Estratégia que será substituída"
                                    value={"id"}
                                    label={"text"}
                                    onSelect={(e) =>
                                        setSelectedDestiny(
                                            estrategias.find(
                                                (stra) =>
                                                    e.target.value == stra.id
                                            )
                                        )
                                    }
                                    options={[
                                        {
                                            text: "Selecione",
                                            id: null,
                                        },
                                        ...estrategias.filter(
                                            (est) =>
                                                est.id !== selectedStrat?.id
                                        ),
                                    ]}
                                />
                            </div>
                        </div>
                        {selectedDestiny && (
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        A <b>{selectedDestiny.text}</b> será{" "}
                                        <b>removida</b> e recriada com base na{" "}
                                        <b>{selectedStrat.text}</b>.
                                    </p>
                                </div>
                            </div>
                        )}
                        <Button
                            text={"Substituir"}
                            onSubmit={handleCloneStrategy}
                            disabled={!selectedDestiny || loadingCopy}
                            className={"btn-primary mt-4"}
                        />
                    </Modal>
                    {!loadingPDF && (
                        <>
                            {pdf ? (
                                <a
                                    className="btn mb-3"
                                    target="_blank"
                                    href={pdf}
                                >
                                    Abrir PDF
                                </a>
                            ) : (
                                <button
                                    className="btn btn-primary mb-3 ml-2"
                                    disabled={loadingGeneratePDF}
                                    onClick={() => {
                                        handleGenerateFoodStrategyPDF();
                                    }}
                                >
                                    Gerar PDF
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
            <hr />
            <div style={Styles.row}>
                <Button
                    text="Cadastrar Novo"
                    className="btn-primary"
                    onSubmit={adicionarMeals}
                />
            </div>
            <div className="row mt-3">
                <div className="col-md-5">
                    <ListOfRefeicao
                        client={client}
                        onSelect={setMealDaySelected}
                        selectedStrat={selectedStrat}
                        mealDaySelected={mealDaySelected}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        excluirMealsDay={excluirMealsDay}
                    />
                </div>
                <div className="col-md-7">
                    {mealDaySelected && (
                        <EstrategiaAlimentarForm
                            duracao={duracao}
                            client={client}
                            adicionarMeals={adicionarMeals}
                            foods={foods}
                            selectedStrat={selectedStrat}
                            setRefresh={setRefresh}
                            mealDaySelected={mealDaySelected}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const Styles = {
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    estrategia: {
        display: "flex",
        marginLeft: "32px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    estrategia_text: {
        color: "#414141",
        fontWeight: "bold",
        fontSize: "16px",
        marginRight: "8px",
    },
};

export { EstrategiaAlimentar };
