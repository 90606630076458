import React, { useState, useEffect } from 'react';
import { Input, Button, Alert } from '../../../atoms';
import { ImageInput } from '../../../molecules';
import { Api } from '../../../../services';
import { useAccessToken } from '../../../../hooks';
import { useHistory } from "react-router-dom";

const TabCadastro = ({ client, updateClient }) => {

  const [form, setForm] = useState({});

  const [alert, setAlert] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);


  const { first_name, last_name, birth, civil_status, cpf, email, instagram, naturalidade, profession, rg,
    phone_number, address, number, neighborhood, cep, health_plan, reason, religion, gym, personal_trainer_name,
    personal_trainer_number, recommendation, gender } = form;

  useEffect(() => {
    setForm({
      first_name: client ? client.user.first_name : "",
      last_name: client ? client.user.last_name : "",
      photo_url: client ? client.photo_url : "",
      gender: client ? (client.gender === "Masculino" ? "male" : "female") : "",
      birth: client ? client.birth : "",
      civil_status: client ? client.civil_status : "",
      cpf: client ? client.cpf : "",
      rg: client ? client.rg : "",
      email: client ? client.user.email : "",
      phone_number: client ? client.phone_number : "",
      instagram: client ? client.instagram : "",
      naturalidade: client ? client.naturalidade : "",
      profession: client ? client.profession : "",
      address: client ? client.address && client.address.name : "",
      number: client ? client.address && client.address.number : "",
      neighborhood: client ? client.address && client.address.neighborhood : "",
      cep: client ? client.address && client.address.cep : "",
      reason: client ? client.reason : "",
      religion: client ? client.religion : "",
      health_plan: client ? client.health_plan : "",
      recommendation: client ? client.recommendation : "",
      gym: client ? client.gym && client.gym.name : "",
      personal_trainer_name: client ? client.personal_trainer && client.personal_trainer.name : "",
      personal_trainer_number: client ? client.personal_trainer && client.personal_trainer.number : "",
    })
  }, [client]);

  function editar() {
    setIsLoading(true)
    const body = getFormData(password ? { ...form, password } : { ...form });
    Api.post(`/api/clients/user/${client.user.id}/update`, body, `Bearer ${access_token}`).then(res => {
      updateClient(res.data);
      setAlert({ message: res.message, type: 'alert-success' });
    })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
        setIsLoading(false);
      });
  }

  const excluirCadastro = () => {
    setIsLoading(true);
    Api.delete(`/api/clients/${client.id}`, {}, `Bearer ${access_token}`)
      .then(() => {
        history.replace('/pacientes');
      })
      .catch((err) => {
        setAlert({ message: err.message, type: 'alert-danger' });
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
      })
      .finally(() => setIsLoading(false))
  }

  function getFormData(formParams) {
    let formData = new FormData();
    Object.keys(formParams).forEach((key) => {
      if (formParams[key]) {
        formData.append(key, formParams[key]);
      }
    });
    return formData;
  }

  function criar() {
    setIsLoading(true);
    const body = getFormData({ ...form, password });
    Api.post(`/api/clients/painel`, body, `Bearer ${access_token}`)
      .then(res => {
        setAlert({ message: res.message, type: 'alert-success' });
        console.log(res.data);
        history.push('/pacientes/' + res.data.id)
      })
      .catch(err => {
        if (err.message == 'Unauthenticated.') {
          setIsAuthenticated(false);
        } else if (err.errors && Array.isArray(Object.keys(err.errors)) && Object.keys(err.errors)[0]) {
          setAlert({ message: err.errors[Object.keys(err.errors)[0]].join(' '), type: 'alert-danger' });
        } else if (err.message) {
          setAlert({ message: err.message, type: 'alert-danger' });
        }
      })
      .finally(() => {
        document.getElementsByClassName("content-inside")[0].scrollTo(0, 0);
        setIsLoading(false)
      });
  }

  const onChangeImage = (name, value) => {
    let plan_aux = { ...form };
    plan_aux.photo_url = URL.createObjectURL(value);
    plan_aux.photo_file = value;
    setForm(plan_aux);
  }


  return (
    <div className="d-flex flex-fill flex-column">
      <div className="d-flex flex-fill flex-column overflow-y-auto col-12">
        {
          alert && <Alert text={alert.message} className={alert.type} />
        }
        <div className="row">
          <div className="col-12">
            <ImageInput
              onChangeInputValue={onChangeImage}
              file={form.photo_url ? form.photo_url : undefined}
              label={"<b>Selecione a imagem</b>"}
              name={"banner"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <Input label="Nome" value={first_name}
              onChangeInputValue={(first_name, value) => setForm({ ...form, first_name: value })} />
          </div>
          <div className="col-3">
            <Input label="Sobrenome" value={last_name}
              onChangeInputValue={(name, value) => setForm({ ...form, last_name: value })} />
          </div>
          <div className="col-3">
            <div className="form-group">
              <label style={{ display: 'block' }}>Sexo</label>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="sexoMasculino" id="sexo-masculino"
                  onChange={e => setForm({ ...form, gender: e.currentTarget.value })}
                  value="male" checked={gender == 'male'} />
                <label className="form-check-label" htmlFor="sexo-masculino">
                  Masculino
              </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="sexoFeminino" id="sexo-feminino"
                  onChange={e => { console.log(e.currentTarget.value); setForm({ ...form, gender: e.currentTarget.value }) }}
                  value="female" checked={gender == 'female'} />
                <label className="form-check-label" htmlFor="sexo-feminino">
                  Feminino
              </label>
              </div>
            </div>
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-5">
            <div className="row">
              <div className="col-6">
                <Input label="Data de Nascimento" value={birth} type="date"
                  onChangeInputValue={(name, value) => setForm({ ...form, birth: value })} />
              </div>
              <div className="col-6">
                <Input label="Estado Civil" value={civil_status}
                  onChangeInputValue={(name, value) => setForm({ ...form, civil_status: value })} />
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="row">
              <div className="col-6">
                <Input label="CPF" value={cpf}
                  onChangeInputValue={(name, value) => setForm({ ...form, cpf: value })} />
              </div>
              <div className="col-6">
                <Input label="RG" value={rg}
                  onChangeInputValue={(name, value) => setForm({ ...form, rg: value })} />
              </div>
            </div>
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-5">
            <Input label="Email" value={email}
              onChangeInputValue={(name, value) => setForm({ ...form, email: value })} />
          </div>
          {

            <div className="col-5">
              <Input label={client ? "Inserir Nova Senha" : "Senha"} type="password" value={password}
                onChangeInputValue={(name, value) => setPassword(value)} />
            </div>
          }
          <div className="col-5">
            <div className="row">
              <div className="col-6">
                <Input label="Telefone" value={phone_number}
                  onChangeInputValue={(name, value) => setForm({ ...form, phone_number: value })} />
              </div>
              <div className="col-6">
                <Input label="Instagram" value={instagram}
                  onChangeInputValue={(name, value) => setForm({ ...form, instagram: value })} />
              </div>
            </div>
          </div>
        </div>
        {/* Linha */}
        <div className="row">
          <div className="col-3">
            <Input label="Naturalidade" value={naturalidade}
              onChangeInputValue={(name, value) => setForm({ ...form, naturalidade: value })} />
          </div>
          <div className="col-3">
            <Input label="Profissão/Ocupação" value={profession}
              onChangeInputValue={(name, value) => setForm({ ...form, profession: value })} />
          </div>
        </div>
        <div className="mt-5">
          {/* Linha */}
          <div className="row">
            <div className="col-5">
              <Input label="Endereço" value={address}
                onChangeInputValue={(name, value) => setForm({ ...form, address: value })} />
            </div>
            <div className="col-3">
              <Input label="Número" value={number}
                onChangeInputValue={(name, value) => setForm({ ...form, number: value })} />
            </div>
          </div>
          {/* Linha */}
          <div className="row">
            <div className="col-5">
              <Input label="Bairro" value={neighborhood}
                onChangeInputValue={(name, value) => setForm({ ...form, neighborhood: value })} />
            </div>
            <div className="col-3">
              <Input label="CEP" value={cep}
                onChangeInputValue={(name, value) => setForm({ ...form, cep: value })} />
            </div>
          </div>
        </div>
        <div className="mt-5">
          {/* Linha */}
          <div className="row">
            <div className="col-5">
              <Input label="Plano de Saúde" value={health_plan}
                onChangeInputValue={(name, value) => setForm({ ...form, health_plan: value })} />
            </div>
            <div className="col-4">
              <Input label="Religião" value={religion}
                onChangeInputValue={(name, value) => setForm({ ...form, religion: value })} />
            </div>
          </div>
          {/* Linha */}
          <div className="row">
            <div className="col-5">
              <Input label="Queixa (Doença instalada)" value={reason}
                onChangeInputValue={(name, value) => setForm({ ...form, reason: value })} />
            </div>
          </div>
        </div>
        {/* indicação */}
        <div className="mt-5">
          {/* Linha */}
          <div className="row">
            <div className="col-5">
              <Input label="Indicação" value={recommendation}
                onChangeInputValue={(name, value) => setForm({ ...form, recommendation: value })} />
            </div>
          </div>
        </div>
        {/* ACADEMIA */}
        <div className="mt-5">
          {/* Linha */}
          <div className="row">
            <div className="col-4">
              <Input label="Academia" value={gym}
                onChangeInputValue={(name, value) => setForm({ ...form, gym: value })} />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <Input label="Personal" value={personal_trainer_name}
                onChangeInputValue={(name, value) => setForm({ ...form, personal_trainer_name: value })} />
            </div>
            <div className="col-4">
              <Input label="Telefone" value={personal_trainer_number}
                onChangeInputValue={(name, value) => setForm({ ...form, personal_trainer_number: value })} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 actions mb-2">
        {
          client && (
            <>
              <a href="#" onClick={() => excluirCadastro()} >Excluir Cadastro</a>
            </>
          )
        }
        <Button disabled={isLoading} text={client ? "Atualizar Cadastro" : "Criar Cadastro"} onSubmit={() => client ? editar() : criar()} className="btn-submit" />
      </div>
    </div>
  );
}

export { TabCadastro };