import React from 'react';
import { Alert } from '../../atoms';

const ActionsStatuses = ({ sucesso, erro }) => {
  return (
    <div className="w-100">
      {sucesso ?
        <Alert className="alert-success" text="Operação concluída com sucesso."/>
        : null
      }
      {erro != "" ?
        <Alert className="alert-danger" text={erro}/>
        : null
      }
    </div>
  );
}

export { ActionsStatuses };
