import React from 'react';
import { Input, Button, TopicTitle } from '../../atoms';

const Topic = ({ removeTopic, index, topic, changeTopic, focus }) => {
  const onChangeTitle = (_, value) => {
    changeTopic(index, { ...topic, title: value });
  }

  const onChangeSubtitle = (_, value) => {
    changeTopic(index, { ...topic, subtitle: value });
  }

  return (
    <div className="col-12">
      <div className="row align-items-center">
        <div className="col-10 separator-bottom">
          <TopicTitle number={index + 1}/>
          <Input
            focus={focus}
            type={"text"}
            id={"title" + index}
            name={"title" + index}
            onChangeInputValue={onChangeTitle}
            label={"<b>Título</b>"}
            value={topic ? topic.title || "" : ""}
          />
          <Input
            type={"text"}
            id={"subtitle" + index}
            name={"subtitle" + index}
            onChangeInputValue={onChangeSubtitle}
            label={"<b>Subtítulo</b>"}
            value={topic ? topic.subtitle || "" : ""}
          />
        </div>
        <div className="col-2">
          <Button onSubmit={() => {removeTopic(index)}} className={"btn-danger btn-red btn-outline"} text={"X"} />
        </div>
      </div>
    </div>
  )
}

export { Topic }
