import React, { useState, useEffect } from 'react';
import { Api } from '../../../services';
import { Input, Button, Loader } from '../../atoms';
import { PlayerWithLabel, ImageInput, ActionsStatuses } from '../../molecules';
import { useAccessToken } from '../../../hooks';


const VideoUpdate = ({ currentMedia, setRefresh }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const access_token = useAccessToken(isAuthenticated, setIsAuthenticated);

  useEffect(() => {
    setImage(null);
  }, [currentMedia])

  useEffect(() => {
    if (isAuthenticated && shouldUpdate) {
      let body = new FormData();
      body.append('id', currentMedia.id);
      body.append('thumbnail_file', image);
      console.log('thumbnail_file', image);
      Api.post('/api/medias/update', body, `Bearer ${access_token}`)
        .then(response => {
          setSucesso(true);
          setLoading(false);
          setShouldUpdate(false);
          setRefresh(true);
        })
        .catch(erro => {
          if (erro.message == 'Unauthenticated.') {
            setIsAuthenticated(false);
          } else {
            setShouldUpdate(false);
            setLoading(false);
            setErro(erro.message);
          }
        })
    }
  }, [isAuthenticated, shouldUpdate])

  const onChangeInputValue = (_, value) => {
    setImage(value);
  }

  const onSubmit = () => {
    if (image !== null) {
      setLoading(true);
      setShouldUpdate(true);
    } else {
      setErro("Selecione uma thumbnail");
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (sucesso) {
        setSucesso(false);
      }
      if (erro != "") {
        setErro("")
      }
    }, 5000)
  }, [sucesso, erro])

  return (
    <div>
      <Input
        label={"<b>Nome do vídeo</b>:"}
        type={"text"}
        readOnly={true}
        name={"nome"}
        id={"nome-video"}
        onChangeInputValue={() => { }}
        value={currentMedia.name}
      />
      <div className="row">
        <div className="col-6">
          <PlayerWithLabel label={"<b>Vídeo:</b>"} media_id={currentMedia.id} height={200} />
        </div>
        <div className="col-6">
          {((currentMedia.thumbnail_url != null) && (image == null)) ?
            <ImageInput
              onChangeInputValue={onChangeInputValue}
              file={currentMedia.thumbnail_url}
              label={"<b>Selecione a thumbnail</b>"}
              name={"video_thumbnail"}
            />
            :
            <ImageInput
              onChangeInputValue={onChangeInputValue}
              file={image != null ? URL.createObjectURL(image) : null}
              label={"<b>Selecione a thumbnail</b>"}
              name={"video_thumbnail"}
            />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-5 d-flex justify-content-end">
          <Button disabled={loading} onSubmit={onSubmit} className={"btn-submit"} text={"Atualizar Vídeo"} />
        </div>
      </div>
      {loading &&
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader />
          </div>
        </div>
      }
      <div className="row">
        <div className="col-12">
          <ActionsStatuses sucesso={sucesso} erro={erro}/>
        </div>
      </div>
    </div>
  );
}

export { VideoUpdate };
