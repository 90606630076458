import React from 'react';

const EditButton = ({className}) => {
  return (
    <button className={`btn btn-editar ${className}`} >
      <img src="/img/edit.svg" alt="Botão de editar"/>
    </button>
  );
}

export { EditButton };
