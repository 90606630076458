import React, { useState, useEffect } from 'react';
import { Button } from "../../atoms";
import { ListOfFood } from "..";
import { FoodForm } from "../../molecules";
import './style.scss';

const FoodContainer = ({ }) => {
  const [currentFood, setCurrentFood] = useState(null);
  const [refresh, setRefresh] = useState(0);

  return (
    <div className="row d-flex flex-column food-container flex-fill h-100">
      <div className="mb-3">
        <Button className="btn-primary" text="Cadastrar Novo" onSubmit={() => setCurrentFood(null)} />
      </div>
      <div className="d-flex flex-fill">
        <div className="row d-flex flex-fill">
          <div className="col-5 d-flex flex-fill">
            <ListOfFood onSelect={setCurrentFood} selected={currentFood} setRefresh={setRefresh} refresh={refresh} />
          </div>
          <div className="col-7">
            <FoodForm setCurrent={setCurrentFood} current={currentFood} setRefresh={setRefresh} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { FoodContainer };
