import React from 'react';
import { SubSide } from '..';

const DashboardContainer = ({children, subsides, onClickItemMenu}) => {
  return (
    <div className="dashboard-content">
      <div className="content-title">
        <img className="logo" src="/img/logo-pnp.svg" />
      </div>
      <div className="d-flex flex-row">
        {
          subsides && (
          <div className="flex-column">
            <SubSide subsides={subsides} onClick={onClickItemMenu} />
          </div>
          )
        }
      <div className="content-inside container-fluid d-flex flex-column">
        {children}
      </div>
      </div>
    </div>
  );
}

export { DashboardContainer };
