import React, { useState } from 'react';
import { Input, Button, ProgressBar } from '../../atoms';
import { Api } from '../../../services';
import tus from "tus-js-client";

// resourceURL recebe a url para atualizar o registro que irá armazenar o link para o vídeo
const VideoInput = ({ name, onSuccess, onErro, authToken, currentFile, videoChanged }) => {
  const [video, setVideo] = useState("");
  const [porcentage, setPorcentage] = useState(0);
  const [uploading, setUploading] = useState(false);

  const submitClick = async () => {
    if (name != "") {
      let videoName = name + '.' + video.type.split('/')[1];
      await removeFile();
      setUploading(true)
      const upload = new tus.Upload(video, {
        endpoint: `${process.env.MIX_API_URL}/api/upload/videos`,
        uploadUrl: `${process.env.MIX_API_URL}/api/upload/videos`,
        retryDelays: [0, 1000, 3000, 5000],
        chunkSize: 500000,
        resume: false,
        metadata: {
          filename: videoName,
          filetype: video.type
        },
        headers: {
          Authorization: authToken
        },
        overridePatchMethod: true,
        onError: (error) => {
          onErro(error.message)
        },
        onProgress: (uploadedBytes, totalBytes) => {
          setPorcentage((100 * uploadedBytes) / totalBytes);
        },
        onSuccess: () => {
          setUploading(false)
          setPorcentage(0);
          videoChanged("video", "")
          onSuccess(true);
        }
      });
      upload.start();
    } else {
      onErro("O nome do vídeo não pode ser vazio");
    }
  }

  const removeFile = () => {
    if (currentFile) {
      return Api.post('/api/file/remove', {
        file_path: currentFile
      }, authToken)
    }
  }


  const onChangeInputValue = (_, value) => {
    setVideo(value);
    videoChanged("video", URL.createObjectURL(value))
  }

  return (
    <div className="video-input">
      <Input
        onChangeInputValue={onChangeInputValue}
        label={"Selecione o seu <b>vídeo</b>"}
        name={"video"}
        id={"video"}
        type={"file"}
        disabled={uploading}
        accept={"video/*"} />
      <Button onSubmit={submitClick} disabled={uploading} className={"btn-primary"} text={"Enviar vídeo"} />
      {uploading && <ProgressBar porcentage={porcentage} />}
    </div>
  );
}

export { VideoInput };
