import React from 'react';
import { SideBar } from '../organisms';
import { DashboardContainer as DashboardContent } from '../atoms';
import { InternPageHeader, ConfigOptions } from '../organisms';

function ConfigPage() {
  return (
    <div className="dashboard-container">
      <SideBar/>
      <DashboardContent>
        <InternPageHeader link={"/home"} title={"Interface do meu App"}/>
        <ConfigOptions/>
      </DashboardContent>
    </div>
  )
}

export { ConfigPage };
