import React from 'react'

const Statistic = ({ text, percentage, color }) => {
  const colors = {
    verde: "verde",
    roxo: "roxo",
    vermelho: "vermelho",
    amarelo: "amarelo"
  }
  return (
    <div className={`w-100 mb-3 d-flex align-items-center justify-content-center statistic ${colors[color]}`}>
      <div className="mr-3">
        <h5>{percentage}|</h5>
      </div>
      <div>
        <h6>{text}</h6>
      </div>
    </div>
  )
}

export { Statistic }