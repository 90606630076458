import firebase from 'firebase';
import momentjs from 'moment';

class Chat {
  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyD0dRAy30uqOBPB8TG2Yt3MtGr5d07Dy5E",
      authDomain: "pnp-programa-nutricional.firebaseapp.com",
      databaseURL: "https://pnp-programa-nutricional.firebaseio.com",
      projectId: "pnp-programa-nutricional",
      storageBucket: "pnp-programa-nutricional.appspot.com",
      messagingSenderId: "739635922755",
      appId: "1:739635922755:web:49c1c5ec2ae5e18e5651f6",
      measurementId: "G-6Q2W3E05HV"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    this.chats = [];
    this.subscriptions = [];
    this.subscriptionsCount = [];
    const firestore = firebase.firestore();
    firestore.collection('users')
      .orderBy('last_message', 'desc')
      .onSnapshot((doc) => {
        this.chats = doc.docs.map((user) => {
          const { user_name, last_message, unread_messages, was_read_adm, photo_url, stage } = user.data();
          return {
            id: user.id,
            nome: user_name,
            data: momentjs(last_message.toDate()).format('DD/MM/YYYY'),
            userRef: user,
            unread_messages: unread_messages || 0,
            was_read_adm,
            photo_url,
            stage
          };
        });
        this.subscriptions.forEach((cb) => cb(this.chats));
        this.subscriptionsCount.forEach((cb) => cb(this.chats.filter(message => !message.was_read_adm).length));
      });
  }

  onSubscription(cb) {
    this.subscriptions.push(cb);
    cb(this.chats);
    return () => {
      this.subscriptions = this.subscriptions.filter(cbCurrent => cb != cbCurrent);
    }
  }

  onSubscriptionCount(cb) {
    this.subscriptionsCount.push(cb);
    cb(this.chats.filter(message => !message.was_read_adm).length);
  }

  get() {
    return [...this.chats];
  }
}

const instance = new Chat();

export default instance;